// npm
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Custom functions
import { getElementList, getElement } from './utils';

// Stylesheet
import './dataInsert.css';


/**
 * This component renders the form to insert into the database a new 3D model
 * @param {*} props React props, namely:
 * - insertCount: the counter of the new insertions during the current session;
 * - setInsertCount: the function to update insertCount.
 * @returns The React component
 */
const Model3DInsert = (props) => {
  
  // Redux
  const store = useSelector(state => state);
  const lang = store.lang;

  // React state
  const [typeState, setTypeState] = useState("complesso");
  const [hotspotList, setHotspotList] = useState([]);
  const [skyboxList, setSkyboxList] = useState([]);
  const [, setElementCurrentState] = useState();
  const [elementID, setElementID] = useState(null);
  // Selector state
  const [elementsSelectorList, setElementsSelectorList] = useState(null);
  const [skyboxSelectorList, setSkyboxSelectorList] = useState(null);
  // Re-rendering
  const [, setRenderCount] = useState(0);

  /**
   * This effect is called when the user changes the element typology.
   */
  useEffect(() => {
    async function fetchData() {
      setElementsSelectorList(await getElementList(typeState, lang));
      setSkyboxSelectorList(await getElementList('skybox', lang));
    }
    fetchData();
  }, [typeState, lang, props.insertCount])

  return (
    <div className="pb-20">
      {/* Form */}
      <form className="flex flex-col mx-4" id="New3DModelForm">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mx-8 my-4">
          {/* Type */}
          <div className="flex justify-end items-center">
            <p className="mx-2">Seleziona il tipo di elemento:</p>
            <select name="type" value={typeState} onChange={typeSelectorHandler} onClick={typeSelectorHandler} className="w-36 bg-white text-dark">
              <option value="complesso">Complesso</option>
              <option value="monumento">Monumento</option>
            </select>
          </div>
          {/* Element ID */}
          <div className="flex justify-end items-center">
            <label className="pr-2">Elemento: </label>
            <select className="w-60 bg-white text-dark" value={(elementID ? String(elementID) : "-1") || "-1"} id="elementid" name="elementid" onChange={handlerElementIDInput} onClick={handlerElementIDInput}>
              <option value="-1"> -- select an option -- </option>
              {elementsSelectorList ?
                elementsSelectorList.List.map((element, index) => {
                  return (
                    <option key={index} value={element[Object.keys(element)[0]].ID}>
                      {(element[Object.keys(element)[0]].OGTN ? element[Object.keys(element)[0]].OGTN : element[Object.keys(element)[0]].ID)}
                    </option>
                  )
                })
              : null}
            </select>
          </div>

          <div className="flex justify-center items-center">
            <label className="pr-2">Nome file<span className="text-orange font-bold font-cinzel">*</span>: </label>
            <input className="text-dark px-1 w-full" type="text" id="link3D" name="link3D" placeholder="Con estensione e sottocartelle." />
          </div>

          <div className="flex justify-center items-center">
            <label className="pr-2">Autore: </label>
            <input className="text-dark px-1 w-full" type="text" id="autore" name="autore" />
          </div>

          <div className="flex justify-center items-center">
            <label className="pr-2">Detentore: </label>
            <input className="text-dark px-1 w-full" type="text" id="detentore" name="detentore" />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSII: 
              <span className="tooltiptext">Anno di inizio, limite inferiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsii" name="dtsii" />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSIS: 
              <span className="tooltiptext">Anno di inizio, limite superiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsis" name="dtsis" />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSFI: 
              <span className="tooltiptext">Anno di fine, limite inferiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsfi" name="dtsfi" />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSFS: 
              <span className="tooltiptext">Anno di fine, limite superiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsfs" name="dtsfs" />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2">Pianta 3D<span className="text-orange font-bold font-cinzel">*</span>: </label>
            <input type="checkbox" name="alzato" value={true} />
          </div>
        </div>

        <div className="mx-80 mt-6">
          <div className="marginLine border-white">
            <span>Hotspot</span>
          </div>
        </div>
        {hotspotList.map((element, index) => {
          return (
            <div key={"hotspot-" + index} className="grid grid-cols-5 gap-4 mx-8 my-4">
              <div className="flex items-center justify-end">
                <label className="pr-2">Posizione: </label>
                <input className="text-dark px-1" type="text" id={"posizione-" + index} name={"posizione-" + index} onChange={(event) => handlerHotspotInput(event, index, 'Posizione')} />
              </div>
              <div className="flex items-center justify-end">
                <label className="pr-2">Normale: </label>
                <input className="text-dark px-1" type="text" id={"normale-" + index} name={"normale-" + index} onChange={(event) => handlerHotspotInput(event, index, 'Normale')} />
              </div>
              <div className="flex items-center justify-end">
                <label className="pr-2">Immagine: </label>
                <input className="text-dark px-1" type="text" id={"immagine-" + index} name={"immagine-" + index} placeholder="Immagine allegata (file)" onChange={(event) => handlerHotspotInput(event, index, 'Immagine')} />
              </div>
              <div className="flex items-center justify-end">
                <label className="pr-2">Link: </label>
                <input className="text-dark px-1" type="text" id={"link-" + index} name={"link-" + index} onChange={(event) => handlerHotspotInput(event, index, 'Link')} placeholder="Eventuali link esterni" />
              </div>
              <div className="flex items-center justify-end">
                <label className="pr-2">Descrizione: </label>
                <input className="text-dark px-1" type="text" id={"testo-" + index} name={"testo-" + index} onChange={(event) => handlerHotspotInput(event, index, 'Testo')} placeholder="Testo descrizione (ITA)" />
              </div>
              <div className="flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={() => removeHotspotFieldHandler(index)}>
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          )
        })}
        <div className="grid grid-cols-5 gap-4 mx-8 my-4">
          <div className="flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={addHotspotFieldHandler}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

        <div className="mx-80 mt-6">
          <div className="marginLine border-white">
            <span>Skybox</span>
          </div>
        </div>
        
        {skyboxList.map((element, index) => {
          return (
            <div key={"skybox-" + index} className="grid grid-cols-4 gap-4 mx-8 my-4">
              <div className="flex items-center justify-end">
                <label className="pr-2">Opzioni: </label>
                <select className="w-60 bg-white text-dark" defaultValue="-1" id="skyboxid" name="skyboxid" onChange={(event) => handlerSkyboxInput(event, index, 'ID')} onClick={(event) => handlerSkyboxInput(event, index, 'ID')}>
                  <option value="-1"> Nuovo elemento </option>
                  {skyboxSelectorList ?
                    skyboxSelectorList.List.map((element, index2) => {
                      return (
                        <option key={index2} value={element[Object.keys(element)[0]].ID}>
                          {element[Object.keys(element)[0]].Link.substring(12)}
                        </option>
                      )
                    })
                  : null}
                </select>
              </div>
              <div className="flex items-center justify-end">
                <label className="pr-2">Esposizione: </label>
                <input className="text-dark px-1" type="range" min="0" max="2" step="0.01" defaultValue="1" id={"esposizione-" + index} name={"eposizione-" + index} onChange={(event) => handlerSkyboxInput(event, index, 'Esposizione')} />
                <p className="text-white w-1/6">{skyboxList[index].Esposizione}</p>
              </div>
              <div className={"flex items-center justify-end " + (new FormData(document.forms.New3DModelForm).get('skyboxid') === "-1" || new FormData(document.forms.New3DModelForm).get('skyboxid') === null ? null : "hidden")}>
                <label className="pr-2">Nome file: </label>
                <input className="text-dark px-1" type="text" id={"skybox-" + index} name={"skybox-" + index} placeholder="Nome file" onChange={(event) => handlerSkyboxInput(event, index, 'Link')} />
              </div>
              <div className="flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={() => removeSkyboxFieldHandler(index)}>
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          )
        })}

        <div className="grid grid-cols-3 gap-4 mx-8 my-4">
          <div className="flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={addSkyboxFieldHandler}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

        {/* Submit */}
        <div className="flex justify-end mx-8">
          <button className="w-60 text-dark rounded-full mt-10 bg-white" onClick={handlerSubmitForm}>{"Crea nuovo Modello 3D"}</button>
        </div>
      </form>
    </div>
  )


  // ****************************************** HANDLERS ******************************************

  /**
   * This function handles the change in the typology
   * @param {*} event The onChange in the type selector
   */
  async function typeSelectorHandler(event) {
    setTypeState(event.target.value);
    setElementCurrentState(null);
    setElementID(null);
    document.forms.New3DModelForm.reset();
  }

  /**
   * This function handles the change in the ID input field.
   * @param {*} event The onClick or onChange event in the input field.
   */
  async function handlerElementIDInput(event) {
    const currentID = event.target.value;
    document.forms.New3DModelForm.reset(); // Reset form
    if (currentID !== "-1" && currentID !== null) {
      // Get info
      setElementID(currentID);
      let element = await getElement(currentID, typeState, lang);
      setElementCurrentState(element[Object.keys(element)[0]]);
    } else {
      // Reset
      setElementID(null);
      setElementCurrentState(null);
    }
  }

  /**
   * This function handles the change in the hotspot input fields
   * @param {*} event The onChange event in the type selction;
   * @param {Int} index The position of the selected hotspot in the selection;
   * @param {String} key The input field key.
   */
  function handlerHotspotInput(event, index, key) {
    let value = event.target.value;
    if (value === "")
      value = null;
    let provList = JSON.parse(JSON.stringify(hotspotList));
    provList[index][key] = value;
    setHotspotList(provList);
  }

  /**
   * This handler adds a new hotspot to the current 3D model.
   */
  function addHotspotFieldHandler() {
    let provList = JSON.parse(JSON.stringify(hotspotList));
    provList.push({Posizione: null, Normale: null, Immagine: null, Link: null});
    setHotspotList(provList);
  }

  /**
   * This handler removes an hotspot from the current 3D model.
   */
  function removeHotspotFieldHandler(index) {
    let provList = JSON.parse(JSON.stringify(hotspotList));
    provList.splice(index, 1);
    setHotspotList(provList);
  }

  /**
   * This function handles the change in the skybox input fields
   * @param {*} event The onChange event in the type selction;
   * @param {Int} index The position of the selected skybox in the selection;
   * @param {String} key The input field key.
   */
  function handlerSkyboxInput(event, index, key) {
    let value = event.target.value;
    if (value === "")
      value = null;
    setRenderCount(renderCount => renderCount + 1);
    let provList = JSON.parse(JSON.stringify(skyboxList));
    provList[index][key] = value;
    setSkyboxList(provList);
  }

  /**
   * This handler adds a new skybox to the current 3D model.
   */
  function addSkyboxFieldHandler() {
    let provList = JSON.parse(JSON.stringify(skyboxList));
    provList.push({ID: -1, Link: null, Esposizione: 1}); // Default value is 1
    setSkyboxList(provList);
  }

  /**
   * This handler removes an hotspot from the current 3D model.
   */
  function removeSkyboxFieldHandler(index) {
    let provList = JSON.parse(JSON.stringify(skyboxList));
    provList.splice(index, 1);
    setSkyboxList(provList);
  }

  /**
   * This handler submits the inserted data to the database server.
   * @param {*} event The onClick event that confirms the submission.
   */
  function handlerSubmitForm(event) {
    event.preventDefault();

    // Get form
    var formEl = document.forms.New3DModelForm;
    var formData = new FormData(formEl); 

    // Final element
    let finalJSON = {};
    finalJSON.Modello3D = {};

    // Get type
    let type = formData.get('type');
    finalJSON.Modello3D.Tipo = type;

    // ID elemento
    let elementID = formData.get('elementid');
    if (!elementID) {
      alert("Selezionare un " + type + ".");
      return;
    }
    finalJSON.Modello3D.IDElemento = parseInt(elementID);

    // Link 3D
    let link3D = formData.get('link3D');
    if (link3D === "") {
      alert("Inserire il nome del file del modello 3D.");
    }
    finalJSON.Modello3D.Link = "/assets/3DModels/" + link3D;

    // Autore
    let autore = formData.get('autore');
    if (autore === "") {
      autore = null;
    }
    finalJSON.Modello3D.Autore = autore;

    // Detentore
    let detentore = formData.get('detentore');
    if (detentore === "") {
      detentore = null;
    }
    finalJSON.Modello3D.Detentore = detentore;

    // DTS
    var dtsii = formData.get("dtsii");
    var dtsis = formData.get("dtsis");
    var dtsfi = formData.get("dtsfi");
    var dtsfs = formData.get("dtsfs");
    if (dtsii !== "" || dtsis !== "" || dtsfi !== "" || dtsfs !== "") { // If not disabled
      dtsii = parseInt(dtsii);
      dtsis = parseInt(dtsis);
      dtsfi = parseInt(dtsfi);
      dtsfs = parseInt(dtsfs);

      if (isNaN(dtsii) || isNaN(dtsis) || isNaN(dtsfi) || isNaN(dtsfs)) {
        alert ("DTSII, DTSIS, DTSFI e DTSFS devono essere valori numerici.");
        return;
      }
      if (!(dtsii <= dtsis && dtsis <= dtsfi && dtsfi <= dtsfs)) {
        alert ("DTSII, DTSIS, DTSFI e DTSFS devono avere valori crescenti.");
        return;
      }
      finalJSON.Modello3D.DTSII = dtsii;
      finalJSON.Modello3D.DTSIS = dtsis;
      finalJSON.Modello3D.DTSFI = dtsfi;
      finalJSON.Modello3D.DTSFS = dtsfs;
    }

    // Alzato
    let alzato = formData.get('alzato');
    if (alzato) {
      alzato = true;
    } else {
      alzato = false;
    }
    finalJSON.Modello3D.Alzato = alzato; 

    // Hotspot
    let hotspotJSONList = [];
    for (let i = 0; i < hotspotList.length; i++) {
      const element = hotspotList[i];
      if (element.Posizione || element.Normale || element.Immagine || element.Link || element.Testo ) {
        if (!element.Posizione || !element.Normale || !element.Testo) {
          alert("Posizione, Normale e Testo sono obbligatori.");
          return;
        }
        hotspotJSONList.push({Hotspot: element});
      }
    }
    finalJSON.Modello3D.Hotspots = hotspotJSONList;

    // Skybox
    let skyboxJSONList = [];
    for (let i = 0; i < skyboxList.length; i++) {
      const element = skyboxList[i];
      if (parseInt(element.ID) === -1 && (element.Link === "" || element.Link === null)) {
        alert ("Inserire il nome del file per la nuova skybox.");
        return;
      }
      if (parseInt(element.ID) === -1)
        skyboxJSONList.push({Skybox: {ID: parseInt(element.ID), Link: ("/assets/hdr/" + element.Link), Esposizione: parseFloat(element.Esposizione)}});
      else
        skyboxJSONList.push({Skybox: {ID: parseInt(element.ID), Link: null, Esposizione: parseFloat(element.Esposizione)}});
    }
    finalJSON.Modello3D.Skyboxes = skyboxJSONList;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',  
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, UPDATE',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(finalJSON)
    };
    fetch(window.SERVER_MAIN_PATH + "/insert/modello3D?language=ITA", requestOptions)
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 201) {
          let count = props.insertCount + 1;
          props.setInsertCount(count); // New Render
          alert("Modello3D inserito con ID: " + result.NewModello3D.ID);
        } else {
          console.log("ERRORE: " + JSON.stringify(result));
          alert("Inserimento non eseguito. Contatta TuSaiChi.");
        }   
      })
      .catch(function(error) {
        alert(error);
      });

    console.log(finalJSON);
  }

}

export default Model3DInsert;