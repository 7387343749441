// npm
import React from 'react';
import { Link } from 'react-router-dom';
// Custom components
import Toggle from '../components/toggle.js';
import LanguageSelector from '../components/languageSelector.js';
import SettingsBar from '../components/settingsBar.js';
import Person from './person.js';
// Assets
import darkLogo from '../assets/imgs/darkLogo.png';
import lightLogo from '../assets/imgs/lightLogo.png';
import Foto_Busana from '../assets/imgs/team/Foto_Busana.jpg';
import Foto_Roda from '../assets/imgs/team/Foto_Roda.png';
import Foto_Greco from '../assets/imgs/team/Foto_Greco.png';
import Foto_Amadei from '../assets/imgs/team/Foto_Amadei.jpg';
import Foto_Carpanese from '../assets/imgs/team/Foto_Carpanese.jpg';
import Foto_DallaPozza from '../assets/imgs/team/Foto_DallaPozza.jpg';
import Foto_Padoan from '../assets/imgs/team/Foto_Padoan.png';
import Foto_Vacilotto from '../assets/imgs/team/Foto_Vacilotto.jpg';


/**
 * This class renders the Team page, where several information about the CArD 3D project members can be found.
 * It requires the following properties:
 * - darkOnChange: function to handle the dark mode toggle;
 * - isDark: boolean variable to check if the dark mode is active.
 */
class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSidebarIsOpen: false
    }
    this.handleMobileSidebar = this.handleMobileSidebar.bind(this);
  }

  handleMobileSidebar(event) {
    event.preventDefault();
    this.setState({
      mobileSidebarIsOpen: !this.state.mobileSidebarIsOpen
    });
  }
  
  componentDidMount(){
    document.title = "CArD 3D - Chi siamo"
  }

  render() {
    return (
      <div className="flex flex-col items-center">
        <SettingsBar toggleEnabled={this.props.isDark} toggleOnChange={this.props.darkOnChange} settingsbarIsOpen={this.state.mobileSidebarIsOpen} />
        <div className="hidden w-4/5 h-20vh lg:flex justify-between">
          <div className="z-50 flex w-1/4 justify-center align-center">
            <Toggle enabled={this.props.isDark} onChange={this.props.darkOnChange} />
          </div>
          <div className="w-1/4 flex items-center z-50">
            <Link to="/">
              <img className="transform hover:scale-110 duration-300" src={this.props.isDark ? darkLogo : lightLogo} alt="CArD 3D light logo" />
            </Link>
          </div>
          <div className="z-50 flex w-1/4 justify-center align-center">
            <LanguageSelector arrowColor={this.props.isDark ? "white" : "dark"} />
          </div>
        </div>
        <h1 className="font-cinzel text-2xl md:text-5xl mb-6 mt-24 lg:mt-8">
          Il team di lavoro
        </h1>
        <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 mx-5 lg:w-8/12 my-10">
          <Person
            name="Prof.ssa Maria Stella Busana"
            role=""
            desc="Responsabile e coordinatrice del Progetto. Referente scientifica per il Dipartimento dei Beni Culturali."
            img={Foto_Busana}
          />
          <Person
            name="Prof. Antonio Rodà"
            role=""
            desc="Referente scientifico del Progetto per il Dipartimento di Ingegneria dell'Informazione."
            img={Foto_Roda}
          />
          <Person
            name="Prof. Luciano Greco"
            role=""
            desc="Referente scientifico del Progetto per il Dipartimento di Scienze Economiche e Aziendali."
            img={Foto_Greco}
          />
          <Person
            name="Irene Carpanese"
            role="Assegnista di Ricerca DBC"
            desc="Archeologa classica. Modellatrice 3D. Database management."
            img={Foto_Carpanese}
          />
          <Person
            name="Alice Vacilotto"
            role="Assegnista di Ricerca DBC"
            desc="Archeologa del paesaggio. Specializzata in GIS e valorizzazione del patrimonio territoriale."
            img={Foto_Vacilotto}
          />
          <Person
            name="Nadir Dalla Pozza"
            role="Assegnista di Ricerca DEI"
            desc="Computer Engineer. Sviluppatore App lato front end. Esperto in UX e grafica web."
            img={Foto_DallaPozza}
          />
          <Person
            name="Alberto Padoan"
            role="Assegnista di Ricerca DEI"
            desc="TLC Engineer. Sviluppatore App lato back end. Database Developer."
            img={Foto_Padoan}
          />
          <Person
            name="Claudia Amadei"
            role="Assegnista di Ricerca DSEA"
            desc="Economista. Specialista in sviluppo e analisi della sostenibilità economico-finanziaria di applicazioni."
            img={Foto_Amadei}
          />
        </div>
      </div>
    )
  }
}

export default Team;
