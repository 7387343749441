
// npm
import React from 'react';
import parse from 'html-react-parser';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Custom components
import Toggle from '../components/toggle.js';
import LanguageSelector from '../components/languageSelector.js';
import SearchBar from '../components/searchBar/searchBar.js';
import SettingsBar from '../components/settingsBar.js';
import Error from '../components/error.js';
import Carousel from '../components/carousel.js';
// Stylesheet
import './home.css';
// Assets
import lightLogo from '../assets/imgs/lightLogo.png';
import darkLogo from '../assets/imgs/darkLogo.png';
// Translations
import staticTranslation from '../utils/JSON/staticTranslation.json';


/**
 * This component renders the homepage of CArD 3D.
 * It presents an interface to search for archeological elements, including a search bar and some random suggestions.
 * It also presents a brief tutorial on the application usage.
 * @param {*} props React props, namely:
 * - darkOnChange: function to handle the dark mode toggle;
 * - isDark: boolean variable to check if the dark mode is active.
 * @returns The React component.
 */
const Home = (props) => {
  // Redux
  const store = useSelector(state => state)
  const lang = store.lang
  // React state
  const [mobileSidebarIsOpen, setMobileSidebarIsOpen] = useState(false);
  const [suggestionsResponse, setSuggestionsResponse] = useState([]);
  // Get home translations
  const homeTranslations = staticTranslation[lang].home


  /**
   * This effect performs an AJAX call to get random suggestions.
   */
  useEffect(() => {
    fetch(window.SERVER_MAIN_PATH + "/utility/suggestions?language=" + lang, { method: 'GET' })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 200)
          setSuggestionsResponse({data: result.Suggestions, isLoaded: true, messageError: null});
        else if (result)
          setSuggestionsResponse({data: null, isLoaded: false, messageError: result.message});
      },
      (error) => {
        console.log(error);
      })
  }, [lang]);

  /**
   * This effect updates the page title.
   */
  useEffect(() => {
    document.title = "CArD 3D"
  }, []);


  return (
    <div className="flex flex-col items-center">
      <SearchBar handleMobileSidebar={handleMobileSidebar} mobileSidebarIsOpen={mobileSidebarIsOpen} isHomePage={true} />
      <SettingsBar languageEnabled={true} toggleEnabled={props.isDark} toggleOnChange={props.darkOnChange} settingsbarIsOpen={mobileSidebarIsOpen} />
      <div className="h-100vh min-h-home-xs lg:min-h-home-lg 2xl:min-h-home-2xl w-full flex flex-col items-center bg-white dark:bg-dark">
        <div className="hidden w-4/5 h-20vh lg:flex justify-between py-12">
          <div className="z-50 flex w-1/4 justify-center align-center focus:outline-none">
            <Toggle enabled={props.isDark} onChange={props.darkOnChange} />
          </div>
          {/* Empty container positioned under the fixed searchBar */}
          <div className="w-2/4" />
          <div className="z-50 flex w-1/4 justify-center align-center">
            <LanguageSelector languageEnabled={true} arrowColor={props.isDark ? "white" : "dark"} />
          </div>
        </div>
        {/* Logo */}
        <div className="flex flex-col items-center justify-center h-1/2 sm:h-2/3 lg:h-2/5 w-2/3 mt-16 sm:mt-0">
          <div className="w-full lg:w-3/5 pb-8">
            <img src={props.isDark ? darkLogo : lightLogo} alt="CArD 3D light logo" />
          </div>
          <div className="text-xl mx-4 mb-4">
            <span className="text-orange">C</span>arta <span className="text-orange">Ar</span>cheologica <span className="text-orange">D</span>igitale <span className="text-orange">3D</span> - Regione Veneto
          </div>
        </div>
        {/* Suggestions */}
        <div className="bg-dark bg-opacity-5 dark:bg-whiteop flex flex-col items-center h-1/3 lg:h-2/5 w-full lg:min-h-examples-lg 2xl:min-h-examples-2xl overflow-visible">
          <div className="text-2xl h-1/4 md:h-1/5 lg:h-1/4 pt-6">
            {parse(homeTranslations.examples)}
          </div>
          <div className="h-3/4 md:h-4/5 lg:h-3/4 w-full mb-4 lg:mb-0">{
            suggestionsResponse.isLoaded && <Carousel data={suggestionsResponse.data} />
          }{
            !suggestionsResponse.isLoaded && suggestionsResponse.messageError && 
              <Error message={suggestionsResponse.messageError.message} code={suggestionsResponse.messageError.code} details={suggestionsResponse.messageError.details} />
          }</div>
        </div>
      </div>

      <div id="sec1" className="flex flex-col items-center lg:h-70vh lg:min-h-sections-lg 2xl:min-h-sections-2xl w-full bg-white bg-opacity-5 dark:bg-dark dark:bg-opacity-5">
        <div className="text-center h-1/4 text-3xl pt-12 pb-12 mx-4">
          {parse(homeTranslations.sec1Title)}
        </div>
        <div className="h-3/4 mb-12 w-full flex flex-col items-center align-center lg:flex-row-reverse">
          <div className="h-3/5 w-1/10 bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5" />
          <div className="w-4/5 lg:w-2/5 h-2/5 lg:h-full">
            <div className="flex items-center h-full">
              <video className="rounded-3xl" id="video-player" width="820" height="480" src={process.env.PUBLIC_URL + "/assets/videos/sec1.mp4"} autoPlay="autoPlay" muted loop type="video/mp4">
                <p>Your browser cannot play the provided video file.</p>
              </video>
            </div>
          </div>
          <div className="w-3/5 lg:w-2/5 h-3/5 flex items-center text-xl bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-b-3xl lg:rounded-l-3xl lg:rounded-r-none px-6 lg:px-12 py-4 lg:text-left">
            <div>
              {parse(homeTranslations.sec1Body)}
            </div>
          </div>
        </div>
      </div>

      <div id="sec2" className="flex flex-col items-center lg:h-70vh lg:min-h-sections-lg 2xl:min-h-sections-2xl w-full bg-dark bg-opacity-5 dark:bg-whiteop">
        <div className="text-center h-1/4 text-3xl pt-12 pb-12 mx-4">
          {parse(homeTranslations.sec2Title)}
        </div>
        <div className="h-3/4 mb-12 w-full flex flex-col items-center align-center lg:flex-row">
          <div className="h-3/5 w-1/10 bg-white dark:bg-dark" />
          <div className="w-4/5 lg:w-2/5 h-2/5 lg:h-full">
            <div className="flex items-center h-full">
              <video className="rounded-3xl" id="video-player" width="820" height="480" src={process.env.PUBLIC_URL + "/assets/videos/sec2.mp4"} autoPlay="autoPlay" muted loop type="video/mp4">
                <p>Your browser cannot play the provided video file.</p>
              </video>
            </div>
          </div>
          <div className="w-3/5 lg:w-2/5 h-3/5 flex items-center text-xl bg-white dark:bg-dark rounded-b-3xl lg:rounded-l-none lg:rounded-r-3xl px-6 lg:px-12 py-4 lg:text-left">
            <div>
              {parse(homeTranslations.sec2Body)}
            </div>
          </div>
        </div>
      </div>

      <div id="sec3" className="flex flex-col items-center lg:h-70vh lg:min-h-sections-lg 2xl:min-h-sections-2xl w-full bg-white dark:bg-dark">
        <div className="text-center h-1/4 text-3xl pt-12 pb-12 mx-4">
          {parse(homeTranslations.sec3Title)}
        </div>
        <div className="h-3/4 mb-12 w-full flex flex-col items-center align-center lg:flex-row-reverse">
          <div className="h-3/5 w-1/10 bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5" />
          <div className="w-4/5 lg:w-2/5 h-2/5 lg:h-full bg-dark dark:bg-white rounded-3xl">
            <div className="h-48 md:h-80 lg:h-full">
              <model-viewer class="object-cover h-full w-full focus:outline-none" crossorigin="anonymous" src={process.env.PUBLIC_URL + '/assets/3DModels/monumento_concordia_LOW.glb'} camera-controls alt="A 3D model of a farm." max-camera-orbit="auto 105deg auto" min-camera-orbit="auto 0deg auto" />
            </div>
          </div>
          <div className="w-3/5 lg:w-2/5 h-3/5 flex items-center text-xl bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-b-3xl lg:rounded-l-3xl lg:rounded-r-none px-6 lg:px-12 py-4 lg:text-left">
            <div>
              {parse(homeTranslations.sec3Body)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  /**
   * This function opens and close the mobile side bar.
   * @param {*} event The onClick event.
   */
  function handleMobileSidebar(event) {
    event.preventDefault();
    setMobileSidebarIsOpen(!mobileSidebarIsOpen);
  }
}

export default Home;