// npm
import React from 'react';

// Stylesheet
import './imageViewer.css';


/**
 * This component renders an image viewer, displaying one image at time, on top of the underlying page.
 */
class ImageViewer extends React.Component {

  /**
   * Class constructor.
   * @param {*} props React properties:
   * - backgroundStyle: backround style, written as a 'style' attribute;
   * - currentIndex: the index of the image to be visualized when the ImageViewer is opened;
   * - imgs: the array of images to be displayed;
   * - onClose: function to handle the ImageViewer closing.
   */
  constructor(props) {

    super(props);

    this.state = {
      currentIndex: this.props.currentIndex === undefined
        ? 0
        : this.props.currentIndex,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.callOnClose = this.callOnClose.bind(this);

    document.addEventListener('keydown', this.handleKeyDown);

  }


  /**
   * This function changes the image displayed on the ImageViewer.
   * @param {int} direction An integer specifying the desired direction.
   */
  changeImage(direction) {
    let nextIndex = this.state.currentIndex + direction;
    if (nextIndex > this.props.imgs.length - 1)
      nextIndex = 0;
    else if (nextIndex < 0)
      nextIndex = this.props.imgs.length - 1;

    this.setState({
      currentIndex: nextIndex,
    });
  }


  /**
   * This function closes the Image Viewer.
   */
  callOnClose() {
    if (this.props.onClose !== undefined)
      this.props.onClose();
  }


  /**
   * This function is the 'keyDown' event handler used when the Image Viewer is open.
   * @param {*} event The 'keyDown' event
   */
  handleKeyDown(event) {
    if (event.key === 'Escape')
      this.callOnClose();

    if (['ArrowLeft', 'h'].includes(event.key))
      this.changeImage(-1);

    if (['ArrowRight', 'l'].includes(event.key))
      this.changeImage(1);

  }


  /**
   * This function is the 'click' event handler used when the Image Viewer is open.
   * @param {*} event The 'click' event.
   */
   handleClick(event) {
    if (event.target && event.target.id === 'ReactSimpleImageViewer')
      this.callOnClose();
  }


  render() {

    const currentImg = this.props.imgs[this.state.currentIndex].ImmagineLight;
    const length = this.props.imgs.length;

    return (
      <div id="ReactSimpleImageViewer" className="fixed w-full h-full box-border flex align-center items-center z-50 backdrop-blur" style={this.props.backgroundStyle} onClick={this.handleClick}>
        {/* Title */}
        <div className="absolute py-6 w-full top-0 text-center text-white text-lg px-16">
          <p>{currentImg.Descrizione}</p>
        </div>
        {/* Close button */}
        <div className="absolute cursor-pointer flex align-center justify-center items-center w-1/5 sm:w-1/10 px-2 py-6 lg:opacity-20 lg:hover:opacity-100 lg:transform lg:hover:scale-110 lg:duration-300 top-0 right-0" onClick={this.callOnClose}>
          <div className="rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong">
            <svg className="h-8 stroke-dark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        {/* Previous button */
        length > 1 && <div className="absolute flex items-center justify-center align-center opacity-20 lg:hover:opacity-100 lg:transform lg:hover:scale-110 lg:duration-300 w-1/5 sm:w-1/10 px-2 h-3/5 cursor-pointer left-0" onClick={() => this.changeImage(-1)}>
          <div className="rounded-full h-10 w-10 flex items-center justify-center align-center bg-white shadow-strong">
            <svg className={"h-8 stroke-dark"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </div>
        </div>}
        {/* Next button */
        length > 1 && <div className="absolute flex items-center justify-center align-center opacity-20 lg:hover:opacity-100 lg:transform lg:hover:scale-110 lg:duration-300 w-1/5 sm:w-1/10 px-2 h-3/5 cursor-pointer right-0" onClick={() => this.changeImage(1)}>
          <div className="rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong">
            <svg className={"h-8 stroke-dark"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </div>}
        {/* Image container */}
        <div className="mx-auto my-auto w-3/5 h-3/5 sm:w-4/5 sm:h-4/5 max-h-full text-center">
          <div className="h-full flex items-center align-center justify-center"> {
            currentImg.Link.substring(currentImg.Link.length-4) === ".mp4" ? 
              <video ref={elem => this.vid = elem} id="video-player" width="640" height="480" src={process.env.PUBLIC_URL + currentImg.Link} type="video/mp4" controls>
                <p>Your browser cannot play the provided video file.</p>
              </video>
            :
              <img className="h-auto max-h-full w-auto max-w-full rounded-3xl" src={process.env.PUBLIC_URL + currentImg.Link + ".jpg"} alt={"img"+this.state.currentIndex} />
          } </div>
        </div>
        {/* Description */
        currentImg.Autore ?
          <div className="absolute py-6 w-full bottom-0 text-center text-white text-sm">
            <p>{"Fonte: " + currentImg.Autore}</p>
          </div>
        :
          null}
      </div>
    )
  }
}

export default ImageViewer;
