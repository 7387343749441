// npm
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Custom functions
import { getElementList, getEpochByID } from './utils';

// Stylesheet
import './dataInsert.css';


/**
 * This component renders the form to insert into the database a new epoch
 * @param {*} props React props, namely:
 * - insertCount: the counter of the new insertions during the current session;
 * - setInsertCount: the function to update insertCount.
 * @returns The React component
 */
const EpochInsert = (props) => {
  
  // Redux
  const store = useSelector(state => state);
  const lang = store.lang;
  // React state
  const [epochID, setEpochID] = useState(-1);
  const [translationLang, setTranslationLang] = useState("ITA");
  const [epochCurrentState, setEpochCurrentState] = useState(null);
  // Select state
  const [epochSelectList, setEpochSelectList] = useState(null);

  /**
   * This effect is called when a new insert has been submitted, to update the displayed information.
   */
  useEffect(() => {
    async function fetchData() {
      setEpochSelectList(await getElementList('epoca', lang));
    }
   fetchData();
  }, [lang, props.insertCount])

  return (
    <div className="pb-20">
      {/* Form */}
      <form className="flex flex-col mx-4" id="NewEpochForm">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-8 my-4">
          {/* Epoch ID */}
          <div className="flex justify-center items-center col-span-2">
            <label className="pr-2">Epoca (se nuova non inserire)</label>
            <select className="w-60 bg-white text-dark" id="epochid" name="epochid" onChange={handlerEpochInput} onClick={handlerEpochInput}>
              <option value={-1}>Nuova epoca</option>
              {epochSelectList ?
                epochSelectList.List.map((element, index) => {
                  return <option key={index} value={element[Object.keys(element)[0]].ID}>{element[Object.keys(element)[0]].DTZG}</option>
                })
              : null}
            </select>
          </div>
          {/**Language */}
          <div className="flex justify-center items-center">
            <p className="mx-2">Seleziona la lingua:</p>
            <select id="translationlang" name="translationlang" value={translationLang} onChange={translationLangSelectorHandler} onClick={translationLangSelectorHandler} className="w-36 bg-white text-dark">
              <option value="ITA">Italiano</option>
              <option value="ENG">Inglese</option>
              <option value="DEU">Tedesco</option>
            </select>
          </div>
          <div className="flex justify-center items-center">
            <label className="pr-2 tooltip">DTZG<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Fascia cronologica di riferimento.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="dtzg" name="dtzg" placeholder={epochCurrentState ? epochCurrentState.DTZG : null} />
          </div>
        </div>
        {/* Submit */}
        <div className="flex justify-end mx-8">
          <button className="w-60 text-dark rounded-full mt-10 bg-white" onClick={handlerSubmitForm}>{"Crea nuova epoca"}</button>
        </div>
      </form>

    </div>
  )


  // ****************************************** HANDLERS ******************************************

  /**
   * This function handles the change in the new Epoch input field.
   * @param {*} event The onClick or onChange event in the input field.
   */
  async function handlerEpochInput(event) {
    const currentID = event.target.value;
    if (currentID !== -1 && currentID != null) {
      setEpochID(currentID);
      setEpochCurrentState(await getEpochByID(currentID, translationLang));
    } else {
      setEpochID(null);
      setEpochCurrentState(null);
    }
    console.log(epochCurrentState);
  }

  /**
   * This function handles the change in the language of the new epoch.
   * @param {*} event The onChange in the type selector
   */
  async function translationLangSelectorHandler(event) {
    setTranslationLang(event.target.value);
    if (epochID !== null)
      setEpochCurrentState(await getEpochByID(epochID, event.target.value));
  }

  /**
   * This handler submits the inserted data to the database server.
   * @param {*} event The onClick event that confirms the submission
   */
  function handlerSubmitForm(event) {
    event.preventDefault();

    // Get form
    var formEl = document.forms.NewEpochForm;
    var formData = new FormData(formEl);

    // Final element
    let finalJSON = {};
    finalJSON.Epoca = {};
    
    // ID 
    var id = epochID;
    finalJSON.Epoca.ID = parseInt(id);

    // Language
    var lang = formData.get('translationlang');
    finalJSON.Epoca.Lingua = lang;

    var dtzg = formData.get('dtzg');
    if (dtzg !== "")
      finalJSON.Epoca.DTZG = dtzg;
    else if (dtzg === "" && epochCurrentState)
      finalJSON.Epoca.DTZG = epochCurrentState.DTZG;
    else {
      alert("Inserire un DTZG valido");
      return;
    }

    if (finalJSON.Epoca.DTZG === "" || finalJSON.Epoca.DTZG === null) {
      alert("Inserire un DTZG valido");
      return;
    }
    
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',  
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, UPDATE',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(finalJSON)
    };

    fetch(window.SERVER_MAIN_PATH + "/insert/epoca", requestOptions)
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 201) {
          let count = props.insertCount + 1;
          props.setInsertCount(count); // New Render
          alert("Epoca inserita con ID: " + result.Epoca.ID);
        } else {
          console.log("ERRORE: " + JSON.stringify(result));
          alert("Inserimento non eseguito. Contatta TuSaiChi.");
        }   
      })
      .catch(function(error) {
        alert(error);
      });
  
    console.log(finalJSON);
  }

}

export default EpochInsert;