// npm
import React from 'react';
import { Link } from 'react-router-dom';
// Custom components
import Toggle from '../components/toggle.js';
import LanguageSelector from '../components/languageSelector.js';
import Tile from './tile.js';
import SettingsBar from '../components/settingsBar.js';
// Assets
import darkLogo from '../assets/imgs/darkLogo.png';
import lightLogo from '../assets/imgs/lightLogo.png';


/**
 * This class renders the Project page, where several information about the CArD 3D project can be found.
 * It requires the following properties:
 * - darkOnChange: function to handle the dark mode toggle;
 * - isDark: boolean variable to check if the dark mode is active.
 */
class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileSidebarIsOpen: false
    }
    this.handleMobileSidebar = this.handleMobileSidebar.bind(this);
  }

  handleMobileSidebar(event) {
    event.preventDefault();
    this.setState({
      mobileSidebarIsOpen: !this.state.mobileSidebarIsOpen
    });
    console.log(this.state);
  }

  componentDidMount(){
    document.title = "CArD 3D - Progetto"
  }

  render() {
    return (
      <div className="flex flex-col items-center">
        <SettingsBar toggleEnabled={this.props.isDark} toggleOnChange={this.props.darkOnChange} settingsbarIsOpen={this.state.mobileSidebarIsOpen} />
        <div className="hidden w-4/5 h-20vh lg:flex justify-between">
          <div className="z-50 flex w-1/4 justify-center align-center">
            <Toggle enabled={this.props.isDark} onChange={this.props.darkOnChange}/>
          </div>
          <div className="w-1/4 flex items-center z-50">
            <Link to="/">
              <img className="transform hover:scale-110 duration-300" src={this.props.isDark ? darkLogo : lightLogo} alt="CArD 3D light logo"/>
            </Link>
          </div>
          <div className="z-50 flex w-1/4 justify-center align-center">
            <LanguageSelector arrowColor={this.props.isDark ? "white" : "dark"} languageEnabled={false} />
          </div>
        </div>
        <h1 className="font-cinzel text-2xl md:text-5xl mb-6 mt-24 lg:mt-8">
          Il Progetto CArD 3D
        </h1>
        <p className="w-4/5 lg:w-3/5 text-justify lg:text-lg">
          In un momento storico caratterizzato da un’unicità senza precedenti, siamo chiamati a ripensare e progettare il futuro con occhi nuovi e prospettive differenti.
          L’importanza del distanziamento sociale e il rispetto delle più basilari norme di sicurezza ci impongono la rivisitazione del turismo a partire da una riscoperta dei territori regionali, spesso relegati ad un turismo più enogastronomico che culturale.
          Il territorio veneto è costellato di piccoli tesori da visitare e da fare propri e nell'impossibilità di accedervi è importante comunque conoscerli e apprezzarli.
          A partire da queste considerazioni nasce l’idea del Progetto CArD 3D (acronimo di Carta Archeologica Digitale) che ha lo scopo di raccogliere i modelli 3D dei contesti archeologici siti nella Regione Veneto, coinvolgendo Enti di Ricerca, liberi professionisti e studenti che sono interessati a pubblicare e far conoscere i loro lavori sfruttando questa piattaforma informatica.
        </p>
        <h1 className="font-cinzel text-3xl mb-8 mt-12 mx-2">
          Obiettivi del Progetto
        </h1>
        <p className="w-4/5 lg:w-3/5 text-justify lg:text-lg">
          Il progetto ha come scopo quello di raccogliere e presentare i contesti archeologici disseminati all'interno della Regione Veneto, in modo da favorirne la conoscenza e l’accessibilità attraverso la fruizione di modelli 3D navigabili e visionabili in differenti modalità.
          Attraverso una Web App sarà possibile visualizzare in mappa la consistenza del Patrimonio Archeologico Regionale e ottenere informazioni di dettaglio sui singoli siti di interesse.
          Ogni sito archeologico sarà rappresentato da un marker e corredato da una scheda descrittiva contenente anche la documentazione grafica e fotografica di riferimento, essenziale per la corretta visione di insieme del Bene.
        </p>
        <h1 className="font-cinzel text-3xl mb-8 mt-12 mx-2">
          Fasi del Progetto
        </h1>
        <ul className="grid lg:grid-cols-3 w-4/5 lg:w-3/5 text-center text-lg">
          <Tile
            title="Raccolta Dati"
            pathD1="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
            text="Individuazione dei siti archeologici di interesse e registrazione dei dati raccolti attraverso una mirata ricerca bibliografica."
          />
          <Tile
            title="Posizionamento Siti"
            pathD1="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            pathD2="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            text="Posizionamento dei siti archeologici in una cartografia della Regione Veneto gestita in ambiente GIS."
          />
          <Tile
            title="Sviluppo Web App"
            pathD1="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            text="Ideazione e impostazione di una Web App e di una banca dati finalizzati a raccogliere e presentare i siti archeologici e i modelli 3D."
          />
          <Tile
            title="Creazione Modelli 3D"
            pathD1="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
            text="Raccolta e adeguamento di 3D archeologici esistenti e creazione ex novo di modelli 3D di due casi studio da inserire nella Web App."
          />
          <Tile
            title="Studio Sostenibilità App"
            pathD1="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"
            text="Studio inerente la sostenibilità futura della Web App e le ricadute sull'economia del territorio."
          />
          <Tile
            title="Divulgazione Risultati"
            pathD1="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
            text="Disseminazione dei risultati e organizzazione di incontri e momenti di confronto con i detentori dei dati e i fruitori della Web App."
          />
        </ul>
        <h1 className="font-cinzel text-3xl mb-8 mt-12 mx-2">
          Potenzialità Future
        </h1>
        <p className="w-4/5 lg:w-3/5 text-justify lg:text-lg mb-12">
          CArD <span className="text-orange">3D</span> mira a diventare uno strumento per <span className="font-extrabold">capire</span>, <span className="font-extrabold">scoprire</span> e <span className="font-extrabold">conoscere</span> il patrimonio storico-archeologico e paesaggistico in maniera innovativa e coinvolgente al di fuori degli schemi classici di apprendimento.
          Uno strumento accessibile da dispositivi fissi e mobile, utile a <span className="font-extrabold">scuola</span> per insegnare, a <span className="font-extrabold">casa</span> o al <span className="font-extrabold">museo</span> per apprendere in autonomia, <span className="font-extrabold">sul territorio</span> per vivere l’esperienza di viaggiare nel tempo.
          Una App che punta a <span className="font-extrabold">incuriosire</span> e <span className="font-extrabold">fidelizzare</span> il pubblico grazie alla continua implementazione di modelli 3D e contenuti testuali disponibili per nuove aree e differenti periodi storici.
          Un modo per rendere <span className="font-extrabold">visibile</span> l’<span className="font-extrabold">invisibile</span>, per restituire valore ai luoghi e incentivare forme di turismo che abbiano ricadute positive sul territorio, in termini economici e di vivibilità.
          CArD 3D si delinea come <span className="font-extrabold">"progetto pilota"</span> che, a partire dal territorio veneto, può essere sviluppato e adattato da un punto di vista spaziale verso altre realtà regionali e temporale nelle diverse fasi storiche.
        </p>
      </div>
    )
  }
}

export default Project;
