// npm
import React, { useState, useEffect } from "react";
import { useTimer } from 'react-timer-hook';

/**
 * This component renders the Player in the InfoBar,
 * when an audio description file is available for the current element.
 * It requires the following React property:
 * - url: the URL of the audio to be loaded.
 */
const Player = ({ url }) => {

  // React state
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  // A timestamp containing the current time, used for the timer
  const time = new Date();

  // The react-timer-hook
  const {
    seconds,
    minutes,
    start,
    restart,
  } = useTimer({ time, onExpire: () => console.warn('onExpire called') });


  /**
   * This effect is used to play/pause the audio
   */ 
  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  /**
   * This function performs the operations to restart the track,
   * whether it is finished or the user presses the play/pause button
   */
  const restartTrack = () => {
    audio.currentTime = 0;
    time.setSeconds(new Date().getSeconds() + audio.duration);
    restart(time, false);
  };

  /**
   * This function handles the play/pause button
   */
  const toggle = () => {
    if (playing)
      restartTrack();
    else
      start();
    setPlaying(!playing);
  }

  /**
   * The following event listener updates the timer with the audio duration,
   * which is available when it is completely loaded 
   */ 
  audio.addEventListener('loadedmetadata', (e) => {
    time.setSeconds(new Date().getSeconds() + e.target.duration);
    restart(time, false);
  });

  /**
   * The following event listener handles the track ending
   */ 
  audio.addEventListener('ended', () => {
    setPlaying(false);
    restartTrack();
  });


  return (
    <div className="flex justify-center items-center">
      <button className="flex justify-center items-center transition-all duration-300 transform hover:scale-110" onClick={() => { toggle() }}>
        <div className="flex justify-center items-center">
          <span className={"absolute h-12 w-12 rounded-full bg-orange " + (playing ? "animate-ping" : "")} />
          <span className={"relative h-12 w-12 rounded-full bg-orange flex justify-center items-center"}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
            </svg>
          </span>
        </div>
      </button>
      <span className="ml-6 text-left w-12">
        {minutes} : {seconds < 10 ? "0" + seconds : seconds}
      </span>
    </div>
  );
};

export default Player;
