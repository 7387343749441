// npm
import React from 'react';

// Assets
import light from './light.svg';
import dark from './dark.svg';


/**
 * This React class renders the dark mode toggle.
 * It takes the following React properties:
 * - enabled: a boolean value to indicate if the dark mode is active;
 * - onChange: the function to be called when the toggle is clicked.
 */
class Toggle extends React.Component {
  render() {
    return (
      <label htmlFor="darkmode-toggle" className="inline-flex items-center cursor-pointer">
        <span className="relative">
          {/*
            User input: checkbox to toggle Dark mode.
            Initialized from App.js state,
            Handler in App.js.
          */}
          <input id="darkmode-toggle" type="checkbox" className="absolute hidden" checked={this.props.enabled} onChange={this.props.onChange} />
          {/* The rounded toggle with fixed Moon and Sun images. */}
          <span id="darkmode-bg" className="block w-16 h-8 bg-dark dark:bg-white rounded-full dark:shadow-inner">
            <img src={dark} alt="Darkmode icon" className="absolute h-6 my-1 mx-1" />
            <img id="darkmode-light" src={light} alt="Lightmode icon" className="absolute h-6 my-1 mx-1 left-8" />
          </span>
          {/* Toggle dot with animation */}
          <span id="darkmode-dot" className="absolute block w-6 h-6 my-1 mx-1 bg-white dark:bg-dark rounded-full inset-y-0 left-0 focus-within:shadow-outline" />
        </span>
      </label>
    )
  }
}

export default Toggle;
