// npm
import React from 'react';

// Stylesheet
import './dataInsert.css';


/**
 * This component renders the form to insert into the database a Limite Amministrativo
 * @param {*} props React props.
 * @returns The React component.
 */
const AdminLimitsInsert = (props) => {
  
  // const store = useSelector(state => state);
  // const lang = store.lang;

  return (
    <div className="pb-20">
      {/* Form */}
      <form className="flex flex-col mx-4" id="AdminLimitsForm">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-8 my-4">
          <div className="flex justify-center items-center">
            <label className="pr-2 tooltip">GeoJSON<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Inserire GeoJSON.</span>
            </label>
            <input className="text-dark px-1 w-full" type="file" id="json" name="json" accept=".json, .geojson" />
          </div>
          {/* Submit */}
          <div className="flex justify-end mx-8 items-center">
            <button className="w-64 text-dark rounded-full bg-white" onClick={handlerSubmitForm}>{"Crea nuovo inserimento"}</button>
          </div>
        </div>
      </form>
    </div>
  )


  // ****************************************** HANDLERS ******************************************
  
  /**
   * This function submits the GeoJSON
   * @param {*} event The onClick event.
   */
  async function handlerSubmitForm(event) {
    event.preventDefault();

    var formEl = document.forms.AdminLimitsForm;
    var formData = new FormData(formEl); 
    var file = await formData.get('json').text();
    var json = JSON.parse(file).features;

    var finalQuery = "";
    
    for (let i = 0; i < json.length; i++) {
      const element = json[i];
      const properties =  element.properties;
      const geometry = element.geometry;
      const name = properties.nomcom.replace(/'/g, "''");
      const provincia = properties.provincia;
      const area = properties.area;
      const perimetro = properties.perimetro;
      const codISTAT = properties.codistat;
      const geomArray = geometry.coordinates;

      var geom = "POLYGON((";

      if (geometry.type === "MultiPolygon") {
        for (let j = 0; j < geomArray.length; j++) {
          const subPoly = geomArray[j][0];
          for (let y = 0; y < subPoly.length; y++) {
            const subCoord = subPoly[y];
            geom = geom + subCoord[0] + " " + subCoord[1] + ", ";
            if (y === subPoly.length-1)
              geom = geom.substring(0, geom.length-2) + "), ";
          }
          if (j === geomArray.length-1) {
            console.log("fine");
            geom = geom.substring(0, geom.length-2) + ")";
          } 
        }
        finalQuery = finalQuery + "\n INSERT INTO \"LimiteAmministrativo\" (\"Nome\", \"Provincia\", \"Area\", \"Perimetro\", \"CodISTAT\", \"Geom\") VALUES ('" + name + "', '" + provincia + "', " + area + ", " + perimetro + ", '" + codISTAT + "', ST_GeomFromText('" + geom + "',3857));";
      }
      
      for (let j = 0; j < geomArray.length; j++) {
        geom = geom + geomArray[j][0] + " " + geomArray[j][1] + ", ";
        if (j === geomArray.length-1)
          geom = geom.substring(0, geom.length-2) + "))";
      }
      //finalQuery = finalQuery + "\n INSERT INTO \"LimiteAmministrativo\" (\"Nome\", \"Provincia\", \"Area\", \"Perimetro\", \"CodISTAT\", \"Geom\") VALUES ('" + name + "', '" + provincia + "', " + area + ", " + perimetro + ", '" + codISTAT + "', ST_GeomFromText('" + geom + "',3857));";
    }

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(finalQuery));
    element.setAttribute('download', 'query.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    console.log(finalQuery);
  }

}

export default AdminLimitsInsert;