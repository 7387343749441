// npm
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Polygon, ZoomControl, useMap, LayersControl, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Link, useParams, useHistory } from 'react-router-dom';
import L, { latLngBounds } from 'leaflet';
// Custom components
import InfoBar from '../components/infoBar/infoBar.js';
import SearchBar from '../components/searchBar/searchBar.js';
import SettingsBar from '../components/settingsBar.js';
import InfoBarHeader from '../components/infoBar/infoBarHeader.js';
import ImageViewer from '../components/imageViewer/imageViewer.js';
import Error from '../components/error.js';
// Custom functions
import { processPOLY, getElementCenter, getElementCoord } from './utils.js';
// Assets
import loading3D from '../assets/imgs/loading3D.gif';
import blueIconURL from '../assets/imgs/marker/marker-icon-blue.png';
// Stylesheet
import './explore.css';


// The previous visualised element, needed for the correct rendering of ChangeView function component
let oldElement = { data: null, centerCoord: [45.5443172, 11.5562483], isLoaded: false, message: null };


/**
 * This component renders the exploration page, which is the main interaction page with the information stored on the server;
 * it shows the map of the Regione Veneto or the 3D model visualiser, depending on the actions of the user.
 * Together with these elements, several informations concerning the current visualised element are shown to the user.
 * @param {*} props React props, namely:
 * - isDark: A boolean variable to indicate if dark mode is active;
 * - darkOnChange: the function to be called when the dark mode toggle is pressed.
 * @returns The React component
 */
const Explore = (props) => {

  // ************************************* SETUP VARIABLES ************************************* //
  // Routes
  let { view, type, id } = useParams();// REACT ROUTER
  const history = useHistory();
  const routeToHome = useCallback(() => history.push('/'), [history]);
  const routeToMap = useCallback((id, type) => history.push('/explore/map/'+type.toLowerCase()+'/'+id), [history]);
  // Redux
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const lang = store.lang;
  const storeCurrentElement = store.currentElement;
  const storeSearchedElements = store.searchedElements;
  // Data States
  const [searchedElements, setSearchedElements] = useState(storeSearchedElements);
  const [currentElement, setCurrentElement] = useState({ data: null, centerCoord: [45.5443172, 11.5562483], isLoaded: false, message: null });
  const [current3DModels, setCurrent3DModels] = useState({ Archeo: null, Complete: null, Error: false });
  const [viewArcheo3D, setViewArcheo3D] = useState(true);
  const [viewHotspots, setViewHotspots] = useState(false);
  const [proMode, setProMode] = useState(false); // InfoBar Pro mode, activated in settingsBar
  // Used to access to the current 3D model
  let current3DModelsKey = "Archeo";
  if (viewArcheo3D && current3DModels.Archeo)
    current3DModelsKey = "Archeo";
  else
    current3DModelsKey = "Complete";
  // States related to the CSS behaviours.
  // Handlers are on the bottom of this file.
  const [blur, setBlur] = useState("backdrop-blur");
  const [mobileSidebarExpand, setMobileSidebarExpand] = useState("");
  const [mobileSidebarHeight, setMobileSidebarHeight] = useState("h-60v");
  const [mobileSidebarIsOpen, setMobileSidebarIsOpen] = useState(false);
  const [mobileSidebarReduce, setMobileSidebarReduce] = useState("hidden");
  const [show3Doptions, setShow3DOptions] = useState(false);
  const [showSkybox, setShowSkybox] = useState(false);
  // State for Evolution slider
  const [evolutionValue, setEvolutionValue] = useState(-1);


  // ********************************* LEAFLET SETUP VARIABLES ********************************* //
  const greenArea = { color: 'mediumSeaGreen' };
  const purpleArea = { color: 'darkViolet' };
  const blueIcon = new L.icon({
    iconUrl: blueIconURL,
    iconSize: [25,41],
    iconAnchor: [12.5,41],
    popupAnchor: [12.5,41]
  });
  const smallBlueIcon = new L.icon({
    iconUrl: blueIconURL,
    iconSize: [15,24],
    iconAnchor: [7.5,24],
    popupAnchor: [7.5,24]
  });


  // *********************************** IMAGE VIEWER SETUP ************************************ //
  // State to manage ImageViewer
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  /**
   * This function is used to open the image viewer
   */
  const openImageViewer = useCallback((index) => {
    setCurrentIndex(index);
    setIsViewerOpen(true);
  }, []);

  /**
   * This function is used to open the image viewer
   */
  const closeImageViewer = () => {
    setCurrentIndex(0);
    setIsViewerOpen(false);
  };


  // ************************************* REACT EFFECTS *************************************** //
  /**
   * This effect is triggered anytime storeSearchedElements changes
   */
  useEffect(() => {
    // Check if there are errors, for example when an element is not available in the selected language
    // This prevents an infinite loop of calls to the server
    if (storeCurrentElement.messageError == null) {
      setSearchedElements(storeSearchedElements);
      if (storeCurrentElement.data !== null)
        setCurrentElement({
          data: storeCurrentElement.data,
          centerCoord: getElementCenter(storeCurrentElement.data),
          isLoaded: storeCurrentElement.isLoaded,
          message: storeCurrentElement.message
        });
      else {
        let randomIndex = Math.round(Math.random() * (storeSearchedElements.data.length - 1));
        updateCurrentElement(storeSearchedElements.data[randomIndex].SearchResult.ID, storeSearchedElements.data[randomIndex].SearchResult.Tipo);
      }
      // Set mobile sidebar correctly following a search
      setMobileSidebarIsOpen(false);
      if (mobileSidebarHeight === "h-0" && view !== "model")
        setMobileSidebarHeight("h-60v");
      else if (view === "model")
        setMobileSidebarHeight("h-0");
      setBlur("backdrop-blur");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeSearchedElements, storeCurrentElement]);

  /**
   * This effect updates oldElement variable, so that ChangeView is rendered only when necessary
   */
  useEffect(() => {
    oldElement = currentElement;
  }, [currentElement]);

  /**
   * This effect redirects the user to the homepage if it is looking for an incorrect URL path,
   * and it sets the correct tab title.
   * Finally, it is responsible for updating the visualised current element.
   */
  useEffect(() => {
    if ((view !== "map" && view !== "model") || (type !== "monumento" && type !== "complesso" && type !== "sito"))
      routeToHome();
    if (view === "map") {
      setShowSkybox(false);
      setShow3DOptions(false);
      document.title = "CArD 3D - Mappa";
    } else
      document.title = "CArD 3D - Model";
    updateCurrentElement(id, type, view);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, view]);

  /**
   * This effect updates the current 3D model
   */
  useEffect(() => {
    if (currentElement.isLoaded) {
      let model3DList = currentElement.data[Object.keys(currentElement.data)[0]].Modelli3D;
      // Redirect to map if the element does not have any 3D model.
      if (!model3DList) {
        setCurrent3DModels({
          Archeo: null,
          Complete: null,
          Error: true
        });
        return;
      }
      if (model3DList.length > 0) {
        current3DModels.Archeo = null;
        current3DModels.Complete = null;
        for (let i = 0; i < model3DList.length; i++) {
          const element = model3DList[i];
          fetch(window.SERVER_MAIN_PATH + "/info/modello3D/" + element.Modello3DLight.ID + "?language=" + lang, { method: 'GET' })
            .then(res => {
              const statusCode = res.status;
              const data = res.json();
              return Promise.all([statusCode, data]);
            })
            .then(([statusCode, result]) => {
              if (statusCode === 200) {
                if (result.Modello3D.Alzato) {
                  current3DModels.Archeo = result.Modello3D;
                  current3DModels.Error = false;
                }
                if (!result.Modello3D.Alzato) {
                  current3DModels.Complete = result.Modello3D;
                  current3DModels.Error = false;
                  setViewArcheo3D(false);
                } 
              } else if (result) {
                setCurrent3DModels({
                  Archeo: null,
                  Complete: null,
                  Error: true
                });
              }
            },
            (error) => {
              console.log(error);
            })
        }
      } else {
        setCurrent3DModels({
          Archeo: null,
          Complete: null,
          Error: true
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElement, isViewerOpen, lang]);

  console.log(currentElement.centerCoord[0] !== oldElement.centerCoord[0] || currentElement.centerCoord[1] !== oldElement.centerCoord[1])

  return (
    <>
      {/* Map and searchbar and sidebar container */}
      <div className="lg:flex lg:flex-row">
        {/* Map and searchbar */}
        <div className="absolute lg:relative inset-x-0 lg:inset-y-0 bottom-0 lg:right-0 w-full lg:w-8/12 h-100v lg:h-screen flex justify-center">
          {/* SearchBar. On mobile it becomes also the header of the page and opens the SettingsBar. */}
          <SearchBar handleMobileSidebar={handleMobileHomeSidebar} mobileSidebarIsOpen={mobileSidebarIsOpen} isHomePage={false} setEvolutionValue={setEvolutionValue} />
          <SettingsBar languageEnabled={false} toggleEnabled={props.isDark} toggleOnChange={props.darkOnChange} settingsbarIsOpen={mobileSidebarIsOpen} proMode={proMode} setProMode={setProMode} showCredits={true} />
          {view === "map" ?
            <MapContainer className="z-0 w-full h-full" zoomControl={false} center={[currentElement.centerCoord ? currentElement.centerCoord[0] : "45.5443172", currentElement.centerCoord ? currentElement.centerCoord[1] : "11.5562483"]} zoom={17} scrollWheelZoom={true} tap={false}>
              {/* Change the map center */
                (currentElement.centerCoord[0] !== oldElement.centerCoord[0] || currentElement.centerCoord[1] !== oldElement.centerCoord[1]) || mobileSidebarHeight === "h-0" ? // First check to prevent unecessary movements after manually moving around the map, second check to translate the visual when closing the mobile InfoBar
                <ChangeView center={[currentElement.centerCoord[0], currentElement.centerCoord[1]]} sidebarHeight={mobileSidebarHeight} currentElement={currentElement} oldElement={oldElement} />
              : null}
              <LayersControl position="bottomleft">
                {/* Open Street Map layer */}
                <LayersControl.BaseLayer checked name="Mappa">
                  <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"minZoom="0" maxZoom="19" />
                </LayersControl.BaseLayer>
                {/*OrthoPhoto layer*/}
                <LayersControl.BaseLayer name="Satellite">
                  <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://idt2.regione.veneto.it/gwc/service/wmts??&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=default&TILEMATRIXSET=EPSG:900913&FORMAT=image/jpeg&LAYER=rv:OrthoPhoto_2015_pyramid&TILEMATRIX=EPSG:900913:{z}&TILEROW={y}&TILECOL={x}" minZoom = "13" maxZoom = "19" />
                </LayersControl.BaseLayer>
                {/* Zoom button position visible only on desktop */
                !(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) && <ZoomControl position="bottomleft" />}
                <MarkerClusterGroup showCoverageOnHover={false} disableClusteringAtZoom={17} spiderfyOnMaxZoom={false} maxClusterRadius={50} onClick={(a) => { handlerMobileSidebarCross() }}>{
                  searchedElements && searchedElements.data && searchedElements.data.map((element, idx) => 
                    element.SearchResult.Tipo === "Monumento" ?
                      <Marker
                        key={`marker-${idx}`}
                        position={JSON.parse(element.SearchResult.Coordinate)}
                        icon={
                          currentElement.data ?
                            currentElement.data[Object.keys(currentElement.data)].OGTN === element.SearchResult.OGTN ? blueIcon : smallBlueIcon
                          : null
                        }
                        eventHandlers={{
                          click: () => {
                            // IF a marker is clicked, update css classes and current element that is visualized
                            setMobileSidebarHeight("h-60v");
                            setBlur("backdrop-blur");
                            routeToMap(element.SearchResult.ID, element.SearchResult.Tipo.toLowerCase());
                            updateCurrentElement(element.SearchResult.ID, element.SearchResult.Tipo);
                            setEvolutionValue(-1);
                          },
                        }}
                      >
                        <Tooltip>{element.SearchResult.OGTN}</Tooltip>
                      </Marker>
                    :
                      element.SearchResult.Tipo === "Sito" ?
                        <Polygon
                          key={`polyline-${idx}`}
                          positions={processPOLY(element.SearchResult.Coordinate)}
                          pathOptions={purpleArea}
                          eventHandlers={{ 
                            click: () => {
                              // IF a marker is clicked, update css classes and current element that is visualized
                              setMobileSidebarHeight("h-60v");
                              setBlur("backdrop-blur");
                              routeToMap(element.SearchResult.ID, element.SearchResult.Tipo.toLowerCase());
                              updateCurrentElement(element.SearchResult.ID, element.SearchResult.Tipo);
                              setEvolutionValue(-1);
                            }
                          }}
                        >
                          <Tooltip sticky>{element.SearchResult.OGTN}</Tooltip>
                        </Polygon>
                      :
                        element.SearchResult.Tipo === "Complesso" ?
                        <Polygon
                          key={`polyline-${idx}`}
                          positions={processPOLY(element.SearchResult.Coordinate)}
                          pathOptions={greenArea}
                          eventHandlers={{ 
                            click: () => {
                              // IF a marker is clicked, update css classes and current element that is visualized
                              setMobileSidebarHeight("h-60v");
                              setBlur("backdrop-blur");
                              routeToMap(element.SearchResult.ID, element.SearchResult.Tipo.toLowerCase());
                              updateCurrentElement(element.SearchResult.ID, element.SearchResult.Tipo);
                              setEvolutionValue(-1);
                            }
                          }}
                        >
                          <Tooltip sticky>{element.SearchResult.OGTN}</Tooltip>
                        </Polygon>
                      : null
                  )
                }</MarkerClusterGroup>
              </LayersControl>
            </MapContainer>
          :
            view === "model" ?
              <>
                {/* Button to go back to the map or close the skybox. History needs to be implemented. */}
                <div className="absolute z-10 lg:z-40 h-30v lg:h-10v w-1/8 lg:w-1/12 flex items-center justify-center left-0 top-0 mx-3">{
                  !showSkybox ?
                    <Link to={"/explore/map/"+type+"/"+id} className="rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong transition-all duration-300 transform hover:scale-110">
                      <svg className={"h-8 stroke-dark"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                      </svg>
                    </Link>
                  :
                    <button className="rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong transition-all duration-300 transform hover:scale-110" onClick={() => {setShowSkybox(false)}}>
                      <svg className="h-8 stroke-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                }</div>
                {/* Button to open infoBar in mobile view */}
                <div className="absolute z-10 lg:z-40 h-30v lg:h-10v w-1/8 lg:w-1/12 flex items-center justify-center right-0 top-0 mx-3">
                  <div className="lg:hidden rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong transition-all duration-300 transform hover:scale-110" onClick={handlerMobileOpenSidebar}>
                    <svg className="h-8 stroke-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                </div>

                {/* Button 3D settings box */}
                <div className="absolute z-11 lg:z-40 h-30v lg:h-10v w-1/12 flex flex-col items-center justify-center center-1/2 lg:right-4 left-auto">
                  <div className="rounded-full z-20 h-10 w-10 flex items-center justify-center bg-white shadow-strong cursor-pointer transition-all duration-300 transform hover:scale-110" onClick={() => setShow3DOptions(!show3Doptions)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 my-1 stroke-dark" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                    </svg>
                  </div>
                  {/* 3D settings box */}
                  <div className={"absolute z-10 flex items-center origin-top lg:origin-top-right transform ease-in-out transition-all duration-500 " + (show3Doptions ? "scale-100 top-2/3 lg:top-24 lg:right-2" : "scale-0 top-1/2 lg:right-4")}>
                    <div className={"rounded-lg w-80 md:w-96 flex flex-col items-center bg-white shadow-strong"  + (show3Doptions ? "" : "hidden") }>
                      {/* Button to view archeological 3D */}
                      <div className="flex flex-row items-center px-2 md:px-4 my-4 w-full">
                        <div className="rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong cursor-pointer transition-all duration-300 transform hover:scale-110" onClick={() => setViewArcheo3D(!viewArcheo3D)}>
                          <svg className={"h-8 w-8 stroke-dark " + (current3DModels.Archeo ? "" : "opacity-10")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">{
                            (!viewArcheo3D || !current3DModels.Archeo) && <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                          }{
                            (viewArcheo3D && current3DModels.Archeo) && <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                          }</svg>
                        </div>
                        <div className="mx-2 md:mx-4 text-dark text-left text-sm">{
                          viewArcheo3D && current3DModels.Archeo ?
                            "Mostra modello completo"
                          : (current3DModels.Archeo ?
                              "Mostra alzati"
                            : "Nessun alzato per questo modello")
                        }</div>
                      </div>
                      {/* Button to view hotspots */
                        current3DModels[current3DModelsKey] && current3DModels[current3DModelsKey].Hotspots.length > 0 ?
                        <div className="flex flex-row items-center px-2 md:px-4 mb-4 w-full">
                          <div className="rounded-full h-10 w-10 flex items-center justify-center bg-white shadow-strong cursor-pointer transition-all duration-300 transform hover:scale-110" onClick={() => setViewHotspots(!viewHotspots)}>
                            <svg className={"h-8 stroke-dark " + (viewHotspots ? "" : "opacity-10")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                          </div>
                          <div className="mx-2 md:mx-4 text-dark text-sm">{
                            viewHotspots ? "Nascondi etichette" : "Mostra etichette"
                          }</div>
                        </div>
                      : null}
                    </div>
                  </div>
                </div>
                {current3DModels.Error ?
                  <Error message="No 3D models found" code="404" details="Can not retrieve 3D models for this element." />
                :
                  ((current3DModels.Archeo) || (current3DModels.Complete)) && !showSkybox ?
                    <model-viewer
                      class="h-full w-full z-0 focus:outline-none"
                      max-camera-orbit="auto 95deg auto"
                      min-camera-orbit="auto 0deg auto"
                      crossorigin="anonymous"
                      environment-image={(current3DModels[current3DModelsKey].Skyboxes.length > 0) ? process.env.PUBLIC_URL + current3DModels[current3DModelsKey].Skyboxes[0].Skybox.Link : null} // Naive approach
                      exposure={(current3DModels[current3DModelsKey].Skyboxes.length > 0) ? current3DModels[current3DModelsKey].Skyboxes[0].Skybox.Esposizione : null}
                      src={process.env.PUBLIC_URL + current3DModels[current3DModelsKey].Link}
                      ar ar-modes="webxr scene-viewer quick-look"
                      ar-scale="auto"
                      camera-controls alt="A 3D model of a farm."
                      loading="eager"
                      poster={loading3D}
                    >
                      <button slot="ar-button" id="ar-button" className="flex flex-row justify-center items-center bottom-16 shadow-strong">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 stroke-dark mr-2" fill="none" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        Realtà Aumentata!
                      </button>
                      {current3DModels[current3DModelsKey].Hotspots.map((hotspot, index) => {
                        return (
                          <button key={index} className={"Hotspot shadow-lg " + (viewHotspots ? "block" : "hidden")} slot={"hotspot-" + index} data-position={hotspot.Hotspot.Posizione} data-normal={hotspot.Hotspot.Normale} data-visibility-attribute="visible">
                            <div className="HotspotAnnotation shadow-lg text-dark">
                              <p>{hotspot.Hotspot.Testo}</p>
                              {hotspot.Hotspot.Immagine ?
                                <img src={process.env.PUBLIC_URL + hotspot.Hotspot.Immagine + ".jpg"} alt={"hotspot-img-" + index} className="h-40 w-auto max-w-none"></img>
                              : null}
                            </div>
                          </button>
                        )
                      })}
                      {id === "53" && type.toLowerCase() === "monumento" && !viewArcheo3D ? // Teatro Romano
                        <button key="hotspot-skybox" className="Hotspot shadow-lg block" slot="hotspot-skybox" data-position="0.04289268538776031m 0.3893409231264719m -0.931729045425268m" data-normal="1.9505170544753148e-7m 0.9999999999998193m 5.689468827919501e-7m" data-visibility-attribute="visible">
                          <div className="HotspotAnnotation shadow-lg text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute animate-ping-slow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                            <svg onClick={() => {setShowSkybox(true); setShow3DOptions(false)}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 relative" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                          </div>
                        </button>
                      : null}
                      {id === "54" && type.toLowerCase() === "monumento" ? // Anfiteatro Romano
                        <button key="hotspot-skybox" className="Hotspot shadow-lg block" slot="hotspot-skybox" data-position="0.1023289391654039m 0.010770887135817309m 0.20378312758012282m" data-normal="0.0014453311939664284m 0.999996823636349m 0.0020648813410705743m" data-visibility-attribute="visible">
                          <div className="HotspotAnnotation shadow-lg text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute animate-ping-slow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                            <svg onClick={() => {setShowSkybox(true); setShow3DOptions(false)}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 relative" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                          </div>
                        </button>
                      : null}
                      {id === "55" && type.toLowerCase() === "monumento" ? // Ponte San Lorenzo
                        <button key="hotspot-skybox" className="Hotspot shadow-lg block" slot="hotspot-skybox" data-position="-0.5229415356226654m -0.9349243718374272m 0.04123958549271278m" data-normal="0m -3.258413698858758e-7m 0.9999999999999469m" data-visibility-attribute="visible">
                          <div className="HotspotAnnotation shadow-lg text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute animate-ping-slow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                            <svg onClick={() => {setShowSkybox(true); setShow3DOptions(false)}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 relative" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                          </div>
                        </button>
                      : null}
                      {id === "58" && type.toLowerCase() === "monumento" && viewArcheo3D ? // Palazzo Maldura
                        <button key="hotspot-skybox" className="Hotspot shadow-lg block" slot="hotspot-skybox" data-position="3.885632217917294m 0.42856975137800646m 0.9643615383809134m" data-normal="-0.05358884578848771m 0.9892509061281531m -0.13605469610302215m" data-visibility-attribute="visible">
                          <div className="HotspotAnnotation shadow-lg text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute animate-ping-slow" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                            <svg onClick={() => {setShowSkybox(true); setShow3DOptions(false)}} xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 relative" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                            </svg>
                          </div>
                        </button>
                      : null}
                    </model-viewer>
                  :
                    (id === "53" && type.toLowerCase() === "monumento") && !viewArcheo3D ?
                      <div id="myEmbeddedScene" className={"absolute w-full h-screen block"}>
                        <a-scene embedded vr-mode-ui="enabled: false">
                          <a-assets>
                            <img alt="skybox" id="skybox" src={process.env.PUBLIC_URL + '/assets/skyboxes/TeatroRomano_Render_360.jpg'} /> 
                          </a-assets>
                          <a-sky id="image-360" radius="10" src="#skybox" />         
                        </a-scene>
                      </div>
                    :
                      (id === "54" && type.toLowerCase() === "monumento") && !viewArcheo3D ?
                        <div id="myEmbeddedScene" className={"absolute w-full h-screen block"}>
                          <a-scene embedded vr-mode-ui="enabled: false">
                            <a-assets>
                              <img alt="skybox" id="skybox" src={process.env.PUBLIC_URL + '/assets/skyboxes/AnfiteatroRomano_Render_360.jpg'} /> 
                            </a-assets>
                            <a-sky id="image-360" radius="10" src="#skybox" />         
                          </a-scene>
                        </div>
                      :
                        (id === "55" && type.toLowerCase() === "monumento") ?
                          <div id="myEmbeddedScene" className={"absolute w-full h-screen block"}>
                            <a-scene embedded vr-mode-ui="enabled: false">
                              <a-assets>
                                <img alt="skybox" id="skybox" src={process.env.PUBLIC_URL + '/assets/skyboxes/PonteSanLorenzo_Render_360.jpg'} /> 
                              </a-assets>
                              <a-sky id="image-360" radius="10" src="#skybox" />         
                            </a-scene>
                          </div>
                        : 
                          (id === "58" && type.toLowerCase() === "monumento") && viewArcheo3D ?
                            <div id="myEmbeddedScene" className={"absolute w-full h-screen block"}>
                              <a-scene embedded vr-mode-ui="enabled: false">
                                <a-assets>
                                  <img alt="skybox" id="skybox" src={process.env.PUBLIC_URL + '/assets/skyboxes/RecintoFunerarioMaldura.jpeg'} /> 
                                </a-assets>
                                <a-sky id="image-360" radius="10" src="#skybox" />         
                              </a-scene>
                            </div>
                          : null}
              </>
            : null}
        </div>
        <InfoBarHeader handleMobileHomeSidebar={handleMobileHomeSidebar} mobileSidebarIsOpen={mobileSidebarIsOpen} />
        <div className={mobileSidebarHeight + " z-20 lg:h-screen absolute lg:relative inset-x-0 bottom-0 transition-all duration-500 ease-in-out lg:w-4/12 order-last lg:order-first flex-auto flex-col overflow-auto bg-white dark:bg-dark bg-opacity-80 dark:bg-opacity-90 lg:bg-opacity-100 backdrop-blur"}>
          <InfoBar
            mobileSidebarExpand={view === "map" ? mobileSidebarExpand : "hidden"}
            mobileSidebarReduce={view === "map" ? mobileSidebarReduce : "hidden"}
            handlerMobileSidebarCross={handlerMobileSidebarCross}
            handlerMobileSidebarExpand={handlerMobileSidebarExpand}
            handlerMobileSidebarReduce={handlerMobileSidebarReduce}
            updateCurrentElement={updateCurrentElement}
            button3D={view === "map" ? "flex" : "hidden"}
            openImageViewer={openImageViewer}
            proMode={proMode}
            evolutionValue={evolutionValue}
            setEvolutionValue={setEvolutionValue}
            model3D={current3DModels["Complete"]}
          />
        </div>
        {/* Image Viewer */
        isViewerOpen && <ImageViewer currentIndex={currentIndex} imgs={currentElement.data[Object.keys(currentElement.data)].Immagini} onClose={closeImageViewer} backgroundStyle={{ backgroundColor: "rgba(13, 31, 45, 0.9)" }} />}
      </div>
    </>
  )


  // **************************************** FUNCTIONS **************************************** //

  /**
   * This function updates the currentElement state
   * @param {Int} id The ID of the new element
   * @param {String} type The typology of the new element
   */
  function updateCurrentElement(id, type) {
    const elementType = type.toLowerCase();
    setEvolutionValue(-1);

    fetch(window.SERVER_MAIN_PATH + "/info/" + elementType + "/" + id + "?language=" + lang, { method: 'GET' })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 200) {
          // Set current element
          let currentElementKey = Object.keys(result)[0];
          let coord = getElementCoord(result, currentElementKey);
          if (!coord) { // Check if the element has coordinates
            dispatch({
              type: "CHANGE_CURRENT_ELEMENT",
              payload: {
                data: null,
                isLoaded: false,
                messageError: "Element not found."
              }
            });
            return;
          }
          // Update complessi in searched elements
          let provList = searchedElements.data;
          if (storeCurrentElement.data && Object.keys(storeCurrentElement.data)[0].toLowerCase() === "monumento")
            // Remove previous complessos
            for (let i = 0; i < storeCurrentElement.data.Monumento.Complessi.length; i++) {
              const complesso = storeCurrentElement.data.Monumento.Complessi[i].ComplessoLight;
              for (let y = 0; y < provList.length; y++) {
                const sr = provList[y].SearchResult;
                if (sr.ID === complesso.ID && sr.Tipo.toLowerCase() === "complesso")
                  provList.splice(y, 1);
              }
            }
          else if (storeCurrentElement.data && Object.keys(storeCurrentElement.data)[0].toLowerCase() === "complesso") {
            for (let y = 0; y < provList.length; y++) {
              const sr = provList[y].SearchResult;
              if (sr.ID === storeCurrentElement.data.Complesso.ID && sr.Tipo.toLowerCase() === "complesso")
                provList.splice(y, 1);
            }
          }
          // Add new complessos
          if (currentElementKey.toLowerCase() === "monumento")
            for (let i = 0; i < result[currentElementKey].Complessi.length; i++) {
              const element = result[currentElementKey].Complessi[i].ComplessoLight;
              if (element.GAP !== null)
                provList.push({
                  SearchResult: {
                    ID: element.ID,
                    OGTN: element.OGTN,
                    Coordinate: element.GAP,
                    Tipo: "Complesso"
                  }
                });
            }
          
          // Store current element
          dispatch({
            type: "CHANGE_CURRENT_ELEMENT",
            payload: {
              data: result,
              isLoaded: true,
              messageError: null
            }
          });
          // Update searched elements with current element
          if (!searchedElements.isLoaded || searchedElements.data.findIndex(x => (x.SearchResult.ID === result[currentElementKey].ID && currentElementKey === x.SearchResult.Tipo)) === -1) {
            if (coord) {
              provList.push({
                SearchResult: {
                  ID: result[currentElementKey].ID,
                  OGTN: result[currentElementKey].OGTN,
                  Coordinate: coord,
                  Tipo: currentElementKey
                }
              });
              dispatch({
                type: "CHANGE_SEARCHED_ELEMENTS",
                payload: {
                  data: provList,
                  isLoaded: true,
                  messageError: null
                }
              });
            } 
          }
        } else if (result) {
          dispatch({
            type: "CHANGE_CURRENT_ELEMENT",
            payload: {
              data: null,
              isLoaded: false,
              messageError: result.message
            }
          });
        }
      },
      (error) => {
        console.log(error);
      });
  }

  /**
   * This handler is used on mobile to close the infoBar and to have the map in full screen
   */
  function handlerMobileSidebarCross() {
    setBlur("");
    setMobileSidebarExpand("");
    setMobileSidebarHeight("h-0");
    setMobileSidebarReduce("hidden");
  }

  /**
   * This handler is used on mobile to expand the infoBar to full screen
   */
  function handlerMobileSidebarExpand() {
    setMobileSidebarExpand("hidden");
    setMobileSidebarHeight("h-90v"); // sm:h-85v md:h-90v
    setMobileSidebarReduce("");
  }

  /**
   * This handler is used on mobile to bring the infoBar back to its initial state
   */
  function handlerMobileSidebarReduce() {
    setMobileSidebarExpand("");
    setMobileSidebarHeight("h-60v");
    setMobileSidebarReduce("hidden");
  }

  /**
   * This handler is used on mobile to open the infoBar
   */
  function handlerMobileOpenSidebar() {
    setBlur("backdrop-blur");
    setMobileSidebarHeight("h-90v"); // sm:h-85v md:h-90v
  }

  /**
   * This handler opens and closes the mobile sidebar
   * @param {*} event The onClick event
   */
  function handleMobileHomeSidebar(event) {
    event.preventDefault();
    setMobileSidebarIsOpen(!mobileSidebarIsOpen);
  }
   
}


/**
 * This function detects if the current device is a mobile one.
 * @returns A boolean value.
 */
function detectMobile() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  // Old check for other devices
  || (/Android|webOS|BlackBerry/i.test(navigator.userAgent))
}


// ***************************************** MAP HOOKS ***************************************** //

/**
 * This hook changes the center of the map when the user selects a new marker.
 * The center depends by the sidebar status.
 * @param {*} param0 A list containing the new center and the current sidebar height
 */
function ChangeView({ center, sidebarHeight, currentElement, oldElement }) {
  console.log("ChangeView" + center)
  const map = useMap();
  let newlat = center[0];

  // If the sidebar is partially opened, perfrom a translation in order to set the
  // center on the map's visible portion.
  let mapBounds = map.getBounds();
  let visibleLat = mapBounds._northEast.lat - mapBounds._southWest.lat;
  let translationCoefficient = visibleLat/20 * 5; // Coefficient computed basing on map bounds

  if (sidebarHeight === "h-60v")
    newlat = newlat - translationCoefficient;
  else if (sidebarHeight === "h-90v") // sm:h-85v md:h-90v
    newlat = newlat - translationCoefficient;
  else if (sidebarHeight === "h-0")
    newlat = newlat + translationCoefficient/5;

  if (!detectMobile()) {
    newlat = newlat + translationCoefficient;
  }

  // Change map zoom if necessary
  let zoom = map.getZoom();
  if (oldElement.data && currentElement.data)
    if ((Object.keys(oldElement.data)[0] === "Sito" && Object.keys(currentElement.data)[0] === "Monumento") || (Object.keys(oldElement.data)[0] === "Sito" && Object.keys(currentElement.data)[0] === "Complesso"))
      map.setView([newlat, center[1]], 17, { animate: true, duration: 0.3 }); // From Sito to Monumento or Complesso, change the zoom to 17
    else if (Object.keys(currentElement.data)[0] === "Sito") { // Going to Sito, set the map to fit the Sito's bounds
      let coord = processPOLY(currentElement.data.Sito.GAP);
      const bounds = latLngBounds(coord[0]);
      coord.forEach((data) => {
        bounds.extend(data);
      })
      map.fitBounds(bounds);
      console.log("Going to Sito")
    } else // In every other case, just change center
      map.setView([newlat, center[1]], zoom, { animate: true, duration: 0.3 });
  else // Required when entering in explore page
    map.setView([newlat, center[1]], 17, { animate: true, duration: 0.3 });
  return null;
}


export default Explore;