// npm
import React, { useCallback } from 'react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * This component renders the Suggestions in the homepage
 * @param {*} props React props, namely:
 * - img: the link of the suggestion image;
 * - title: the name of the element related to the suggestion;
 * - id: an ID for the suggestion;
 * - className: additional className specifications for the highest container;
 * - transform: the transformation to personalise the CSS animations.
 * @returns The React component
 */
const Example = (props) => {
  // React Router
  const history = useHistory();
  const routeToMap = useCallback((id, type) => history.push('/explore/map/'+type+'/'+id), [history]);
  // Redux
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const lang = store.lang;

  return (
    <div className={"flex flex-col items-center my-4 w-full " + props.className + " " + props.transform}>
      <div className="flex flex-col items-center cursor-pointer lg:transform lg:hover:scale-105 lg:duration-300 lg:ease-in-out" onClick={selectExample}>
        <img className="rounded-full object-cover w-20 h-20 md:w-32 md:h-32 2xl:w-48 2xl:h-48" src={process.env.PUBLIC_URL + props.img + ".jpg"} alt={"Esempio " + props.id} />
        <p className="font-cinzel text-sm xl:text-xl pt-2 xl:pt-4 px-1 ">
          {parse(props.title)}
        </p>
      </div>
    </div>
  )

  /**
   * This function redirect the user to the map page displaying the selected element.
   */
  function selectExample() {
    const id = props.id;
    const elementType = 'monumento'; // Examples can be only monuments!

    // Store current element
    fetch(window.SERVER_MAIN_PATH + "/search/id?type=" + elementType + "&id=" + id + "&language=" + lang, { // Maybe it is better having a smarter query
      method: 'GET',
    })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, result]) => {
      if (statusCode === 200) {
        dispatch({
          type: "CHANGE_SEARCHED_ELEMENTS",
          payload: {data: [result] /* We need an array */, isLoaded: true, messageError: null}
        });
        // Set current element to null: it is not a specific search so the current element
        // will be selected from the searchedElements in map.js
        dispatch({
          type: "CHANGE_CURRENT_ELEMENT",
          payload: {data: null, isLoaded: false, messageError: null}
        });
        routeToMap(id, elementType);
      } else if (statusCode !== 200 && result) {
        dispatch({
          type: "CHANGE_SEARCHED_ELEMENTS",
          payload: {data: null, isLoaded: false, messageError: result.message}
        });
      }
    },
    (error) => {
      console.log(error);
    });
  }
}

export default Example;
