// npm
import React, { useState } from "react";

// Custom components
import Example from "./example.js";


/**
 * This component renders the carousel in the home page.
 * In the desktop version it presents 3 elements at once, while in the mobile version it shows only one element.
 * @param {*} props React props, namely 'data', the Suggestions returned by the server
 * @returns The React component
 */
const Carousel = (props) => {

	// React state
	const [ currentSet, setCurrentSet ] = useState(0);
	// The width of the window, useful to determine whether the display is medium or large
	// by rules defined in TailwindCSS (discrimination: 1024 pixels)
	var width = window.screen.width;
	
	/**
	 * This function changes the visualised suggestion
	 * @param {int} direction The new position in the array, relative to the current suggestion
	 */
	const changeSet = (direction) => {
		const nextSet = currentSet + direction;
		if (width < 1024)
			if (nextSet > props.data.length-1)
				setCurrentSet(0);
			else if (nextSet < 0)
				setCurrentSet(props.data.length-1);
			else
				setCurrentSet(nextSet);
		else
			if (nextSet > props.data.length/3-1)
				setCurrentSet(0);
			else if (nextSet < 0)
				setCurrentSet(props.data.length/3-1);
			else
				setCurrentSet(nextSet);
	}

	return (
		<div className="flex flex-row w-full h-full items-center">
			{/* Previous button */}
			<div className="flex items-center justify-center lg:opacity-20 lg:hover:opacity-100 w-1/10 sm:w-1/5 lg:w-1/10 lg:ml-8 py-12 cursor-pointer lg:transform lg:hover:scale-125 lg:duration-300" onClick={() => changeSet(-1)}>
				<svg className={"h-8 stroke-dark dark:stroke-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
				</svg>
			</div>
			{/* The shown elements */}
			<div className="relative w-full overflow-hidden flex justify-center">{
				width < 1024 ? <>
					<Example img={props.data[0].Suggestion.Link} title={props.data[0].Suggestion.OGTN} id={props.data[0].Suggestion.ID} className="" transform={"transform ease-in-out duration-700 " + (currentSet === 0 ? "" : (currentSet === 8 ? "translate-x-full opacity-0" : (currentSet === 1 ? "-translate-x-full opacity-0" : "opacity-0")))} />
					<Example img={props.data[1].Suggestion.Link} title={props.data[1].Suggestion.OGTN} id={props.data[1].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 1 ? "" : (currentSet === 0 ? "translate-x-full opacity-0" : (currentSet === 2 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[2].Suggestion.Link} title={props.data[2].Suggestion.OGTN} id={props.data[2].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 2 ? "" : (currentSet === 1 ? "translate-x-full opacity-0" : (currentSet === 3 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[3].Suggestion.Link} title={props.data[3].Suggestion.OGTN} id={props.data[3].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 3 ? "" : (currentSet === 2 ? "translate-x-full opacity-0" : (currentSet === 4 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[4].Suggestion.Link} title={props.data[4].Suggestion.OGTN} id={props.data[4].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 4 ? "" : (currentSet === 3 ? "translate-x-full opacity-0" : (currentSet === 5 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[5].Suggestion.Link} title={props.data[5].Suggestion.OGTN} id={props.data[5].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 5 ? "" : (currentSet === 4 ? "translate-x-full opacity-0" : (currentSet === 6 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[6].Suggestion.Link} title={props.data[6].Suggestion.OGTN} id={props.data[6].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 6 ? "" : (currentSet === 5 ? "translate-x-full opacity-0" : (currentSet === 7 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[7].Suggestion.Link} title={props.data[7].Suggestion.OGTN} id={props.data[7].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 7 ? "" : (currentSet === 6 ? "translate-x-full opacity-0" : (currentSet === 8 ? "-translate-x-full opacity-0" : "hidden")))} />
					<Example img={props.data[8].Suggestion.Link} title={props.data[8].Suggestion.OGTN} id={props.data[8].Suggestion.ID} className="absolute left-0 top-0" transform={"transform ease-in-out duration-700 " + (currentSet === 8 ? "" : (currentSet === 7 ? "translate-x-full opacity-0" : (currentSet === 0 ? "-translate-x-full opacity-0" : "hidden")))} />
				</> : <>
					<div className={"flex flex-row w-full transform ease-in-out duration-700 " + (currentSet === 0 ? "" : (currentSet === 1 ? "-translate-x-full opacity-0" : (currentSet === 2 ? "translate-x-full opacity-0" : "hidden")))}>
						<Example img={props.data[0].Suggestion.Link} title={props.data[0].Suggestion.OGTN} id={props.data[0].Suggestion.ID} className="h-full" transform={""} />
						<Example img={props.data[1].Suggestion.Link} title={props.data[1].Suggestion.OGTN} id={props.data[1].Suggestion.ID} className="h-full" transform={""} />
						<Example img={props.data[2].Suggestion.Link} title={props.data[2].Suggestion.OGTN} id={props.data[2].Suggestion.ID} className="h-full" transform={""} />
					</div>
					<div className={"absolute left-0 top-0 flex flex-row w-full transform ease-in-out duration-700 " + (currentSet === 1 ? "" : (currentSet === 2 ? "-translate-x-full opacity-0" : (currentSet === 0 ? "translate-x-full opacity-0" : "hidden")))}>
						<Example img={props.data[3].Suggestion.Link} title={props.data[3].Suggestion.OGTN} id={props.data[3].Suggestion.ID} className="h-full" transform={""} />
						<Example img={props.data[4].Suggestion.Link} title={props.data[4].Suggestion.OGTN} id={props.data[4].Suggestion.ID} className="h-full" transform={""} />
						<Example img={props.data[5].Suggestion.Link} title={props.data[5].Suggestion.OGTN} id={props.data[5].Suggestion.ID} className="h-full" transform={""} />
					</div>
					<div className={"absolute left-0 top-0 flex flex-row w-full transform ease-in-out duration-700 " + (currentSet === 2 ? "" : (currentSet === 0 ? "-translate-x-full opacity-0" : (currentSet === 1 ? "translate-x-full opacity-0" : "hidden")))}>
						<Example img={props.data[6].Suggestion.Link} title={props.data[6].Suggestion.OGTN} id={props.data[6].Suggestion.ID} className="h-full" transform={""} />
						<Example img={props.data[7].Suggestion.Link} title={props.data[7].Suggestion.OGTN} id={props.data[7].Suggestion.ID} className="h-full" transform={""} />
						<Example img={props.data[8].Suggestion.Link} title={props.data[8].Suggestion.OGTN} id={props.data[8].Suggestion.ID} className="h-full" transform={""} />
					</div>
				</>
			}</div>
			{/* Next button */}
			<div className="flex items-center justify-center align-center lg:opacity-20 lg:hover:opacity-100 w-1/10 sm:w-1/5 lg:w-1/10 lg:mr-8 py-12 cursor-pointer lg:transform lg:hover:scale-125 lg:duration-300" onClick={() => changeSet(1)}>
				<svg className={"h-8 stroke-dark dark:stroke-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
				</svg>
			</div>
		</div>
	)
}

export default Carousel;