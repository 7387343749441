// npm
import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

// Custom functions
import { getElementList, getElement } from './utils';

// Stylesheet
import './dataInsert.css';


/**
 * This component renders the form to insert into the database a new archeological element
 * @param {*} props React props, namely:
 * - insertCount: the counter of the new insertions during the current session;
 * - setInsertCount: the function to update insertCount.
 * @returns The React component
 */
const ElementInsert = (props) => {
  
  // Redux
  const store = useSelector(state => state);
  const lang = store.lang;

  // React state
  const [typeState, setTypeState] = useState("sito");
  const [fatherList, setFatherList] = useState([]);
  const [epochList, setEpochList] = useState([]);
  const [selectedPredecessore, setSelectedPredecessore] = useState(null);
  const [imageList, setImageList] = useState([{ID: -1, Link: null, Autore: null, Descrizione: null, isProfile: true}]);
  const [fonteBibliograficaList, setFonteBibliograficaList] = useState([-1]);
  const [elementID, setElementID] = useState(null);
  const [elementCurrentState, setElementCurrentState] = useState(null);
  const [elementCurrentPredecessore, setElementCurrentPredecessore] = useState(null);
  const [elementGAP, setElementGAP] = useState(null);
  const [isVisibile, setIsVisibile] = useState(null);
  const [isAccessibile, setIsAccessibile] = useState(null);
  // States for select list
  const [predecessorSelectList, setPredecessorSelectList] = useState(null);
  const [fatherSelectList, setFatherSelectList] = useState(null);
  const [epochSelectList, setEpochSelectList] = useState(null);
  const [fonteBibSelectList, setFonteBibSelectList] = useState(null);
  const [elementsSelectorList, setElementsSelectorList] = useState(null);

  /**
   * This effect is called when a new insert has been submitted, to update the displayed information.
   */
  useEffect(() => {
    async function fetchData() {
      setPredecessorSelectList(await getElementList('candidatopredecessore', lang));
      setEpochSelectList(await getElementList('epoca', lang));
      setFonteBibSelectList(await getElementList('fontebibliografica', lang));
    }
    fetchData();
  }, [lang, props.insertCount]);

  /**
   * This effect is called when the user changes the element typology.
   */
  useEffect(() => {
    async function fetchData() {
      if (typeState === 'complesso')
        setFatherSelectList(await getElementList('sito', lang));
      else if (typeState === 'monumento')
        setFatherSelectList(await getElementList('complesso', lang));
      setElementsSelectorList(await getElementList(typeState, lang));
    }
    fetchData();
  }, [typeState, lang, props.insertCount]);

  /**
   * This effect is called to update the visualised GAP.
   */
  useEffect(() => {
    if (elementCurrentState && elementCurrentState.GAP) {
      let gap = elementCurrentState.GAP.substring(9, elementCurrentState.GAP.length-2);
      let finalGAP = gap;
      let commas = 0;
      for (var i = 0; i < gap.length; i++) {
        if (gap[i] === ",") {
          finalGAP = finalGAP.slice(0, i+2*commas) + "],[" + gap.slice(i+1);
          commas++;
        }
        if (gap[i] === " ")
          finalGAP = finalGAP.slice(0, i+2*commas) + "," + gap.slice(i+1);
      }

      finalGAP = finalGAP.replace('(', '[');
      finalGAP = finalGAP.replace(')', ']');
      setElementGAP("[[[" + finalGAP + "]]]");
      
      
      //console.log("GAP: " + gap);
      //console.log("finalGAP: " + finalGAP)
    }
  }, [elementCurrentState]);

  return (
    <div className="pb-20">
      {/** Form */}
      <form className="flex flex-col mx-4" id="NewElementForm">
        {/** General info */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-8 my-4">
          
          <div className="flex justify-end">
            <select name="type" value={typeState} onChange={typeSelectorHandler} className="w-36 bg-white text-dark">
              <option value="sito">Sito</option>
              <option value="complesso">Complesso</option>
              <option value="monumento">Monumento</option>
            </select>
          </div>

          {/**Element ID*/}
          <div className="flex justify-end items-center">
            <label className="pr-2">Elemento: </label>
            <select className="w-60 bg-white text-dark" value={(elementID ? String(elementID) : "-1") || "-1"} name="elementid" onChange={handlerElementIDInput} onClick={handlerElementIDInput}>
              <option value="-1">Nuovo elemento</option>
              {elementsSelectorList ?
                elementsSelectorList.List.map((element, index) => {
                  return (
                    <option key={index} value={element[Object.keys(element)[0]].ID}>
                      {element[Object.keys(element)[0]].OGTN ? element[Object.keys(element)[0]].OGTN : element[Object.keys(element)[0]].ID}
                    </option>
                  )
                })
              : null}
            </select>
          </div>

          <div className="flex justify-end">
            <label className="pr-2">ID ICCD: </label>
            <input className="text-dark px-1" type="text" id="iccd" name="iccd" defaultValue={(elementCurrentState ? elementCurrentState.ICCD : "") || ""} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">GPDPX: 
              <span className="tooltiptext">Coordinata X.</span>
            </label>
            <input className="text-dark px-1" type="text" id="gpdpx" name="gpdpx" defaultValue={(elementCurrentState ? elementCurrentState.GPDPX : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">GPDPY: 
              <span className="tooltiptext">Coordinata Y.</span>
            </label>
            <input className="text-dark px-1" type="text" id="gpdpy" name="gpdpy" defaultValue={(elementCurrentState ? elementCurrentState.GPDPY : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">GPCL: 
              <span className="tooltiptext">Quota s.l.m..</span>
            </label>
            <input className="text-dark px-1" type="text" id="gpcl" name="gpcl" defaultValue={(elementCurrentState ? elementCurrentState.GPCL : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">GAP: 
              <span className="tooltiptext">Proiezione e Sistema di riferimento.</span>
            </label>
            <input className="text-dark px-1" type="text" id="gap" name="gap" defaultValue={(elementCurrentState && elementCurrentState.GAP ? elementGAP : "") || ""} placeholder="long1 lat1, long2 lat2, ..." disabled={(typeState === "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">GPDPXA: 
              <span className="tooltiptext">Coordinata X di accesso.</span>
            </label>
            <input className="text-dark px-1" type="text" id="gpdpxa" name="gpdpxa" defaultValue={(elementCurrentState ? elementCurrentState.GPDPXA : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end"> 
            <label className="pr-2 tooltip">GPDPYA: 
              <span className="tooltiptext">Coordinata Y di accesso.</span>
            </label>
            <input className="text-dark px-1" type="text" id="gpdpya" name="gpdpya" defaultValue={(elementCurrentState ? elementCurrentState.GPDPYA : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">GPCLA: 
              <span className="tooltiptext">Quota s.l.m. di accesso.</span>
            </label>
            <input className="text-dark px-1" type="text" id="gpcla" name="gpcla" defaultValue={(elementCurrentState ? elementCurrentState.GPCLA : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">PVCR<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Regione.</span>
            </label>
            <input className="text-dark px-1" type="text" id="pvcr" name="pvcr" placeholder="05 - Veneto" disabled />
          </div>

          <div className="flex justify-end"> 
            <label className="pr-2 tooltip">PVCP<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Provincia.</span>
            </label>
            <input className="text-dark px-1" type="text" id="pvcp" name="pvcp" defaultValue={(elementCurrentState ? elementCurrentState.PVCP : "") || ""} placeholder="Sigla della provincia" />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">PVCC<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Comune.</span>
            </label>
            <input className="text-dark px-1" type="text" id="pvcc" name="pvcc" defaultValue={(elementCurrentState ? elementCurrentState.PVCC : "") || ""} />
          </div>
          
          <div className="flex justify-end">
            <label className="pr-2 tooltip">PVCL: 
              <span className="tooltiptext">Località.</span>
            </label>
            <input className="text-dark px-1" type="text" id="pvcl" name="pvcl" defaultValue={(elementCurrentState ? elementCurrentState.PVCL : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">PVCI: 
              <span className="tooltiptext">Indirizzo.</span>
            </label>
            <input className="text-dark px-1" type="text" id="pvci" name="pvci" defaultValue={(elementCurrentState ? elementCurrentState.PVCI : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2">Sito Web: </label>
            <input className="text-dark px-1" type="text" id="sitoweb" name="sitoweb" defaultValue={(elementCurrentState ? elementCurrentState.SitoWeb : "") || ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2">Visibile<span className="text-orange font-bold font-cinzel">*</span>: </label>
            <select name="visibile" value={((isVisibile !== null) ? String(isVisibile) : "null") || "null"} disabled={(typeState === "sito") ? "disabled" : null} className="w-36 bg-white text-dark" onChange={handlerVisibileInput} onClick={handlerVisibileInput}>
              <option value="true">Sì</option>
              <option value="false">No</option>
              <option value="null">Sconosciuto</option>
            </select>
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2">Accessibile<span className="text-orange font-bold font-cinzel">*</span>: </label>
            <select name="accessibile" value={((isAccessibile !== null) ? String(isAccessibile) : "null") || "null"} disabled={(typeState === "sito") ? "disabled" : null} className="w-36 bg-white text-dark" onChange={handlerAccessibileInput} onClick={handlerAccessibileInput}>
              <option value="true">Sì</option>
              <option value="false">No</option>
              <option value="null">Sconosciuto</option>
            </select>
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSII<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Anno di inizio, limite inferiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsii_elem" name="dtsii" defaultValue={(elementCurrentState ? elementCurrentState.DTSII : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSIS<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Anno di inizio, limite superiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsis_elem" name="dtsis" defaultValue={(elementCurrentState ? elementCurrentState.DTSIS : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSFI<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Anno di fine, limite inferiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsfi_elem" name="dtsfi" defaultValue={(elementCurrentState ? elementCurrentState.DTSFI : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">DTSFS<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Anno di fine, limite superiore.</span>
            </label>
            <input className="text-dark px-1" type="text" id="dtsfs_elem" name="dtsfs" defaultValue={(elementCurrentState ? elementCurrentState.DTSFS : "") || ""} disabled={(typeState === "sito") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">ORT: 
              <span className="tooltiptext">Orientamento.</span>
            </label>
            <input className="text-dark px-1" type="text" id="ort" name="ort" defaultValue={(elementCurrentState ? elementCurrentState.ORT : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">MISA: 
              <span className="tooltiptext">Altezza.</span>
            </label>
            <input placeholder="(Metri)" className="text-dark px-1" type="text" id="misa" name="misa" defaultValue={(elementCurrentState ? elementCurrentState.MISA : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">MISL: 
              <span className="tooltiptext">Larghezza.</span>
            </label>
            <input placeholder="(Metri)" className="text-dark px-1" type="text" id="misl" name="misl" defaultValue={(elementCurrentState ? elementCurrentState.MISL : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end">
            <label className="pr-2 tooltip">MISN: 
              <span className="tooltiptext">Lunghezza.</span>
            </label>
            <input placeholder="(Metri)" className="text-dark px-1" type="text" id="misn" name="misn" defaultValue={(elementCurrentState ? elementCurrentState.MISN : "") || ""} disabled={(typeState !== "monumento") ? "disabled" : null} />
          </div>

          <div className="flex justify-end items-center col-span-2">
            <label className="pr-2">Predecessore: </label>
            <select id="predecessore" name="predecessore" value={(selectedPredecessore ? String(selectedPredecessore) : "-1") || "-1"} disabled={(typeState !== "monumento") ? "disabled" : null} className="w-80 bg-white text-dark" onChange={handlerPredecessorInput} onClick={handlerPredecessorInput}>
              <option value="-1">Not specified</option>
              {elementCurrentState && elementCurrentPredecessore ?
                <option value={elementCurrentPredecessore.ID}>{"Attuale: " + elementCurrentPredecessore.OGTN}</option>
              : null}
              {predecessorSelectList ? 
                (predecessorSelectList.List.map((element, index) => {
                  return (
                    <option key={index} value={element[Object.keys(element)[0]].ID}>
                      {element[Object.keys(element)[0]].OGTN ? element[Object.keys(element)[0]].OGTN : "Monumento ID: " + element[Object.keys(element)[0]].ID}
                    </option>
                  )
                }))
              : null} 
            </select>
          </div>

        </div>

        {/* Set relations between elements */}
        <div className="mx-80 mt-6">
          <div className="marginLine border-white">
            <span>Appartiene a {typeState === 'monumento' ? 'complesso' : typeState === 'complesso' ? 'sito' : '...'}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-8 my-4">
          {fatherList.map((element, index) => {
              return (
                <div key={index} className="flex justify-end items-center">
                  <label className="pr-2">{typeState === 'monumento' ? 'Complesso' : typeState === 'complesso' ? 'Sito' : 'Disabled'}<span className="text-orange font-bold font-cinzel">*</span>: </label>
                  <select id={"father-"+index} name={"father-"+index} value={String(fatherList[index])} disabled={(typeState === "sito") ? "disabled" : null} className="w-80 bg-white text-dark" onChange={(event) => handlerFatherInput(event, index)}>
                    <option value="null"> -- select an option -- </option>
                    {fatherSelectList ?
                      fatherSelectList.List.map((opt, optIndex) => {
                        return (
                          (fatherList.indexOf(opt[Object.keys(opt)[0]].ID) !== index) && (fatherList.indexOf(opt[Object.keys(opt)[0]].ID) !== -1) ?
                            null
                          :
                            <option key={optIndex} value={opt[Object.keys(opt)[0]].ID}>
                              {opt[Object.keys(opt)[0]].OGTN ? opt[Object.keys(opt)[0]].OGTN : opt[Object.keys(opt)[0]].ID}
                            </option>
                        )
                      })
                    : null}
                  </select>
                  {typeState === 'monumento' && fatherList.length > 1 ?
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-5 mx-1 cursor-pointer" onClick={() => removeFatherFieldHandler(index)}>
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                  : null}
                </div>
              )
            })
          }
          {typeState === 'monumento' ?
            <div className="flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={addFatherFieldHandler}>
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
              </svg>
            </div>
          : null}
        </div>

        {/* Add epoch */}
        <div className="mx-80 mt-6">
          <div className="marginLine border-white">
            <span>Epoca</span>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-8 my-4">
          {epochList.map((element, index) => { 
            return (
              <div key={index} className="flex justify-end items-center">
                <label className="pr-2">Epoca<span className="text-orange font-bold font-cinzel">*</span>: </label>
                <select id={"epoch-"+index} name={"epoch-"+index} value={String(epochList[index])} disabled={(typeState === "sito") ? "disabled" : null} className="w-48 bg-white text-dark" onChange={(event) => handlerEpochInput(event, index)}>
                  <option value="null"> -- select an option -- </option>
                  {epochSelectList ?
                    epochSelectList.List.map((opt, optIndex) => {
                      return (
                        (epochList.indexOf(opt[Object.keys(opt)[0]].ID) !== index && epochList.indexOf(opt[Object.keys(opt)[0]].ID) !== -1) ?
                          null
                        :
                          <option key={optIndex} value={opt[Object.keys(opt)[0]].ID}>
                            {opt[Object.keys(opt)[0]].DTZG}
                          </option>
                      )
                    })
                  : null}
                </select>
                {typeState !== 'sito' && epochList.length > 1 ?
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-5 mx-1 cursor-pointer" onClick={() => removeEpochFieldHandler(index)}>
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                  </svg>
                : null}
              </div>
            )
          })}
          {typeState !== 'sito' ?
            <div className="flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={addEpochFieldHandler}>
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
              </svg>
            </div>
          : null}
        </div>

        {/* Add image */}
        <div className="mx-80 mt-6">
          <div className="marginLine border-white">
            <span>Immagini</span>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-8 my-4">
          <div className="flex flex-col">
            <div className="flex justify-end items-center my-2">
              <label className="pr-2">Immagine Profilo (nome file)<span className="text-orange font-bold font-cinzel">*</span>: </label>
              <input className="text-dark px-1 w-44" value={imageList[0].Link ? imageList[0].Link : ""} type="text" name="img-link-0" onChange={(event) => handlerImgLinkInput(event, 0)} />
            </div>
            <div className="flex justify-end items-center my-2">
              <label className="pr-2">Autore: </label>
              <input className="text-dark px-1 w-44" value={imageList[0].Autore ? imageList[0].Autore : ""} type="text" name="img-aut-0" onChange={(event) => handlerImgAutInput(event, 0)} />
            </div>
            <div className="flex justify-end items-center my-2">
              <label className="pr-2">Descrizione: </label>
              <input className="text-dark px-1 w-44" value={imageList[0].Descrizione ? imageList[0].Descrizione : ""} type="text" name="img-des-0" onChange={(event) => handlerImgDesInput(event, 0)} />
            </div>
          </div>
          {imageList.slice(1).map((element, index) => { 
            return (
              <div key={index+1} className="flex flex-col">
                <div className="flex justify-end items-center my-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-5 mx-1 cursor-poiter" onClick={() => removeImageFieldHandler(index+1)}>
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                  </svg>
                  <label className="pr-2">Immagine (nome file): </label>
                  <input value={element.Link ? element.Link : ""} className="text-dark px-1 w-44" type="text" name={"img-link-" + (index+1)} onChange={(event) => handlerImgLinkInput(event, index+1)} />
                </div>
                <div className="flex justify-end items-center my-2">
                  <label className="pr-2">Autore: </label>
                  <input value={element.Autore ? element.Autore : ""} className="text-dark px-1 w-44" type="text" name={"img-aut-" + (index+1)} onChange={(event) => handlerImgAutInput(event, index+1)} />
                </div>
                <div className="flex justify-end items-center my-2">
                  <label className="pr-2">Descrizione: </label>
                  <input value={element.Descrizione ? element.Descrizione : ""} className="text-dark px-1 w-44" type="text" name={"img-des-" + (index+1)} onChange={(event) => handlerImgDesInput(event, index+1)} />
                </div>
              </div>
            )
          })}
          <div className="flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={addImageFieldHandler}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
            </svg>
          </div>
        </div>

        {/* Add fonte bibliografica */}
        <div className="mx-80 mt-6">
          <div className="marginLine border-white">
            <span>Fonte Bibliografica</span>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mx-8 my-4">
          {fonteBibliograficaList.map((element, index) => { 
            return (
              <div key={index} className="flex justify-end items-center">
                <label className="pr-2">Fonte Bibliografica: </label>
                <select id={"fontebibliografica-"+index} value={String(element)} name={"fontebibliografica-"+index} className="w-60 bg-white text-dark" onChange={(event) => handlerFBInput(event, index)}>
                  <option value="null"> -- select an option -- </option>
                  {fonteBibSelectList ?
                    fonteBibSelectList.List.map((opt, optIndex) => {
                      return (
                        (fonteBibliograficaList.indexOf(opt[Object.keys(opt)[0]].BIBH) !== index) && (fonteBibliograficaList.indexOf(opt[Object.keys(opt)[0]].BIBH) !== -1) ?
                          null
                        :
                          <option key={optIndex} value={opt[Object.keys(opt)[0]].BIBH}>
                            {opt[Object.keys(opt)[0]].BIL}
                          </option>
                      )
                    })
                  : null}
                </select>          
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-5 mx-1 cursor-pointer" onClick={() => removeFBFieldHandler(index)}>
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                </svg>
              </div>
            )
          })}
          <div className="flex justify-center items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" className="w-10 cursor-pointer" onClick={addFBFieldHandler}>
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
            </svg>
          </div>
        </div>
        
        {/* Submit */}
        <div className="flex justify-end mx-8">
          <button className="w-60 text-dark rounded-full mt-10 bg-white" onClick={handlerSubmitForm}>{"Crea nuovo " + typeState}</button>
        </div>
      </form>
    </div>
  )


  // ****************************************** HANDLERS ******************************************

  /**
   * This function handles the change in the typology
   * @param {*} event The onChange in the type selector
   */
  async function typeSelectorHandler(event) {
    setTypeState(event.target.value);
    setFatherList([-1]);
    setEpochList([-1]);
    setFonteBibliograficaList([-1]);
    setImageList([{ID: -1, Link: null, Autore: null, Descrizione: null, isProfile: true}]);
    setElementCurrentState(null);
    setElementID(null);
    setElementCurrentPredecessore(null);
    setIsVisibile(null);
    setIsAccessibile(null);
    document.forms.NewElementForm.reset();
  }

  /**
   * This function handles the change in the ID input field.
   * @param {*} event The onClick or onChange event in the input field.
   */
  async function handlerElementIDInput(event) {
    const currentID = event.target.value;
    document.forms.NewElementForm.reset(); // Reset form

    if (currentID !== "-1" && currentID !== null) {
      // Get info
      setElementID(currentID);
      let element = await getElement(currentID, typeState, lang);
      setElementCurrentState(element[Object.keys(element)[0]]);
      // Set Predecessor
      if (typeState === 'monumento' && element[Object.keys(element)[0]].Predecessore) {
        let predecessore = await getElement(element[Object.keys(element)[0]].Predecessore, 'monumento', lang);
        setElementCurrentPredecessore(predecessore.Monumento);
        setSelectedPredecessore(predecessore.Monumento.ID);
      } else
        setElementCurrentPredecessore(null);

      // Set Visibile
      if (typeState !== "sito")
        setIsVisibile(element[Object.keys(element)[0]].Visibile);

      // Set Accessibile
      if (typeState !== "sito")
        setIsAccessibile(element[Object.keys(element)[0]].Accessibile);

     
      if (typeState !== 'sito') {
        // Set father list
        if (typeState === 'monumento' && element.Monumento.Complessi.length > 0) {
          let provList = [];
          for (let i = 0; i < element.Monumento.Complessi.length; i++)
            provList.push(element.Monumento.Complessi[i].ComplessoLight.ID);
          setFatherList(provList);
        } else if (typeState === 'complesso') {
          let provList = [];
          provList.push(element.Complesso.SitoLight.ID);
          setFatherList(provList);
        }

        // Set epoch list
        let provList = [];
        for (let i = 0; i < element[Object.keys(element)[0]].Epoche.length; i++)
          provList.push(element[Object.keys(element)[0]].Epoche[i].Epoca.ID);
        setEpochList(provList);
      }

      // Set images
      let provImgList = [];
      for (let i = 0; i < element[Object.keys(element)[0]].Immagini.length; i++) {
        const img = element[Object.keys(element)[0]].Immagini[i].ImmagineLight;
        const obj = {ID: img.ID, Link: img.Link.substring(13), Autore: img.Autore, Descrizione: img.Descrizione, isProfile: img.Profilo};
        if (obj.isProfile)
          provImgList = [obj].concat(provImgList);
        else
          provImgList.push(obj);
      }
      setImageList(provImgList);

      // Set bib
      let provBibList = [];
      for (let i = 0; i < element[Object.keys(element)[0]].FontiBibliografiche.length; i++) {
        const fb = element[Object.keys(element)[0]].FontiBibliografiche[i].FonteBibliografica;
        provBibList.push(fb.BIBH);
      }
      setFonteBibliograficaList(provBibList);
    } else {
      // Reset
      setFatherList([-1]);
      setEpochList([-1]);
      setFonteBibliograficaList([-1]);
      setImageList([{ID:-1, Link: null, Autore: null, Descrizione: null, isProfile: true}]);
      setElementID(null);
      setElementCurrentState(null);
      setElementCurrentPredecessore(null);
      setSelectedPredecessore(null);
      setIsVisibile(null);
      setIsAccessibile(null);
    }
  }

  /**
   * This handler updates the state when the Visibile field is changed
   * @param {*} event The onChange event of the type selector
   */
  function handlerVisibileInput(event) {
    setIsVisibile(event.target.value);
  }

  /**
   * This handler updates the state when the Accessibile field is changed
   * @param {*} event The onChange event of the type selector
   */
  function handlerAccessibileInput(event) {
    setIsAccessibile(event.target.value);
  }

  /**
   * This handler updates the state when the Predecessore field is changed
   * @param {*} event The onChange event of the type selector
   */
  function handlerPredecessorInput(event) {
    const currentID = parseFloat(event.target.value);
    if (currentID === -1)
      setSelectedPredecessore(null);
    else
      setSelectedPredecessore(currentID);
  }

  /**
   * This handler adds a new father to the current element.
   */
  function addFatherFieldHandler() {
    let provList = JSON.parse(JSON.stringify(fatherList));
    provList.push(-1)
    setFatherList(provList);
  }

  /**
   * This handler removes the selected father
   * @param {Int} index The position of the father to be removed.
   */
  function removeFatherFieldHandler(index) {
    if (fatherList.length > 1) {
      let provList = JSON.parse(JSON.stringify(fatherList));
      provList.splice(index, 1);
      setFatherList(provList);
    }
  }

  /**
   * This function handles the change in the selection of a new father
   * @param {*} event The onChange event in the type selction
   * @param {Int} index The position of the selected father in the selection
   */
  function handlerFatherInput(event, index) {
    const currentID = event.target.value;
    let provList = JSON.parse(JSON.stringify(fatherList));
    if (currentID)
      provList[index] = parseInt(currentID);
    else
      provList[index] = -1;
    setFatherList(provList);
  }

  /**
   * This handler adds a new epoch to the current element.
   */
  function addEpochFieldHandler(){
    let provList = JSON.parse(JSON.stringify(epochList));
    provList.push(-1)
    setEpochList(provList);
  }

  /**
   * This handler removes an epoch from the current element.
   * @param {Int} index The position of the epoch to be removed.
   */
  function removeEpochFieldHandler(index) {
    if (epochList.length > 1) {
      let provList = JSON.parse(JSON.stringify(epochList));
      provList.splice(index, 1);
      setEpochList(provList);
    }
  }

  /**
   * This function handles the change in the selection of a new epoch.
   * @param {*} event The onChange event in the type selction.
   * @param {*} index The position of the selected epoch in the selection.
   */
  function handlerEpochInput(event, index) {
    const currentID = event.target.value;
    let provList = JSON.parse(JSON.stringify(epochList));
    if (currentID)
      provList[index] = parseInt(currentID);
    else
      provList[index] = -1;
    setEpochList(provList);
  }


  /**
   * This handler adds a new image field to the current element.
   */
  function addImageFieldHandler(){
    let provList = JSON.parse(JSON.stringify(imageList));
    provList.push({ID: -1, Link: null, Autore: null, Descrizione: null, isProfile: false});
    setImageList(provList);
  }

  /**
   * This handler removes an image from the current element.
   */
  function removeImageFieldHandler(index) {
    let provList = JSON.parse(JSON.stringify(imageList));
    provList.splice(index, 1);
    setImageList(provList);
  }

  /**
   * This handler updates the list of images with a new link
   * @param {*} event the onChange event
   * @param {*} index the index of the changed image link
   */
  function handlerImgLinkInput(event, index) {
    const currentLink = event.target.value;
    let provList = JSON.parse(JSON.stringify(imageList));
    provList[index].Link = currentLink;
    setImageList(provList);
  }

  /**
   * This handler updates the list of images with a new author
   * @param {*} event the onChange event
   * @param {*} index the index of the changed image author
   */
  function handlerImgAutInput(event, index) {
    const currentAut = event.target.value;
    let provList = JSON.parse(JSON.stringify(imageList));
    provList[index].Autore = currentAut;
    setImageList(provList);
  }

  /**
   * This handler updates the list of images with a new description
   * @param {*} event the onChange event
   * @param {*} index the index of the changed image description
   */
  function handlerImgDesInput(event, index) {
    const currentDes = event.target.value;
    let provList = JSON.parse(JSON.stringify(imageList));
    provList[index].Descrizione = currentDes;
    setImageList(provList);
  }

  /**
   * This handler adds a new Fonte Bibliografica field to the current element.
   */
  function addFBFieldHandler(){
    let provList = JSON.parse(JSON.stringify(fonteBibliograficaList));
    provList.push(-1);
    setFonteBibliograficaList(provList);
  }

  /**
   * This handler removes a Fonte Bibliografica field from the current element.
   */
  function removeFBFieldHandler(index) {
    let provList = JSON.parse(JSON.stringify(fonteBibliograficaList));
    provList.splice(index, 1);
    setFonteBibliograficaList(provList);
  }

  /**
   * This handler updates the list of Fonte Bibliografica for the current element.
   * @param {*} event the onChange event
   * @param {*} index the changed Fonte Bibliografica
   */
  async function handlerFBInput(event, index) {
    const currentID = event.target.value;
    let provList = JSON.parse(JSON.stringify(fonteBibliograficaList));
    if (currentID)
      provList[index] = parseInt(currentID);
    else
      provList[index] = -1;
    setFonteBibliograficaList(provList);
  }

  /**
   * This handler submits the inserted data to the database server.
   * @param {*} event The onClick event that confirms the submission
   */
  function handlerSubmitForm(event) {
    event.preventDefault();

    // Get form
    var formEl = document.forms.NewElementForm;
    var formData = new FormData(formEl);

    // Get type
    let type = formData.get('type');
    let firstKey = type.charAt(0).toUpperCase() + type.slice(1);

    // Final element
    let finalJSON = {};
    finalJSON[firstKey] = {};

    if (elementCurrentState)
      finalJSON[firstKey].ID = elementCurrentState.ID;
    else
      finalJSON[firstKey].ID = -1;

    // ID ICCD
    var iccd = formData.get('iccd');
    if (iccd === "")
      iccd = null;
    finalJSON[firstKey].ICCD = iccd;

    // GPDPX
    var gpdpx = formData.get('gpdpx');
    if (isNaN(gpdpx)) {
      alert("GPDPX è un valore numerico.");
      return;
    }
    if (gpdpx === "") // If not disabled, it's mandatory
      // alert("GPDPX è un valore numerico obbligatorio.");
      // return;
      finalJSON[firstKey].GPDPX = null;
    if (gpdpx !== null && gpdpx !== "") // If not disabled, insert
      finalJSON[firstKey].GPDPX = parseFloat(gpdpx);

    // GPDPY
    var gpdpy = formData.get('gpdpy');
    if (isNaN(gpdpy)) {
      alert("GPDPY è un valore numerico.");
      return;
    }
    if (gpdpy === "") // If not disabled, it's mandatory
      // alert("GPDPY è un valore numerico obbligatorio.");
      // return;
      finalJSON[firstKey].GPDPY = null;
    if (gpdpy !== null && gpdpy !== "") // If not disabled, insert
      finalJSON[firstKey].GPDPY = parseFloat(gpdpy);
    
    // GPCL
    var gpcl = formData.get('gpcl');
    if (gpcl !== "" && isNaN(gpcl)) { 
      alert("GPCL deve essere un valore numerico.");
      return;
    }
    if (gpcl !== null) // If not disabled, insert
      if (gpcl === "")
        finalJSON[firstKey].GPCL = null;
      else
        finalJSON[firstKey].GPCL = parseFloat(gpcl);

    // GAP
    var gap = formData.get('gap');
    if (gap === "") // If not disabled, it's mandatory
      // alert("GAP è un valore obbligatorio.")
      // return;
      finalJSON[firstKey].GAP = null;
      
    if (gap !== null && gap !== ""){ // If not disabled, insert
      gap = gap.replace(/\s/g,''); // Remove white spaces
      gap = JSON.parse(gap);
      console.log(gap)
      
      let finalGAP = "(";
      for (let i = 0; i < gap.length; i++) {
        const poly = gap[i];
        
        if (poly[0][0] !== poly[poly.length - 1][0] || poly[0][1] !== poly[poly.length - 1][1]) {
          alert("Ogni poligono del GAP deve avere il primo ed ultimo punto identici.")
          return;
        }
        if (poly.length < 4) {
          alert("Ogni poligono del GAP deve avere almeno 4 punti.")
          return;
        }

        let finalGAPPoly = "(";
        for (let j = 0; j < poly.length; j++) {
          finalGAPPoly = finalGAPPoly + poly[j][0] + " " + poly[j][1] + ", ";
          if (j === poly.length-1) {
            finalGAPPoly = finalGAPPoly.substring(0, finalGAPPoly.length-2)
          }
        }
        finalGAPPoly = finalGAPPoly + ")"

        if (i !== 0) {
          finalGAP = finalGAP + ", " + finalGAPPoly;
        } else {
          finalGAP = finalGAP + finalGAPPoly;
        }

      }
      
      console.log(finalGAP)
      finalJSON[firstKey].GAP = "POLYGON" + finalGAP + ")";
    }

    // GPDPXA
    var gpdpxa = formData.get('gpdpxa');
    if (gpdpxa === "" && gpdpxa !== null && gpdpx !== null) // If not specified
      finalJSON[firstKey].GPDPXA = finalJSON[firstKey].GPDPX;
    else if (gpdpxa !== null && gpdpxa !== "") // If not disabled, insert
      finalJSON[firstKey].GPDPXA = parseFloat(gpdpxa);
    else if (gpdpx === null && gpdpxa === "")
      // alert("GPDPXA non deve essere vuoto.");
      // return;
      finalJSON[firstKey].GPDPXA = null;
    if (isNaN(gpdpxa) && gpdpxa !== null && gpdpxa !== "") {
      alert("GPDPXA deve essere un valore numerico.");
      return;
    }

    // GPDPYA
    var gpdpya = formData.get('gpdpya');
    if (gpdpya === "" && gpdpya !== null && gpdpy !== null) // If not specified
      finalJSON[firstKey].GPDPYA = finalJSON[firstKey].GPDPY;
    else if (gpdpya !== null && gpdpya !== "") // If not disabled, insert
      finalJSON[firstKey].GPDPYA = parseFloat(gpdpya);
    else if (gpdpy === null && gpdpya === "")
      // alert("GPDPYA non deve essere vuoto.");
      // return;
      finalJSON[firstKey].GPDPYA = null;
    if (isNaN(gpdpya) && gpdpya !== null && gpdpya !== "") {
      alert("GPDPYA deve essere un valore numerico.");
      return;
    }

    // GPCLA
    var gpcla = formData.get('gpcla');
    if (gpcla !== "" && isNaN(gpcl) ) { 
      alert("GPCLA deve essere un valore numerico.");
      return;
    }
    if (gpcla !== null) // If not disabled, insert
      if (gpcla === "")
        finalJSON[firstKey].GPCLA = null;
      else
        finalJSON[firstKey].GPCLA = parseFloat(gpcla);

    // PVCR
    // var pvcr = formData.get('pvcr');
    // if (pvcr === ""  && type !== 'sito') { 
    //   alert("PVCR è un valore obbligatorio.")
    //   return;
    // }
    finalJSON[firstKey].PVCR = "05"; // ISTAT 05 = VENETO is the only choice, for now

    // PVCP
    var pvcp = formData.get('pvcp');
    if (pvcp === "" && type !== 'sito') { 
      alert("PVCP è un valore obbligatorio.");
      return;
    }
    finalJSON[firstKey].PVCP = pvcp;

    // PVCC
    var pvcc = formData.get('pvcc');
    if (pvcc === "" && type !== 'sito') { 
      alert("PVCC è un valore obbligatorio.");
      return;
    }
    finalJSON[firstKey].PVCC = pvcc;

    // PVCL
    var pvcl = formData.get("pvcl");
    if (pvcl !== null)
      if (pvcl !== "")
        finalJSON[firstKey].PVCL = pvcl;
      else
        finalJSON[firstKey].PVCL = null;

    // PVCI
    var pvci = formData.get("pvci");
    if (pvci !== null)
      if (pvci !== "")
        finalJSON[firstKey].PVCI = pvci;
      else
        finalJSON[firstKey].PVCI = null;

    // SitoWEB
    var sitoweb = formData.get("sitoweb");
    if (sitoweb !== null)
      if (sitoweb !== "")
        finalJSON[firstKey].SitoWeb = sitoweb;
      else
        finalJSON[firstKey].SitoWeb = null;

    // Visibile
    var visibile = formData.get("visibile");
    if (visibile) { // IF not disabled
      if (visibile === 'true') {
        visibile = true;
      } else if (visibile === 'false') {
        visibile = false;
      } else if (visibile === 'null') {
        visibile = null;
      }
      finalJSON[firstKey].Visibile = visibile;
    }

    // Accessibile
    var accessibile = formData.get("accessibile");
    if (accessibile) { // IF not disabled
      if (accessibile === 'true')
        accessibile = true;
      else if (accessibile === 'false')
        accessibile = false;
      else if (accessibile === 'null')
        accessibile = null;
      finalJSON[firstKey].Accessibile = accessibile;
    }

    // DTS
    var dtsii = formData.get("dtsii");
    var dtsis = formData.get("dtsis");
    var dtsfi = formData.get("dtsfi");
    var dtsfs = formData.get("dtsfs");
    if (dtsii && dtsis && dtsfi && dtsfs) { // If not disabled
      dtsii = parseInt(dtsii);
      dtsis = parseInt(dtsis);
      dtsfi = parseInt(dtsfi);
      dtsfs = parseInt(dtsfs);

      if (isNaN(dtsii) || isNaN(dtsis) || isNaN(dtsfi) || isNaN(dtsfs)) {
        alert ("DTSII, DTSIS, DTSFI e DTSFS sono valori numerici obbligatori.");
        return;
      }
      if (!(dtsii <= dtsis && dtsis <= dtsfi && dtsfi <= dtsfs)) {
        alert ("DTSII, DTSIS, DTSFI e DTSFS devono avere valori crescenti.");
        return;
      }
      finalJSON[firstKey].DTSII = dtsii;
      finalJSON[firstKey].DTSIS = dtsis;
      finalJSON[firstKey].DTSFI = dtsfi;
      finalJSON[firstKey].DTSFS = dtsfs;
    }
   
    // ORT
    var ort = formData.get("ort");
    if (ort !== null)
      if (ort !== "")
        finalJSON[firstKey].ORT = ort;
      else
        finalJSON[firstKey].ORT = null;

    // MISA
    var misa = formData.get("misa");
    if (misa !== null)
      if (misa === "")
        finalJSON[firstKey].MISA = null;
      else {
        if (isNaN(misa))
          alert("MISA deve essere un numero (in metri).");
        finalJSON[firstKey].MISA = parseFloat(misa);
      }

    // MISL
    var misl = formData.get("misl");
    if (misl !== null)
      if (misl === "")
        finalJSON[firstKey].MISL = null;
      else {
        if (isNaN(misl))
          alert("MISL deve essere un numero (in metri).");
        finalJSON[firstKey].MISL = parseFloat(misl);
      }

    // MISN
    var misn = formData.get("misn");
    if (misn !== null)
      if (misn === "")
        finalJSON[firstKey].MISN = null;
      else {
        if (isNaN(misn))
          alert("MISN deve essere un numero (in metri).");
        finalJSON[firstKey].MISN = parseFloat(misn);
      }

    // Predecessore
    var predecessore = formData.get("predecessore");
    if (predecessore) // If not disabled
      if (predecessore !== "-1") // If the ID is valid, i.e. has a name
        finalJSON[firstKey].Predecessore = parseInt(predecessore);
      else
        finalJSON[firstKey].Predecessore = null; // Predecessor not specified

    // Father(s) list
    if (type !== "sito") {
      // Set main key
      let fatherType;
      let fatherMainKey;
      if (type === "monumento") {
        fatherType = "Complesso";
        fatherMainKey = "Complessi";
        finalJSON[firstKey].Complessi = {};
      } else if (type === "complesso") {
        if (fatherList.length !== 1) {
          alert("Il complesso deve avere solamente un sito padre!");
          return;
        }
        fatherType = "Sito";
        fatherMainKey = "Siti";
        finalJSON[firstKey].Siti = {};
      }
      // Create array of fathers
      let fatherJSONList = [];
      for (let i = 0; i < fatherList.length; i++) {
        const father = fatherList[i];
        if (i === 0 && father === -1) { // One father is mandatory
          alert("L'elemento deve avere almeno un padre!");
          return;
        }
        if (father !== -1 && !isNaN(father))
          fatherJSONList.push({[fatherType]: {ID: father}});
      }
      finalJSON[firstKey][fatherMainKey] = fatherJSONList;
    }

    // Epoch(s) list
    if (type !== "sito") {
      finalJSON[firstKey].Epoche = {};
      // Create array of epochs
      let epochJSONList = [];
      for (let i = 0; i < epochList.length; i++) {
        const epoch = epochList[i];
        if (i === 0 && epoch === -1) { // One epoch is mandatory
          alert("L'elemento deve avere almeno un'epoca!");
          return;
        }
        if (epoch !== -1 && !isNaN(epoch))
          epochJSONList.push({Epoca: {ID: epoch}});
      }
      finalJSON[firstKey].Epoche = epochJSONList;
    }

    // Image(s) list
    finalJSON[firstKey].Immagini = {};
    // Create array of images
    let imgJSONList = [];
    for (let i = 0; i < imageList.length; i++) {
      const img = imageList[i];
      if (i === 0 && (img.Link === "" || img.Link === null)) { // One epoch is mandatory
        alert("L'elemento deve avere almeno un'immagine!");
        return;
      }
      if (img.Link !== null)
        imgJSONList.push({Immagine: {ID: img.ID, Link: ("/assets/imgs/" + img.Link), Autore: (img.Autore === "" ? null : img.Autore), Descrizione: (img.Descrizione === "" ? null : img.Descrizione), Profilo: img.isProfile}});
    }
    finalJSON[firstKey].Immagini = imgJSONList;

    // Fonte Bibliografica
    finalJSON[firstKey].FontiBibliografiche = {};
    // Create array of epochs
    let fontebibliograficaJSONList = [];
    for (let i = 0; i < fonteBibliograficaList.length; i++) {
      const fb = fonteBibliograficaList[i];
      if (fb !== -1 && !isNaN(fb))
        fontebibliograficaJSONList.push({BIBH: fb});
    }
    finalJSON[firstKey].FontiBibliografiche = fontebibliograficaJSONList;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',  
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, UPDATE',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(finalJSON)
    };
    let apiAction = "/insert/";
    if (elementCurrentState)
      apiAction = "/update/";

    fetch(window.SERVER_MAIN_PATH + apiAction + type, requestOptions)
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 201) {
          let count = props.insertCount + 1;
          props.setInsertCount(count); // New Render
          alert("Inserito " + type + " con ID: " + result["New" + firstKey].ID);
        } else {
          console.log("ERRORE: " + JSON.stringify(result));
          alert("Inserimento non eseguito. Contatta TuSaiChi.");
        }   
      }).catch(function(error) {
        alert(error);
      });

    console.log(finalJSON);
  }
}

export default ElementInsert;