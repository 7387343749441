// npm
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// Custom components
import Home from './home/home.js';
import Explore from './explore/explore.js';
import Footer from './components/footer.js';
import Banner from './policy/banner.js';
import Policy from './policy/policy.js';
import Project from './project/project.js';
import About from './about/about.js';
import Team from './team/team.js';
import ScrollToTop from './utils/scrollToTop.js';
import DataInsert from './dataInsert/dataInsert.js';
// Stylesheet
import './App.css';


/**
 * This is the main application React class, from where all pages are generated.
 */
class App extends React.Component {
  constructor(props) {
    super(props);

    // Check dark mode from system preferences
    let isDark;
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // Expected behaviour from TailwindCSS
      document.querySelector('html').classList.add('dark');
      // Set page theme information to dark
      localStorage.theme = 'dark';
      isDark = true;
    } else {
      // Set page theme information to light
      localStorage.theme = 'light';
      isDark = false;
    }

    // Initialize state with system theme
    this.state = {
      darkMode: isDark
    }

    // Binding "this" to class functions
    this.darkModeCheckboxOnChange = this.darkModeCheckboxOnChange.bind(this);
  }

  componentDidMount() {
    // Add event listener if system theme changes
    window.matchMedia("(prefers-color-scheme: dark)").addListener(
      e => {
        if (e.matches) {
          document.querySelector('html').classList.add('dark');
          localStorage.theme = 'dark';
          this.setState({
            darkMode: true
          });
        } else {
          document.querySelector('html').classList.remove('dark');
          localStorage.theme = 'light';
          this.setState({
            darkMode: false
          });
        }
      }
    );
  }

  /**
   * This function is the dark mode toggle handler.
   * @param {*} event The onClick event.
   */
  darkModeCheckboxOnChange(event) {
    if (event.target.checked) {
      document.querySelector('html').classList.add('dark');
      localStorage.theme = 'dark';
      this.setState({
        darkMode: true
      });
    } else {
      document.querySelector('html').classList.remove('dark');
      localStorage.theme = 'light';
      this.setState({
        darkMode: false
      });
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop/>
        <div className="App my-auto font-raleway bg-white dark:bg-dark text-dark dark:text-white">
          <Switch>
            <Route path="/policy">
              <Policy isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
              <Footer className="hidden lg:flex" />
            </Route>
            <Route path="/about">
              <About isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
              <Footer className="hidden lg:flex" />
            </Route>
            <Route path="/explore/:view/:type/:id">
              <Explore isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
            </Route>
            <Route path="/project">
              <Project isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
              <Footer className="hidden lg:flex" />
            </Route>
            <Route path="/team">
              <Team isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
              <Footer className="hidden lg:flex" />
            </Route>
            <Route path="/dataInsert">
              <DataInsert isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
            </Route>
            <Route path="/">
              <Home isDark={this.state.darkMode} darkOnChange={this.darkModeCheckboxOnChange} />
              <Footer className="hidden lg:flex" />
            </Route>
          </Switch>
        </div>
        <Banner />
      </Router>
    );
  }
}


export default App;
