// npm
import React from 'react';

// Stylesheet
import './dataInsert.css';


/**
 * This component renders the form to insert new bibliography in the database
 * @param {*} props React props, namely:
 * - insertCount: the counter of the new insertions during the current session;
 * - setInsertCount: the function to update insertCount.
 * @returns The React component
 */
const BibInsert = (props) => {
  
  // const store = useSelector(state => state);
  // const lang = store.lang;

  return (
    <div className="pb-20">
      {/* Form */}
      <form className="flex flex-col mx-4" id="NewBibForm">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-8 my-4">
          <div className="flex justify-center items-center">
            <label className="pr-2 tooltip">BIL<span className="text-orange font-bold font-cinzel">*</span>: 
              <span className="tooltiptext">Citazione completa.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="bil" name="bil" placeholder="Inserire la citazione completa." />
          </div>
          {/* Submit */}
          <div className="flex justify-end mx-8 items-center">
            <button className="w-64 text-dark rounded-full bg-white" onClick={handlerSubmitForm}>{"Crea nuova fonte bibliografica"}</button>
          </div>
        </div>
      </form>
    </div>
  )


  // ****************************************** HANDLERS *****************************************
  
  /**
   * This function submits the new bibliography
   * @param {*} event The onClick event.
   */
  function handlerSubmitForm(event) {
    event.preventDefault();

    // Get form
    var formEl = document.forms.NewBibForm;
    var formData = new FormData(formEl);

    // Final element
    let finalJSON = {};
    finalJSON.FonteBibliografica = {};

    // BIL
    var bil = formData.get('bil');
    if (bil === null || bil === "") {
      alert("Inserire un BIL valido.");
      return;
    }
    finalJSON.FonteBibliografica.BIL = bil;

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json',  
                  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, UPDATE",
                  "Access-Control-Allow-Origin": "*" },
      body: JSON.stringify(finalJSON)
    };

    fetch(window.SERVER_MAIN_PATH + "/insert/fonteBibliografica", requestOptions)
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 201) {
          let count = props.insertCount + 1;
          props.setInsertCount(count); // New Render
          alert("Fonte bibliografica inserita con ID: " + result.FonteBibliografica.BIBH);
        } else {
          console.log("ERRORE: " + JSON.stringify(result));
          alert("Inserimento non eseguito. Contatta TuSaiChi.");
        }   
      }).catch(function(error) {
        alert(error);
      });;

    console.log(finalJSON);
  }

}

export default BibInsert;