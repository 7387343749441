// npm
import React from 'react';


/**
 * This class renders the Person component,
 * which shows a brief presentation of one of the people involved in the CArD 3D project.
 * It requires the following React properties:
 * - desc: a brief description of the person;
 * - img: a profile image;
 * - name: the person's name;
 * - role: the person's role within CArD 3D project.
 */
class Person extends React.Component {
  render() {
    return (
      <div className="rounded-3xl bg-dark dark:bg-white flex flex-col items-center text-white dark:text-dark bg-opacity-90">
        <img src={this.props.img} className="mt-6 h-28 lg:h-52 w-28 lg:w-52 rounded-full object-cover" alt={this.props.name} />
        <div className="mt-4 mx-2 font-cinzel text-xl lg:text-2xl text-orange">{this.props.name}</div>
        <div className="divide-y-2 divide-solid divide-orange mx-2">
          <div className="mb-2 mx-2 pb-2 font-cinzel text-xs lg:text-sm">{this.props.role}</div>
          <div className="mt-2 mb-4 py-2 mx-2 lg:mx-4 text-sm lg:text-base whitespace-pre-line">{this.props.desc}</div>
        </div>
      </div>
    )
  }
}

export default Person;
