// npm
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Assets
import logo_facebook from '../assets/imgs/logo_facebook.png';

// Translations
import staticTranslation from '../utils/JSON/staticTranslation.json'


/**
 * This component renders the footer.
 * @param {*} props React props, namely:
 * - className: highest container specifications
 * @returns 
 */
const Footer = (props) => {
  // Redux
  const store = useSelector(state => state)
  const lang = store.lang

  // Get footer translations
  const footerTranslations = staticTranslation[lang].footer

  return (
    <div className={"w-full flex justify-center border-t lg:border-none lg:bg-dark lg:bg-opacity-5 lg:dark:bg-white lg:dark:bg-opacity-5 " + props.className}>
      <div className="lg:w-4/5 py-2 lg:my-2 flex lg:flex-row justify-center items-center text-md">
        <a className="mx-8 cursor-pointer transform hover:scale-125 duration-300" href="https://facebook.com/CArD3D.Archeologia" target="_blank" rel="noreferrer">
          <img src={logo_facebook} className="h-8 rounded-lg" alt="CArD 3D light logo" />
        </a>
        <Link to="/project" className="mx-2 px-2 py-2 hover:text-orange transform hover:scale-110 duration-300">
          <p>
            {footerTranslations.project}
          </p>
        </Link>
        <Link to="/about" className="mx-2 px-2 py-2 hover:text-orange transform hover:scale-110 duration-300">
          <p>
            {footerTranslations.collaborations}
          </p>
        </Link>
        <Link to="/team" className="mx-2 px-2 py-2 hover:text-orange transform hover:scale-110 duration-300">
          <p>
            {footerTranslations.team}
          </p>
        </Link>
        <Link to="/policy" className="mx-2 px-2 py-2 hover:text-orange transform hover:scale-110 duration-300">
          <p>
            Privacy Policy
          </p>
        </Link>
        <a href="mailto:info.card3d@gmail.com?subject=Informazioni%20CArD%203D" className="flex items-center justify-center h-8 w-8 mx-8 bg-bluemail rounded-lg cursor-pointer transform hover:scale-125 duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
          </svg>
        </a>
      </div>
    </div>
  )
}

export default Footer;
