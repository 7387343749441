// npm
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Custom components
import Player from './player.js';

// Stylesheet
import './infoBar.css';

// Assets
import staticTranslation from '../../utils/JSON/staticTranslation.json';
import oldImgBlack from '../../assets/imgs/evoluzione/colonne_nero.png';
import newImgBlack from '../../assets/imgs/evoluzione/museo_nero.png';
import oldImgWhite from '../../assets/imgs/evoluzione/colonne_bianco.png';
import newImgWhite from '../../assets/imgs/evoluzione/museo_bianco.png';


/**
 * This function converts the first character of a string object to uppercase.
 * @param {String} string The string to be converted.
 * @returns A string with its first character uppercased, or an empty string when null is passed.
 */
const firstUpperCase = (string) => {
  if (string === null)
    return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
   * This component renders the InfoBar, displaying to the user the information related to the currentElement
   * and providing several links to explore related elements.
   * It requires the following React properties:
   * - button3D: a String containing a CSS class to render accordingly the button linking to the 3D visualizer;
   * - evolutionValue: the position of the evolution slider;
   * - handlerMobileSidebarCross: the function called when the 'close' button is pressed on mobile;
   * - handlerMobileSidebarExpand: the function called when the 'expand' button is pressed on mobile;
   * - handlerMobileSidebarReduce: the function called when the 'reduce' button is pressed on mobile;
   * - mobileSidebarExpand: a String containing a CSS class to render the 'expand' button;
   * - mobileSidebarReduce: a String containing a CSS class to render the 'reduce' button;
   * - model3D: the object containing the information related to the 3D model;
   * - openImageViewer: the function that opens the ImageViewer component;
   * - proMode: a boolean variable indicating if the Pro mode is enabled;
   * - setEvolutionValue: the function that handles the evolutionValue;
   * - updateCurrentElement: the function to be called whenever the user moves from an element to another.
  */
const InfoBar = (props) => {

  // REDUX store
  const currentElement = useSelector(state => state).currentElement;
  const store = useSelector(state => state);
  const storeCurrentElement = store.currentElement;
  const lang = useSelector(state => state).lang;
  // infoBar translations
  const infoBarTranslations = staticTranslation[lang].infoBar;


  /**
   * This effect listens to storeCurrentElement and places the evolution cursor 
   * to the correct position.
   */
  useEffect(() => {
    if (currentElement.isLoaded) {
      let type = Object.keys(currentElement.data);
      let data = currentElement.data[type];
      if (type[0] === "Monumento" && props.evolutionValue === -1)
        props.setEvolutionValue(data.Predecessori.length);
    }
  // eslint-disable-next-line
  }, [storeCurrentElement]);


  if (currentElement.isLoaded) {

    let type = Object.keys(currentElement.data);
    let data = currentElement.data[type];

    var steplist = []; // This variable is considered only on Chrome to display steps on the evolution slider...
    // ...and here it is populated.
    if (type[0] === "Monumento" && props.evolutionValue === -1) {
      for (var i = 0; i < (data.Predecessori.length+data.Successori.length+1); i++) {
        steplist.push(<option value={i} key={"option"+i}></option>);
      }
    }

    // Create map link
    let mapLink = null
    // If we're on iOS, open in Apple Maps...
    if ((navigator.platform.indexOf("iPhone") !== -1) || (navigator.platform.indexOf("iPad") !== -1) || (navigator.platform.indexOf("iPod") !== -1)) {
      mapLink = "maps://maps.google.com/maps?daddr=" + data.GPDPYA + "," + data.GPDPXA + "&amp;ll=";
    } else { // ...otherwise in Google Maps
      mapLink = "https://maps.google.com/maps?daddr=" + data.GPDPYA + "," + data.GPDPXA + "&amp;ll=";
    }


    /**
     * This function finds the index of the profile image in the array of images.
     * @returns The index.
     */
    const getProfileImgPosition = () => {
      for (let i = 0; i < data.Immagini.length; i++) {
        if (data.Immagini[i].ImmagineLight.Profilo)
          return i;
      };
      return 0;
    }
    

    /**
     * This function iterates on the array of images to find the link of the profile image.
     * @returns The link.
     */
    const getProfileImg = () => {
      for (let i = 0; i < data.Immagini.length; i++) {
        if (data.Immagini[i].ImmagineLight.Profilo)
          return data.Immagini[i].ImmagineLight.Link;
      };
      return null;
    }
    

    /**
     * This function is used to display all Epocas when there are more than one
     * @returns A string containing all Epocas
     */
    const epocas = () => {
      let s = "";
      for (let i = 1; i < data.Epoche.length; i++) {
        s = s + " - " + firstUpperCase(data.Epoche[i].Epoca.DTZG);
      }
      return s;
    }
    

    /**
     * This function creates the gallery of images depending on the images array length.
     * @returns An array of JSX elements corresponding to the gallery.
     */
    const images = () => {
      // Extract all imgs which are not for profile photo
      const imgs = data.Immagini;
      const imgsLength = imgs.length;
      // Prepare the array of returned elements
      const els = [];
      let i = 0;
      // Add to els up to two (2) images
      while (i < imgsLength && els.length < 2) {
        const currentImg = imgs[i].ImmagineLight;
        const j = i;
        if (!currentImg.Profilo)
          els.push(
            <div key={"divImg"+(i+1)} className="flex items-center justify-center w-1/3 duration-300 transform hover:scale-105">
              <img
                key={i}
                className="h-info w-info lg:h-28 lg:w-28 2xl:h-40 2xl:w-40 rounded-3xl cursor-pointer"
                src={process.env.PUBLIC_URL + currentImg.Link + ".jpg"}
                alt={"img"+(i+1)}
                onClick={ () => props.openImageViewer(j) }
              />
            </div>
          );
        i++;
      }
      // If the available images are only three (3), the last of the three elements is the last image...
      if (imgsLength === 4) // In the array of images there is also the profile image
        els.push(
          <div key={"divImg"+(i+1)} className="flex items-center justify-center w-1/3 duration-300 transform hover:scale-105">
            <img
              key={i}
              className="h-info w-info lg:h-28 lg:w-28 2xl:h-40 2xl:w-40 rounded-3xl cursor-pointer"
              src={process.env.PUBLIC_URL + imgs[3].ImmagineLight.Link + ".jpg"}
              alt={"img"+(i+1)}
              onClick={ () => props.openImageViewer(i) }
            />
          </div>
        );
      // ...otherwise it is the fourth image with a '+' in the middle, which will open the fourth image in the ImageViewer (since we are considering also the profile image)
      else if (imgsLength > 4)
        els.push(
          <div key={"divImg"+(i+1)} className="flex items-center justify-center w-1/3">
            <button className="relative flex items-center justify-center h-info w-info lg:h-28 lg:w-28 2xl:h-40 2xl:w-40 rounded-3xl cursor-pointer duration-300 transform hover:scale-105" onClick={ () => props.openImageViewer(i+1) }>
              <img
                className="h-info w-info lg:h-28 lg:w-28 2xl:h-40 2xl:w-40 rounded-3xl cursor-pointer"
                src={process.env.PUBLIC_URL + imgs[3].ImmagineLight.Link + ".jpg"}
                alt={"img"+(i+1)}
              />
              <div className="bg-dark bg-opacity-60 absolute h-full w-full rounded-3xl flex items-center justify-center duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </button>
          </div>
        );
      return els;
    }

    let galleryImgs = images();
    

    /**
     * This function displays the Monumentos related to the Monumento.
     * @returns An array of JSX elements containing the name of and links to the related Monumentos.
     */
    const monumentos = () => {
      let els = [];
      data.Monumenti.forEach((element, i) => {
        if (element.MonumentoLight.GPDP)
          els.push(
            <div key={"monumento"+i} className="flex justify-center">
              <Link
                className="my-1 mx-8 cursor-pointer hover:text-orange transition-all duration-300"
                to={"/explore/map/monumento/" + element.MonumentoLight.ID}
                onClick={() => {
                  props.updateCurrentElement(element.MonumentoLight.ID, "monumento");
                  if (data.Predecessori) {
                    // Check if the Monumento is a Predecessor
                    for (let j = 0; j < data.Predecessori.length; j++)
                      if (data.Predecessori[j].MonumentoLight.ID === element.MonumentoLight.ID)
                        props.setEvolutionValue(j);
                    // Check if the Monumento is a Successor
                    for (let j = 0; j < data.Successori.length; j++)
                      if (data.Successori[j].MonumentoLight.ID === element.MonumentoLight.ID)
                        props.setEvolutionValue(data.Predecessori.length+1+j);
                  }
                }}
              >
                {element.MonumentoLight.OGTN}
              </Link>
            </div>
          );
      });
      return els;
    }
    

    /**
     * This function displays the Complessos related to the Monumento
     * @returns An array of JSX elements containing the name of and links to the related Complessos.
     */
    const complessos = () => {
      let els = [];
      data.Complessi.forEach((element, i) => {
        if (element.ComplessoLight.GAP)
          els.push(
            <div key={"complesso"+i} className="flex justify-center">
              <Link className="w-full my-1 mx-8 cursor-pointer hover:text-orange transition-all duration-300" to={"/explore/map/complesso/"+element.ComplessoLight.ID} onClick={() => props.updateCurrentElement(element.ComplessoLight.ID, "complesso")}>
                {element.ComplessoLight.OGTN}
              </Link>
            </div>
          );
      });
      return els;
    }
    

    /**
     * This function displays the Sitos related to the Complesso.
     * @returns An array of JSX elements containing the name of and links to the related Sitos.
     */
    const sitos = () => {
      let els = [];
      data.Siti.forEach((element, i) => {
        if (element.SitoLight.GAP)
          els.push(
            <div key={"sito"+i} className="flex justify-center">
              <Link to={"/explore/map/sito/"+element.SitoLight.ID} className="w-full my-1 mx-8 cursor-pointer hover:text-orange transition-all duration-300" onClick={() => props.updateCurrentElement(element.SitoLight.ID, "sito")}>
                {element.SitoLight.OGTN}
              </Link>
            </div>
          );
      });
      return els;
    }
    

    /**
     * This function displays the FonteBibliograficas related to the Object.
     * @returns An array of JSX elements containing the name of and links to the related FonteBibliograficas.
     */
    const bibliography = () => {
      let els = [];
      data.FontiBibliografiche.forEach((element, i) => {
        els.push(
          <div key={"fonteBibliografica"+i} className="my-4 mx-8">
            {element.FonteBibliografica.BIL}
          </div>
        );
      });
      return els;
    }


    /**
     * This function updates the currentElement whenever the slider is used.
     * @param {*} e The event that called the function.
     */
    const updateCurrentElementBySlider = (e) => {
      e.preventDefault();
      let val = e.target.value;
      const pLen = data.Predecessori.length;
      if (val < pLen) {
        props.updateCurrentElement(data.Predecessori[pLen-1-val].MonumentoLight.ID, "monumento");
        props.setEvolutionValue(val);
      } else if (data.Successori.length > 0) {
        props.updateCurrentElement(data.Successori[val-pLen-1].MonumentoLight.ID, "monumento");
        props.setEvolutionValue(val);
      }
    }

    // console.log(currentElement);

    return (
      <div className="backdrop-blur">
        {/* Expand and Close sidebar in mobile view */}
        <div key="first-div" className="sticky h-16 inset-x-0 top-0 lg:hidden flex justify-between bg-gradient-to-b from-white dark:from-dark via-middle dark:via-middledark">
          <div className={(props.mobileSidebarExpand) + " h-10 flex items-center justify-center mt-8 mx-3"}>
            <div className="rounded-full bg-white shadow-strong h-10 w-10 flex items-center justify-center">
              <svg className={"h-8 stroke-dark"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" onClick={props.handlerMobileSidebarExpand}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
          <div className={(props.mobileSidebarReduce) + " h-10 flex items-center justify-center mt-8 mx-3"}>
            <div className="rounded-full bg-white shadow-strong h-10 w-10 flex items-center justify-center">
              <svg className={"h-8 stroke-dark"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" onClick={props.handlerMobileSidebarReduce}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div className="h-10 flex items-center justify-center mt-8 mx-3">
            <div className="rounded-full bg-white shadow-strong h-10 w-10 flex items-center justify-center">
              <svg className="h-8 stroke-dark" xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24" onClick={props.handlerMobileSidebarCross}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
        </div>

				<div key="second-div" className="inset-x-0 top-0 mb-8">

					{/* Element image */}
					<div className="flex justify-center lg:mt-20 mb-6">
						<img src={process.env.PUBLIC_URL + getProfileImg() + ".jpg"} alt="Immagine Profilo" className="h-44 w-44 lg:h-60 lg:w-60 rounded-full object-cover cursor-pointer duration-300 transform hover:scale-105" onClick={ () => props.openImageViewer(getProfileImgPosition()) } />
					</div>

					{/* Element name */}
					<div className="font-cinzel text-3xl mx-6">
						{firstUpperCase(data.OGTN)}
					</div>
          <div className="mx-auto mb-4">
            {firstUpperCase(data.OGTD)}
          </div>

					{/* Element address */
          type[0] === "Sito" ? 
            null :
            <div className="font-raleway mx-2 font-bold flex justify-center mb-1">
              <a href={mapLink} className="flex flex-row inline-block hover:text-orange transition-all duration-300 transform hover:scale-105" target="_blank" rel="noreferrer">
                <span>
                  {(data.PVCI ? (firstUpperCase(data.PVCI) + ", ") : "") + (data.PVCL ? (firstUpperCase(data.PVCL) + " di ") : "") + data.PVCC + " (" + data.PVCP + ")"}
                  <svg className="h-5 mx-1 inline-block align-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="orange">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                </span>
              </a>
            </div>
          }

					{/* Element website */
          data.SitoWeb ? 
            <div className="font-raleway mx-auto text-orange underline flex justify-center">
              <a href={data.SitoWeb} className="flex flex-row" target="_blank" rel="noreferrer">
                {infoBarTranslations.website}
                <svg className="h-5 w-5 mx-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="orange">
                  <path fillRule="evenodd" d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z" clipRule="evenodd" />
                </svg>
              </a>
            </div>
          : null
          }

					{/* Element 3D button */
          (type[0] === "Monumento" || type[0] === "Complesso") ?
            data.Modelli3D.length > 0 ?
              <div className="flex justify-center my-6">
                <Link to={"/explore/model/"+type[0].toLowerCase()+"/"+data.ID} className={(props.button3D) + " w-1/3 py-1 flex-row justify-center items-center font-raleway font-bold text-2xl bg-dark bg-opacity-5 hover:bg-dark hover:text-white dark:bg-white dark:bg-opacity-5 dark:hover:bg-white dark:hover:bg-opacity-95 dark:hover:text-dark transition-all duration-300 transform hover:scale-110 rounded-full"}>
                  <span>
                    3D
                  </span>
                  <svg className="h-10 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="orange">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                  </svg>
                </Link>
              </div>
            : null
          : null
          }

          {/* Element audio, currently only for Palazzo Maldura */
          data.ID === 58 && type[0] === "Monumento" ?
            <div className="flex justify-center my-6">
              <Player url={process.env.PUBLIC_URL + '/assets/audio/file new.mp3'} />
            </div>
          : null}

          {/* Visibility and accessibility information */}
          <div className="font-raleway mx-auto flex justify-center px-6">{
            data.Visibile ?
              data.Accessibile ?
                "Puoi visitare questo " + type + "!"
              :
                "Questo " + type + " esiste ancora oggi, ma non è visitabile."
            :
              data.Accessibile ?
                "Questo " + type + " non è più visibile, ma puoi visitarlo!"
              :
                "Purtroppo, questo " + type + " non esiste più."
          }</div>
          
          {/* Informations */}
          <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
            {infoBarTranslations.information}
          </div>
          {/* Verify if the currentElement is a Monumento or a Complesso by checking the presence of Epoche array */
          data.Epoche ? <>
            <div className="mt-2 font-bold text-lg text-left mx-6">
              {infoBarTranslations.chronology}
            </div>
            <div className="font-light text-right mx-6">
              {firstUpperCase(data.DTSI) + " - " + firstUpperCase(data.DTSF)}
            </div>
            {data.Epoche[0] ? <>
              <div className="mt-2 font-bold text-lg text-left mx-6">
                {infoBarTranslations.period}
              </div>
              <div className="font-light text-right mx-6">
                {firstUpperCase(data.Epoche[0].Epoca.DTZG)}
                {epocas()}
              </div>
            </> : null}
            {data.OGTC ? <>
              <div className="mt-2 font-bold text-lg text-left mx-6">
                {infoBarTranslations.category}
              </div>
              <div className="font-light text-right mx-6">
                {firstUpperCase(data.OGTC)}
              </div>
            </> : null}
            {(props.proMode) ? <>
              {data.MISL && data.MISA && data.MISN ? <>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  {infoBarTranslations.dimensions}
                </div>
                <div className="font-light text-right mx-6">
                  {data.MISL + "m x " + data.MISA + "m x " + data.MISN + "m"}
                </div>
              </> : null}
              {data.ORT ? <>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  {infoBarTranslations.orientation}
                </div>
                <div className="font-light text-right mx-6">
                  {data.ORT}
                </div>
              </> : null}
            </> : null}
          </> : <>
            {data.OGTT ? <>
              <div className="mt-2 font-bold text-lg text-left mx-6">
                {infoBarTranslations.precisation}
              </div>
              <div className="font-light text-right mx-6">
                {firstUpperCase(data.OGTT)}
              </div>
            </> : null}
            {data.OGTY ? <>
              <div className="mt-2 font-bold text-lg text-left mx-6">
                {infoBarTranslations.denomination}
              </div>
              <div className="font-light text-right mx-6">
                {firstUpperCase(data.OGTY)}
              </div>
            </> : null}
          </>}

          {/* Gallery Images */
            galleryImgs.length > 0 ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                {infoBarTranslations.images}
              </div>
              <div className="w-full flex flex-row flex-wrap items-center mt-4 lg:px-2">
                {galleryImgs}
              </div>
            </> : null
          }

          {/* Element evolution*/
          type[0] === "Monumento" ? 
            data.Predecessori[0] || data.Successori[0] ?<>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                {infoBarTranslations.evolution}
              </div>
              <div className="flex flex-row w-full mx-auto text-justify font-light px-6 mt-2">
                <div className="flex items-center">
                  <img className="w-8 py-2" src={localStorage.theme === 'dark' ? oldImgWhite : oldImgBlack} alt="oldIcon"></img>
                </div>
                <div className="range-slider flex-grow justify-center py-2 mx-6">
                  <datalist id="steplist">
                    {steplist}
                  </datalist>
                  <input id="evolution-slider" type="range" min="0" max={data.Predecessori.length + data.Successori.length} value={props.evolutionValue} list="steplist" onChange={(e) => updateCurrentElementBySlider(e)}></input>
                </div>
                <div className="flex items-center">
                  <img className="w-8 py-2" src={localStorage.theme === 'dark' ? newImgWhite : newImgBlack} alt="newIcon"></img>
                </div>
              </div>
            </> : null
          : null}

          {/* Element description */
          data.DESO ? <>
            <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.description}
            </div>
            <div className="font-raleway mx-auto text-justify font-light px-6 mt-2">
              {data.DESO}
            </div>
          </> : null}

          {/* Element history */
          props.proMode && data.RES ? <>
            <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.hnd + " " + type}
            </div>
            <div className="font-raleway mx-auto text-justify font-light px-6 mt-2">
              {data.RES}
            </div>
          </> : null}

          {/* Element historical characters */
            props.proMode && data.CASD ? <>
            <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.casd}
            </div>
            <div className="font-raleway mx-auto text-justify font-light px-6 mt-2">
              {data.CASD}
            </div>
          </> : null}

          {/* Element observations */
          props.proMode && data.OSS ? <>
            <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.observations}
            </div>
            <div className="font-raleway mx-auto text-justify font-light px-6 mt-2">
              {firstUpperCase(data.OSS)}
            </div>
          </> : null}

          {/* Monumentos */
          data.Monumenti && data.Monumenti.length > 0 ? <>
            <div className="mt-8 mb-2 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.monumentos}
            </div>
            <div className="font-cinzel">
              {monumentos()}
            </div>
          </> : null}

          {/* Complessos */
          data.Complessi && complessos().length > 0 ? <>
            <div className="mt-8 mb-2 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.complessos}
            </div>
            <div className="font-cinzel">
              {complessos()}
            </div>
          </> : null}

          {/* Sito */
          data.SitoLight ? <>
            <div className="mt-8 mb-2 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.sito}
            </div>
            <div>
              <Link to={"/explore/map/sito/"+data.SitoLight.ID} className="w-full my-1 font-cinzel cursor-pointer hover:text-orange transition-all duration-300" onClick={() => props.updateCurrentElement(data.SitoLight.ID, "sito")}>
                {data.SitoLight.OGTN}
              </Link>
            </div>
          </> : null}

          {/* Sitos */
          data.Siti ? <>
            <div className="mt-8 mb-2 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.sitos}
            </div>
            <div className="font-cinzel">
              {sitos()}
            </div>
          </> : null}

          {/* Bibliography */
          props.proMode && data.FontiBibliografiche[0] ? <>
            <div className="mt-8 mb-2 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
              {infoBarTranslations.bibliography}
            </div>
            <div>
              {bibliography()}
            </div>
          </> : null}

          {/** 3D model info */
          props.proMode && props.model3D ? <>
            {props.model3D.FNSD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Fondazioni
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tipo
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.FNST)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Materiale Impiegato
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.FNSM)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tecnica costruttiva
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.FNSC)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.FNSD)}
                </div>
            </> : null}
            {props.model3D.ELED ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Elevati
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Definizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ELEN)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tipo di tecnica
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ELET)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ELED)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Componenti materiale edilizio costituente
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ELEX)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Componenti materiale edilizio legante
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ELEL)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione del paramento
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ELEM)}
                </div>
            </> : null}
            {props.model3D.SOLD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Solai e strutture orizzontali
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tipo
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SOLO)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Configurazione esterna
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SOLE)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Configurazione interna
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SOLI)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SOLD)}
                </div>
            </> : null}
            {props.model3D.SCAD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Scale
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SCAD)}
                </div>
            </> : null}
            {props.model3D.CPUD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Coperture
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tipo
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.CPUT)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Configurazione esterna
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.CPUE)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Configurazione interna
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.CPUI)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.CPUD)}
                </div>
            </> : null}
            {props.model3D.SPAD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Spazi aperti
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tipo
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SPAT)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tecnica
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SPAC)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.SPAD)}
                </div>
            </> : null}
            {props.model3D.RIVD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Rivestimenti - Elementi di pregio
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Tipo
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.RIVT)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Materiale
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.RIVM)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.RIVD)}
                </div>
            </> : null}
            {props.model3D.ISRD ? <>
              <div className="mt-8 py-0.5 mr-6 pl-6 rounded-r-2xl font-bold text-lg bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
                Iscrizioni
              </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Classe di appartenenza
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ISRC)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Lingua
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ISRL)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Posizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ISRP)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Trascrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ISRI)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Misure
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ISRM)}
                </div>
                <div className="mt-2 font-bold text-lg text-left mx-6">
                  Descrizione
                </div>
                <div className="font-light text-right mx-6">
                  {firstUpperCase(props.model3D.ISRD)}
                </div>
            </> : null}
          </> : null}
				</div>
      </div>
    )
  } else {
    return (
      <div className="flex h-full justify-center items-center">
        <div>
          {infoBarTranslations.noElement}
        </div>
      </div>
    )
  }
}

export default InfoBar;
