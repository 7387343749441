// npm
import React from 'react';

// Assets
import logo from '../assets/imgs/3DLogo.png';

/**
 * This class renders an Error displayed in a standard format.
 * It requires the following React properties:
 * - Message: The main reason;
 * - Code: HTTP error code;
 * - Details: Any additional detail.
 */
class Error extends React.Component {
  render() {
    return (
      <div className="flex flex-col items-center text-left justify-center my-4 w-full h-100 border-2 border-errorred rounded-xl">
        <img className="w-16" src={logo} alt="Error logo." />
        <p>
          <div className="flex flex-row text-xl">
            Accidenti! Si è verificato un&nbsp;<span class="text-errorred">errore</span>&nbsp;:(
          </div>
          <br />
          <b>Message:</b> {this.props.message}
          <br />
          <b>Code:</b> {this.props.code}
          <br />
          <b>Details:</b> {this.props.details}
        </p> 
      </div>
    )
  }
}

export default Error;
