import React, { useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

import './searchBar.css';
import Logo3D from '../../assets/imgs/3DLogo.png'

import staticTranslation from '../../utils/JSON/staticTranslation.json'

/**
 * This component renders the Search Bar, the main interaction element to explore CArD 3D elements.
 */
const SearchBar = (props) => {

  // React Router
  const history = useHistory();
  const routeToMap = useCallback((id, type) => history.push('/explore/map/'+type+'/'+id), [history]);
  // REDUX store
  const dispatch = useDispatch();
  const store = useSelector(state => state);
  const lang = store.lang;
  // React state
  const [autocompletionsHidden, setAutocompletionsHidden] = useState(true);
  const [insertedInput, setInsertedInput] = useState("");
  const [autocompleteResponse, setAutocompleteResponse] = useState({data: null, isLoaded: false, messageError: null});
  const [autocompleteMatches, setAutocompleteMatches] = useState([]);
  // searchBar translations
  const searchBarTranslations = staticTranslation[lang].searchBar;
  

  /**
   * AJAX call for getting the suggestions while the user types on the SearchBar
   */
  useEffect(() => {
    if (insertedInput.length === 2) {
      fetch(window.SERVER_MAIN_PATH + "/utility/autocomplete?q=" + insertedInput + "&language=" + lang, {
        method: 'GET',
      })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(
        ([statusCode, result]) => {
          if (statusCode === 200) {
            let matches = [];
            for (let i = 0; i < result.Autocomplete.length; i++) {
              matches.push(result.Autocomplete[i]);
            }
            setAutocompleteResponse({data: result.Autocomplete, isLoaded: true, messageError: null});
            setAutocompleteMatches(matches);
            setAutocompletionsHidden(false);
          } 
          else if (statusCode !== 200 && result) {
            setAutocompleteResponse({data: null, isLoaded: false, messageError: result.message});
          }
        },
        (error) => {
          console.log(error);
        }
      )
    }
  }, [lang, insertedInput])

 
  // CSS variables
  let mainDivClasses;
  let visibleGradient;
  let formWidth;

  if (props.isHomePage) {
    //mainDivClasses = "h-16 sm:h-15v md:h-10v lg:h-1/5";
    mainDivClasses = "h-10v lg:h-1/5";
    visibleGradient = "";
    formWidth = "lg:w-2/5";
  } else {
    // mainDivClasses = "h-10v sm:h-15v md:h-10v lg:w-2/5 bg-white dark:bg-dark lg:bg-noColor lg:dark:bg-noColor"; // With md and sm measures
    mainDivClasses = "h-10v lg:w-2/5 bg-white dark:bg-dark lg:bg-noColor lg:dark:bg-noColor";
    visibleGradient = "hidden";
    formWidth = "lg:w-full";
  }


  return (
    <div id="searchBar-div" className={"fixed z-40 lg:mx-0 w-full flex justify-center items-center " + mainDivClasses}>
      {/* Container with linear gradients. They are required to use transition on opacity. */}
      <div id="darkbg-div" className={visibleGradient + " absolute opacity-0 dark:opacity-100 h-full w-full bg-gradient-to-b from-dark via-middledark"}></div>
      <div id="lightbg-div" className={visibleGradient + " absolute opacity-100 dark:opacity-0 h-full w-full bg-gradient-to-b from-white via-middle"}></div>

      {/* Searchbar */}
      <div className={"z-10 h-full flex items-center justify-between w-full " + formWidth}>
        {/* Logo (mobile) */}
        <Link to="/" className="z-10 lg:hidden flex justify-center w-1/12 mx-3">
          <img src={Logo3D} alt="Home icon" className="h-6 sm:h-8 w-6 sm:w-8" />
        </Link>

        <div className={"relative w-5/6 lg:w-full h-2/3 lg:h-12 flex flex-column " + props.searchBarDivClasses}>
          <form id="searchForm" className="absolute z-10 rounded-full shadow-3xl dark:shadow-3xl-white h-full lg:h-12 w-full flex flex-grow justify-between bg-white dark:bg-white" onSubmit={submitSearch}>
            <input
              id="search-text"
              className="rounded-full focus:outline-none font-cinzel text-md lg:text-xl flex w-full bg-white bg-opacity-0 pl-4 text-dark"
              type="text"
              autoComplete="off"
              placeholder={searchBarTranslations.placeholder}
              onChange={handlerTextInput}
            />
            {insertedInput.length > 0 ?
              <button id="search-button" className="focus:outline-none lg:my-1" type="submit" onClick={submitSearch}>
                <svg xmlns="http://www.w3.org/2000/svg" className="px-2 sm:px-4 h-6 lg:py-2 lg:h-10 stroke-dark transition-all duration-300 hover:stroke-orange" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </button>
            :
              <button id="search-button" className="focus:outline-none tooltip lg:my-1" title="Dove mi trovo?" type="submit" onClick={searchNearest}>
                <svg xmlns="http://www.w3.org/2000/svg" className="px-2 sm:px-4 h-6 lg:py-2 lg:h-10 stroke-dark hover:stroke-orange transition-all duration-300" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span id="locationMessage" className="tooltipmessage scale-0 transform ease-in-out transition-all duration-5000">
                  Nessun monumento vicino a te. Prova a cercarli tutti premendo sulla mappa a destra!
                </span>
              </button>
            }

            <span id="adminLimitMessage" className="tooltipmessage scale-0 transform ease-in-out transition-all duration-5000">
              Nessun monumento trovato in questa località.
            </span>
            <div className="border my-2 border-dark border-opacity-30 rounded" />
            <button id="map-button" className="focus:outline-none lg:my-1" title="Visualizza tutti gli elementi!" type="button" onClick={searchAll}>
              <svg xmlns="http://www.w3.org/2000/svg" className="pr-4 pl-2 sm:pl-4 h-6 lg:py-2 lg:h-10 stroke-dark hover:stroke-orange transition-all duration-300" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
              </svg>
            </button>
          </form>
          {/** Autocomplete */}
          <div id="autocompleteDiv" className={(autocompletionsHidden ? "hidden" : "") + " top-1/3 lg:top-0 lg:mt-2 absolute z-0 w-full pb-2 rounded-2xl shadow-3xl dark:shadow-3xl-white bg-white dark:bg-white bg-opacity-80 backdrop-blurxl dark:bg-opacity-80 dark:backdrop-blurxl font-cinzel text-dark text-left"}>
            <div className="h-8 md:h-16 lg:h-12" />
            {!autocompletionsHidden && autocompleteResponse.isLoaded && autocompleteMatches.map((value, index) => {
              return (
                value.autocomplete.OGTN.toLowerCase().includes(insertedInput.toLowerCase()) ?
                  index < 10 ? // Display 10 elements at maximum
                    <div id={index+"-autocomplete"} key={index} className="pl-4 my-2 md:my-1 hover:bg-dark hover:bg-opacity-5 cursor-pointer" onClick={() => specificSearch(JSON.stringify(value))}>
                      {value.autocomplete.Tipo.toLowerCase() === "limite amministrativo" ?
                        <span className="flex flex-row items-bottom">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <p>{value.autocomplete.OGTN}</p>
                        </span>
                      :
                        <span className="flex flex-row items-bottom">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                          </svg>
                          <p>{value.autocomplete.OGTN}</p>
                        </span>
                      }
                    </div>
                  : null
                : null
              )
            })}
          </div>
        </div>

        {/* Mobile menu button */}
        <div className="z-10 lg:hidden flex justify-center w-1/12 mx-3 px-1 py-1">
          <button id="mobileSidebar" type="button" onClick={props.handleMobileSidebar}>
            <svg className="h-6 fill-dark dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              {props.mobileSidebarIsOpen ?
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              :
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
              }
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
  
  
  // ***************************************** FUNCTIONS ******************************************

  /**
   * Autocomplete management
   * @param {*} event The event that corresponds to the inserted character
   */ 
  function handlerTextInput(event) {
    let currentText = event.target.value;
    let isHidden = true;

    if (autocompleteResponse.isLoaded && autocompleteResponse.data) {
      let matches = [];
      for (let i = 0; i < autocompleteResponse.data.length; i++) {
        const element = autocompleteResponse.data[i];
        if (element.autocomplete.OGTN.toLowerCase().includes(currentText.toLowerCase())) {
          matches.push(element);
          isHidden = false;
        }
      }
      setAutocompleteMatches(matches);
    }

    if (currentText === "" || autocompleteResponse.data === null)
      isHidden = true;

    if (currentText.length < 2 && insertedInput !== null)
      isHidden = true;

    setAutocompletionsHidden(isHidden);
    setInsertedInput(currentText);
  }

  /**
   * Submit search
   * @param {*} event The event that triggered the submission
   */ 
  function submitSearch(event) {
    event.preventDefault();
    if (insertedInput.length < 2)
      return;
    fetch(window.SERVER_MAIN_PATH + "/search/string?q=" + insertedInput + "&language=" + lang, {
      method: 'GET',
    })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(
      ([statusCode, result]) => {
        if (statusCode === 200) {
          if (props.setEvolutionValue)
            props.setEvolutionValue(-1);
          dispatch({
            type: "CHANGE_SEARCHED_ELEMENTS",
            payload: {data: result.Results, isLoaded: true, messageError: null}
          });
          dispatch({
            type: "CHANGE_CURRENT_ELEMENT",
            payload: {data: null, isLoaded: false, messageError: null}
          });
          // It is not a specific search so the current element
          // is selected randomly
          let randomIndex = Math.round(Math.random() * (result.Results.length - 1));
          routeToMap(result.Results[randomIndex].SearchResult.ID, result.Results[randomIndex].SearchResult.Tipo.toLowerCase());
        } 
        else if (statusCode !== 200 && result) {
          dispatch({
            type: "CHANGE_SEARCHED_ELEMENTS",
            payload: {data: [], isLoaded: false, messageError: result.message}
          });
        }
      },
      (error) => {
        console.log(error);
      }
    )
    
    setAutocompletionsHidden(true);
    document.getElementById('searchForm').reset();
  }

  /**
   * This function searches for all elements stored in the database.
   * @param {*} event The event that triggered the search
   */
  function searchAll(event) {
    event.preventDefault();

    fetch(window.SERVER_MAIN_PATH + "/search/string?q=&language=" + lang, {
      method: 'GET',
    })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(
      ([statusCode, result]) => {
        if (statusCode === 200) {
          if (props.setEvolutionValue)
            props.setEvolutionValue(-1);
          dispatch({
            type: "CHANGE_SEARCHED_ELEMENTS",
            payload: {data: result.Results, isLoaded: true, messageError: null}
          });
          dispatch({
            type: "CHANGE_CURRENT_ELEMENT",
            payload: {data: null, isLoaded: false, messageError: null}
          });
          // It is not a specific search so the current element
          // is selected randomly
          let randomIndex = Math.round(Math.random() * (result.Results.length - 1));
          routeToMap(result.Results[randomIndex].SearchResult.ID, result.Results[randomIndex].SearchResult.Tipo.toLowerCase())
        } 
        else if (statusCode !== 200 && result) {
          dispatch({
            type: "CHANGE_SEARCHED_ELEMENTS",
            payload: {data: [], isLoaded: false, messageError: result.message}
          });
        }
      },
      (error) => {
        console.log(error);
      }
    )
    
    setAutocompletionsHidden(true);
    document.getElementById('searchForm').reset();
  }

  /**
   * This function searches for the elements near to the user.
   * @param {*} event The event that triggered the search.
   */
  async function searchNearest(event) {
    event.preventDefault();
    navigator.geolocation.getCurrentPosition(function(position) {
      let y = position.coords.latitude;
      let x = position.coords.longitude;

      fetch(window.SERVER_MAIN_PATH + "/search/nearest?x=" + x + "&y=" + y + "&language=" + lang, {
        method: 'GET',
      })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(
        ([statusCode, result]) => {
          if (statusCode === 200) {
            if (props.setEvolutionValue)
              props.setEvolutionValue(-1);
            dispatch({
              type: "CHANGE_SEARCHED_ELEMENTS",
              payload: {data: result.Results, isLoaded: true, messageError: null}
            });
            dispatch({
              type: "CHANGE_CURRENT_ELEMENT",
              payload: {data: null, isLoaded: false, messageError: null}
            });
            // It is not a specific search so the current element
            // is selected randomly
            let randomIndex = Math.round(Math.random() * (result.Results.length - 1));
            routeToMap(result.Results[randomIndex].SearchResult.ID, result.Results[randomIndex].SearchResult.Tipo.toLowerCase())
          } 
          else if (statusCode !== 200 && result) {
            var elem = document.getElementById("locationMessage");
            let classes = elem.classList;
            classes.remove("scale-0");
            classes.add("scale-100");
            setTimeout(function(){ classes.remove("scale-100"); classes.add("scale-0"); }, 5000);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      
      setAutocompletionsHidden(true);
      document.getElementById('searchForm').reset();
      });
  }

  /**
   * Specific search management: when user clicks on an autocomplete suggestion
   * @param {String} elementString The string corresponding to the selected suggestion.
   */ 
  function specificSearch(elementString) {
    //const element = JSON.parse(event.target.getAttribute('element')).autocomplete;
    const element = JSON.parse(elementString).autocomplete;
    const id = element.id;
    const elementType = element.Tipo.toLowerCase();

    let currElem = null;

    if (elementType !== "limite amministrativo") {
      // Store current element
      fetch(window.SERVER_MAIN_PATH + "/info/" + elementType + "/" + id + "?language=" + lang, {
        method: 'GET',
      })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(
        ([statusCode, result]) => {
          if (statusCode === 200) {
            if (props.setEvolutionValue)
              props.setEvolutionValue(-1);
            dispatch({
              type: "CHANGE_CURRENT_ELEMENT",
              payload: {data: result, isLoaded: true, messageError: null}
            });
            currElem = result;
            routeToMap(id, elementType.toLowerCase());
          } 
          else if (statusCode !== 200 && result) {
            dispatch({
              type: "CHANGE_CURRENT_ELEMENT",
              payload: {data: null, isLoaded: false, messageError: result.message}
            });
          }
        },
        (error) => {
          console.log(error);
        }
      )

      // Get related search
      fetch(window.SERVER_MAIN_PATH + "/search/within?id=" + id + "&type=" + elementType + "&language=" + lang, {
        method: 'GET',
      })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(
        ([statusCode, result]) => {
          if (statusCode === 200) {
            if (props.setEvolutionValue)
              props.setEvolutionValue(-1);
            
            // Add currentElement complesso 
            let provList = result.Results;
            if (elementType.toLowerCase() === "monumento") {
              for (let i = 0; i < currElem.Monumento.Complessi.length; i++) {
                const element = currElem.Monumento.Complessi[i].ComplessoLight;
                if (element.GAP !== null) {
                  provList.push({SearchResult: {ID: element.ID, OGTN: element.OGTN, Coordinate: element.GAP, Tipo: "Complesso"}});
                }
              }
            }

            dispatch({
              type: "CHANGE_SEARCHED_ELEMENTS",
              payload: {data: result.Results, isLoaded: true, messageError: null}
            });
          } 
          else if (statusCode !== 200 && result) {
            dispatch({
              type: "CHANGE_SEARCHED_ELEMENTS",
              payload: {data: [], isLoaded: false, messageError: result.message}
            });
          }
        },
        (error) => {
          console.log(error);
        }
      )
    } else {
      fetch(window.SERVER_MAIN_PATH + "/search/adminlimits?id=" + id + "&language=" + lang, {
        method: 'GET',
      })
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(
        ([statusCode, result]) => {
          if (statusCode === 200) {
            if (props.setEvolutionValue)
              props.setEvolutionValue(-1);
            dispatch({
              type: "CHANGE_SEARCHED_ELEMENTS",
              payload: {data: result.Results, isLoaded: true, messageError: null}
            });
            dispatch({
              type: "CHANGE_CURRENT_ELEMENT",
              payload: {data: null, isLoaded: false, messageError: null}
            });
            // It is not a specific search so the current element
            // is selected randomly
            let randomIndex = Math.round(Math.random() * (result.Results.length - 1));
            routeToMap(result.Results[randomIndex].SearchResult.ID, result.Results[randomIndex].SearchResult.Tipo.toLowerCase())
          } 
          else if (statusCode !== 200 && result) {
            var elem = document.getElementById("adminLimitMessage");
            let classes = elem.classList;
            classes.remove("scale-0");
            classes.add("scale-100");
            setTimeout(function(){ classes.remove("scale-100"); classes.add("scale-0"); }, 3000);
          }
        },
        (error) => {
          console.log(error);
        }
      )
    }

    setAutocompletionsHidden(true);
    document.getElementById('searchForm').reset();
  }

}

export default SearchBar;