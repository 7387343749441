// This file contains functions to retrieve information from the database

/**
 * This function searches for an archeological element in the database, returning partial information.
 * @param {Int} id The ID of the element;
 * @param {String} type The typology of the element;
 * @param {String} lang The current language;
 * @returns The desired element or null if not found.
 */
async function getElementByID(id, type, lang) {
  let element;

  await fetch(window.SERVER_MAIN_PATH + "/search/id?id=" + id + "&type=" + type + "&language=" + lang, { method: 'GET' })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, result]) => {
      if (statusCode === 200)
        element =  result.SearchResult;
      else if (result)
        element = null
    },
    (error) => {
      console.log(error);
    });
  
  return element;
}

/**
 * This function searches for an epoch in the database.
 * @param {Int} id The ID of the epoch;
 * @param {String} lang The current language;
 * @returns The desired epoch or null if not found.
 */
async function getEpochByID(id, lang) {
  let element;
  
  await fetch(window.SERVER_MAIN_PATH + "/info/epoca/" + id + "?language=" + lang, { method: 'GET' })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, result]) => {
      if (statusCode === 200)
        element = result.Epoca;
      else if (result)
        element = null;
    },
    (error) => {
      console.log(error);
    });
  
  return element;
}

/**
 * This function searches for a Fonte Bibliografica in the database.
 * @param {Int} id The ID of the Fonte Bibliografica;
 * @param {String} lang The current language;
 * @returns The desired Fonte Bibliografica or null if not found.
 */
async function getFonteBibliograficaByID(id, lang) {
  let element;

  await fetch(window.SERVER_MAIN_PATH + "/info/fontebibliografica/" + id + "?language=" + lang, { method: 'GET' })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, result]) => {
      if (statusCode === 200)
        element =  result.FonteBibliografica;
      else if (result)
        element = null;
    },
    (error) => {
      console.log(error);
    });
  
  return element;
}

/**
 * This function searches if an archeological element already exists in the database.
 * @param {Int} id The ID of the element;
 * @param {String} type The typology of the element;
 * @returns A boolean value indicating the existance of the element.
 */
async function getExistence(id, type) {
  let existence = false;

  await fetch(window.SERVER_MAIN_PATH + "/utility/existence?id=" + id + "&type=" + type, { method: 'GET' })
    .then(res => {
      const statusCode = res.status;
      return Promise.all([statusCode]);
    })
    .then(([statusCode]) => {
      if (statusCode === 200)
        existence = true;
    },
    (error) => {
      console.log(error);
    })
  
  return existence;
}

/**
 * This function searches for an archeological element in the database, returning all the available information.
 * @param {Int} id The ID of the element;
 * @param {String} type The typology of the element;
 * @param {String} lang The current language;
 * @returns The desired element or null if not found.
 */
async function getElement(id, type, lang) {
  let element;

  await fetch(window.SERVER_MAIN_PATH + "/info/" + type + "/" + id + "?language=" + lang, { method: 'GET' })
  .then(res => {
    const statusCode = res.status;
    const data = res.json();
    return Promise.all([statusCode, data]);
  })
  .then(([statusCode, result]) => {
    if (statusCode === 200)
      element = result;
    else if (statusCode !== 200 && result)
      element = null
  },
  (error) => {
    console.log(error);
  })
  
  return element;
}

/**
 * This function searches for a list of elements of different typologies in the database.
 * @param {String} type The typology of the desired element;
 * @param {String} lang The current language;
 * @returns The desired element or null if not found.
 */
async function getElementList(type, lang) {
  let element;

  await fetch(window.SERVER_MAIN_PATH + "/utility/list?type=" + type + "&language=" + lang, { method: 'GET' })
    .then(res => {
      const statusCode = res.status;
      const data = res.json();
      return Promise.all([statusCode, data]);
    })
    .then(([statusCode, result]) => {
      if (statusCode === 200)
        element =  result;
      else if (result)
        element = null;
    },
    (error) => {
      console.log(error);
    })
  
  return element;
}

export { getElementByID, getEpochByID, getFonteBibliograficaByID, getExistence, getElement, getElementList };