// npm
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Custom components
import ElementInsert from './elementInsert.js'
import TranslationInsert from './translationInsert.js'
import EpochInsert from './epochInsert.js';
import BibInsert from './bibInsert.js';
import Model3DInsert from './model3DInsert.js';
import AdminLimitsInsert from './adminLimitsInsert.js';

// Stylesheet
import './dataInsert.css';

// Assets
import darkLogo from '../assets/imgs/darkLogo.png';


/**
 * This component renders the page to insert new data in the application database.
 * @param {*} props React props.
 * @returns The React component
 */
const DataInsert = (props) => {
  // Default dark mode
  document.querySelector('html').classList.add('dark');

  // For now, set default language to ITA
  // const store = useSelector(state => state);
  const dispatch = useDispatch();
  dispatch({type: "CHANGE_LANG", payload: "ITA"});
  // const lang = store.lang;

  // React state
  const [elementInsertHidden, setElementInsertHidden] = useState(true);
  const [translationInsertHidden, setTranslationInsertHidden] = useState(true);
  const [epochInsertHidden, setEpochInsertHidden] = useState(true);
  const [bibInsertHidden, setBibInsertHidden] = useState(true);
  const [model3DInsertHidden, setModel3DInsertHidden] = useState(true);
  const [adminLimitsInsertHidden, setAdminLimitsInsertHidden] = useState(true);
  const [insertCount, setInsertCount] = useState(0); // Used to update the selectors after a new insert

  // Title page
  useEffect(() => {
    document.title = "Inserimento dati"
  }, []);

  return (
    <div className="relative flex flex-col text-white h-full py-8">
      {/* CArD 3D logo */}
      <div className="flex justify-center">
        <div className="w-1/3">
            <img src={darkLogo} alt="CArD 3D light logo" />
            <p className="mt-4 text-3xl">Inserimento Dati</p>
        </div>
      </div>

      <p className="text-left font-bold text-orange ml-8 cursor-pointer" onClick={handlerSetElementInsertHidden}>
        {elementInsertHidden ? ">" : "⌄"} NUOVO ELEMENTO
      </p>
      {!elementInsertHidden && <ElementInsert insertCount={insertCount} setInsertCount={setInsertCount} />}

      <p className="text-left font-bold text-orange ml-8 cursor-pointer" onClick={handlerSetTranslationInsertHidden}>
        {translationInsertHidden ? ">" : "⌄"} NUOVA TRADUZIONE ELEMENTO
      </p>
      {!translationInsertHidden && <TranslationInsert insertCount={insertCount} setInsertCount={setInsertCount} />}

      <p className="text-left font-bold text-orange ml-8 cursor-pointer" onClick={handlerSetEpochInsertHidden}>
        {epochInsertHidden ? ">" : "⌄"} NUOVA EPOCA
      </p>
      {!epochInsertHidden && <EpochInsert insertCount={insertCount} setInsertCount={setInsertCount} />}

      <p className="text-left font-bold text-orange ml-8 cursor-pointer" onClick={handlerSetBibInsertHidden}>
        {bibInsertHidden ? ">" : "⌄"} NUOVA FONTE BIBLIOGRAFICA
      </p>
      {!bibInsertHidden && <BibInsert insertCount={insertCount} setInsertCount={setInsertCount} />}

      <p className="text-left font-bold text-orange ml-8 cursor-pointer" onClick={handlerSetModel3DInsertHidden}>
        {model3DInsertHidden ? ">" : "⌄"} NUOVO MODELLO 3D
      </p>
      {!model3DInsertHidden && <Model3DInsert insertCount={insertCount} setInsertCount={setInsertCount} />}

      <p className="text-left font-bold text-orange ml-8 cursor-pointer" onClick={handlerSetAdminLimitsHidden}>
        {adminLimitsInsertHidden ? ">" : "⌄"} NUOVI LIMITI AMMINISTRATIVI
      </p>
      {!adminLimitsInsertHidden && <AdminLimitsInsert insertCount={insertCount} setInsertCount={setInsertCount} />}

    </div>
  )


  // ****************************************** HANDLERS ******************************************

  /**
   * This function opens and closes the element insert section
   */
  function handlerSetElementInsertHidden() {
    setElementInsertHidden(!elementInsertHidden);
  }

  /**
   * This function opens and closes the translation insert section
   */
  function handlerSetTranslationInsertHidden() {
    setTranslationInsertHidden(!translationInsertHidden);
  }

  /**
   * This function opens and closes the epoch insert section
   */
  function handlerSetEpochInsertHidden() {
    setEpochInsertHidden(!epochInsertHidden);
  }

  /**
   * This function opens and closes the bibliographical insert section
   */
  function handlerSetBibInsertHidden() {
    setBibInsertHidden(!bibInsertHidden);
  }

  /**
   * This function opens and closes the 3D model insert section
   */
  function handlerSetModel3DInsertHidden() {
    setModel3DInsertHidden(!model3DInsertHidden);
  }

  /**
   * This function opens and closes the administration limits insert section
   */
  function handlerSetAdminLimitsHidden() {
    setAdminLimitsInsertHidden(!adminLimitsInsertHidden);
  }

}

export default DataInsert;