// npm
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


/**
 * This component renders a banner at the bottom of the page for the cookie policy.
 * @param {*} props React props.
 * @returns The React component.
 */
const Banner = (props) => {
  var strCookieName = "cookie-compliance";
  var strApprovedVal = "approved";

  useEffect(() => {
    var cookieVal = readCookie(strCookieName);
    var displayMsg = document.getElementById('cookieMessageWrapper');
    if (cookieVal !== strApprovedVal)
      setTimeout(function() { displayMsg.classList.remove("hidden"); }, 200);
  });

  return (
    <div id="cookieMessageWrapper" className="flex justify-center fixed bottom-0 w-full py-1 bg-orange text-sm hidden z-50">
      <div id="cookieMessage" className="flex flex-row items-center mx-2">
        <div>
          <span className="font-extrabold">Questo sito utlizza cookie.</span>
          Può leggere come li usiamo nella nostra <Link className="font-bold" to="/policy">Privacy Policy</Link>.
        </div>
        <div id="cookieClose" className="px-1 mx-4 cursor-pointer border-2 border-dark rounded-md bg-dark font-bold text-orange hover:bg-white hover:opacity-80 hover:border-white hover:text-dark" onClick={clickClose}>Close</div>
      </div>
    </div>
  );

  // Cookie functions
  function createCookie(name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    }
    else expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function clickClose() {
    var displayMsg = document.getElementById('cookieMessageWrapper');
    setTimeout(function() {displayMsg.style.visibility = "hidden";}, 200);
    createCookie(strCookieName, strApprovedVal, 365);
  }
}

export default Banner