// npm
import React from 'react';
// Stylesheet
import './tile.css';


/**
 * This class renders the Tile component, which shows an SVG with a short title
 * and, when hovered, it turns to show a brief description.
 * It requires the following React properties:
 * - pathD1: the first path of the desired SVG;
 * - pathD2: the optional second path of the desired SVG;
 * - text: the brief description;
 * - title: the title.
 */
class Tile extends React.Component {
  render() {
    return (
      <li className="relative card col-span-1 mx-4 my-4 h-40">
        <div className="front absolute flex flex-col items-center justify-center rounded-3xl bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5 h-full w-full transition duration-500">
          <svg className="w-10 mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={this.props.pathD1} />
            {this.props.pathD2 ? <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={this.props.pathD2} /> : null}
          </svg>
          <p className="text-xl px-4">
            {this.props.title}
          </p>
        </div>
        <div className="back absolute flex flex-col items-center justify-center rounded-3xl bg-dark dark:bg-white text-white dark:text-dark w-full h-full transition duration-300">
          <p className="text-base mx-4">
            {this.props.text}
          </p>
        </div>
      </li>
    )
  }
}

export default Tile;
