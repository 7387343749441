// npm
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Custom components
import LanguageSelector from './languageSelector.js';
import Toggle from './toggle.js';

// Assets
import logo_facebook from '../assets/imgs/logo_facebook.png';

// Translations
import staticTranslation from '../utils/JSON/staticTranslation.json'


/**
 * This component renders the settings bar called in desktop and mobile versions by clicking on the menu button
 * @param {*} props React props, namely:
 * - toggleEnabled: boolean value to indicate if the dark mode is active;
 * - toggleOnChange: the function to handle the dark mode;
 * - languageEnabled: boolean value to indicate if the language selector is enabled in the current page;
 * - proMode: boolean value to indicate if the proMode has been activated by the user;
 * - showCredits: boolean value to show the Freepik credits.
 * @returns The React component.
 */
const SettingsBar = (props) =>  {

  // Redux
  const store = useSelector(state => state)
  const lang = store.lang

  const translations = staticTranslation[lang].footer

  return (
    <div className={"z-30 fixed lg:inset-x-0 lg:bottom-0 overflow-y-scroll flex flex-col items-center w-full lg:w-4/12 h-100v lg:h-100v bg-white bg-opacity-80 backdrop-blurxl dark:bg-dark dark:bg-opacity-80 transition duration-500 ease-in-out " + (props.settingsbarIsOpen ? "" : "transform -translate-y-full mb-40")}>
      {/* Empty ontainer under searchbar */}
      <div className="h-10v" />
      <div className="flex flex-col h-90v w-full">
        <div className="flex flex-row my-10 md:my-20 lg:my-8 2xl:my-12">
          <div className="w-1/2 flex justify-center">
            <Toggle enabled={props.toggleEnabled} onChange={props.toggleOnChange} />
          </div>
          <div className="w-1/2 flex justify-center focus:outline-none">
            <LanguageSelector languageEnabled={props.languageEnabled} arrowColor={props.toggleEnabled ? "white" : "dark"} />
          </div>
        </div>
        {props.setProMode ?
          <div className="flex justify-center py-2">
            <button className={"flex w-2/3 py-2 flex-row items-center justify-center font-raleway font-bold transition-all duration-300 transform hover:scale-110 rounded-full " + (props.proMode ? "bg-orange text-dark" : "bg-dark bg-opacity-10 hover:bg-dark hover:text-white dark:bg-white dark:bg-opacity-10 dark:hover:bg-white dark:hover:bg-opacity-95 dark:hover:text-dark")} onClick={() => props.setProMode(!props.proMode)}>
              <span>
                Modalità Esperto
              </span>
              <svg  className="h-6 w-6 ml-1 transition-all duration-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke={props.proMode ? "white" : "orange"}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
              </svg>
            </button>
          </div>
        : null}
        <div className="flex flex-col items-center flex-grow md:my-20 lg:my-8 2xl:my-12">
          <a className="mx-8 my-4 cursor-pointer transform hover:scale-125 duration-300" href="https://facebook.com/CArD3D.Archeologia" target="_blank" rel="noreferrer">
            <img src={logo_facebook} className="h-8 rounded-lg" alt="CArD 3D light logo" />
          </a>
          <Link to="/project" className="mx-2 px-2 my-2 py-2 text-xl hover:text-orange duration-300">
            <p>
              {translations.project}
            </p>
          </Link>
          <Link to="/about" className="mx-2 px-2 my-2 py-2 text-xl hover:text-orange duration-300">
            <p>
              {translations.collaborations}
            </p>
          </Link>
          <Link to="/team" className="mx-2 px-2 my-2 py-2 text-xl hover:text-orange duration-300">
            <p>
              {translations.team}
            </p>
          </Link>
          <Link to="/policy" className="mx-2 px-2 my-2 py-2 text-xl hover:text-orange duration-300">
            <p>
              Privacy Policy
            </p>
          </Link>
          <a href="mailto:info.card3d@gmail.com?subject=Informazioni%20CArD%203D" className="flex items-center justify-center h-8 w-8 mx-8 my-4 bg-bluemail rounded-lg cursor-pointer transform hover:scale-125 duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
            </svg>
          </a>
        </div>
        {/** Show Evolution slider icons credits */
        props.showCredits ? 
          <div className="text-xs py-2 px-2 lg:bg-dark lg:bg-opacity-5 lg:dark:bg-white lg:dark:bg-opacity-5">
            Le icone della sezione Evoluzione sono di <a href="https://www.freepik.com" title="Freepik">Freepik</a>, da <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          </div>
        : null}
      </div>
    </div>
  )
}

export default SettingsBar;

/*<div className="h-15v sm:h-20v lg:h-10v">
      </div>
      <div className="h-85v sm:h-80v lg:h-90v w-full"> */