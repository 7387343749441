// npm
import React from 'react';
import ReactDOM from 'react-dom';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
// Custom components
import App from './App';
import reportWebVitals from './reportWebVitals';
// Stylesheet
import './index.css';
// import TempPage from './TempPage';

// Key of local storage
const STORE_KEY = 'card3d';

// Main path for the ajax calls to the server
//window.SERVER_MAIN_PATH = "https://server.card3d.org/CArD3D-1.00";
window.SERVER_MAIN_PATH = "https://server.card3d.beniculturali.unipd.it/CArD3D-1.00";
//window.SERVER_MAIN_PATH = "http://localhost:8080/CArD3D-1.00";
//window.SERVER_MAIN_PATH = "http://192.168.1.109:8080/CArD3D-1.00"; // CASA PADO
//window.SERVER_MAIN_PATH = "http://192.168.1.202:8080/CArD3D-1.00"; // CASA NED

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STORE_KEY, serializedState);
  } catch {
    // ignore write errors
  }
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STORE_KEY);
    if (serializedState === null)
      return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Reducer to change the language
const langReducer = (state = "ITA", action) => {
  if (action.type === "CHANGE_LANG")
    return action.payload;
  return state;
}
// Reducer to change the theme
const themeReducer = (state = "LIGHT", action) => {
  if (action.type === "CHANGE_THEME")
    return action.payload;
  return state;
}
// Reducer to change currentElement
const currentElementReducer = (state = {data: null, isLoaded: false, messageError: null}, action) => {
  if (action.type === "CHANGE_CURRENT_ELEMENT")
    return action.payload;
  return state;
}
// Reducer to change searchedElements
const searchedElementsReducer = (state = {data: [], isLoaded: false, messageError: null}, action) => {
  if (action.type === "CHANGE_SEARCHED_ELEMENTS")
    return action.payload;
  return state;
}

// Create store or load an existing one
const persistentState = loadState();
const store = createStore(combineReducers({
  lang: langReducer,
  theme: themeReducer,
  currentElement: currentElementReducer,
  searchedElements: searchedElementsReducer
}), persistentState);

// Write state
store.subscribe(()=>{
  saveState(store.getState());
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      {/* <TempPage /> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
