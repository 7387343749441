// npm
import {React, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
// Custom components
import SettingsBar from '../components/settingsBar.js';
import Toggle from '../components/toggle.js';
import LanguageSelector from '../components/languageSelector.js';
// Assets
import darkLogo from '../assets/imgs/darkLogo.png';
import lightLogo from '../assets/imgs/lightLogo.png';


/**
 * This component renders the cookie policy information page.
 * @param {*} props React props, namely:
 * - darkOnChange: function to handle the dark mode toggle;
 * - isDark: boolean variable to check if the dark mode is active.
 * @returns The React component.
 */
const Policy = (props) => {
  // React state
  const [mobileSidebarIsOpen] = useState(false);
  
  /**
   * This effect updates the page title.
   */
  useEffect(() => {
    document.title = "CArD 3D - Privacy Policy"
  }, []);

  return (
    <div className="flex flex-col items-center md:h-screen">
      <SettingsBar toggleEnabled={props.isDark} toggleOnChange={props.darkOnChange} settingsbarIsOpen={mobileSidebarIsOpen} />
      <div className="hidden w-4/5 h-20vh lg:flex justify-between">
        <div className="z-50 flex w-1/4 justify-center align-center">
          <Toggle enabled={props.isDark} onChange={props.darkOnChange} />
        </div>
        <div className="w-1/4 flex items-center z-50">
          <Link to="/">
            <img className="transform hover:scale-110 duration-300" src={props.isDark ? darkLogo : lightLogo} alt="CArD 3D light logo"/>
          </Link>
        </div>
        <div className="z-50 flex w-1/4 justify-center align-center">
          <LanguageSelector arrowColor={props.isDark? "white" : "dark"} />
        </div>
      </div>
      <h1 className="font-cinzel text-2xl md:text-5xl mb-8 mt-24 lg:mt-8 mx-4">
        Privacy Policy e trattamento dei dati personali
      </h1>
      <div className="w-4/5 lg:w-3/5 text-justify lg:text-lg">
        <p>
          Informativa resa ai sensi dell'articolo 13 del D.lgs. n.196/2003 ai visitatori del sito Internet
          <Link className="text-orange font-bold" to="/">card3d.org</Link> e fruitori dei servizi offerti dallo stesso,
          a partire dall'indirizzo <Link className="text-orange font-bold" to="/">card3d.org</Link>,
          con l'esclusione dei link esterni.
        </p>
        <br />
        <p>I dati raccolti attraverso questo sito sono:</p>
        <ul className="list-inside list-disc ml-2">
          <li>Dati forniti volontariamente dall'utente;</li>
          <li>L'invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito comporta la successiva acquisizione dell'indirizzo del mittente, necessario per rispondere alle richieste, nonchè degli eventuali altri dati personali inseriti nella missiva;</li>
          <li>Facciamo uso di cookie per il corretto funzionamento di Google Analytics al fine di raccogliere informazioni statistiche e non a fini pubblicitari.</li>
        </ul>
        <br />
        <p>
          Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale.
          Ricordiamo che l'utente, tramite configurazione del proprio browser, può in qualsiasi momento disabilitare l'operatività dei cookie o essere informato nel momento in cui riceve il cookies e negare il consenso all'invio.
        </p>
      </div>
    </div>
  )
}

export default Policy;