// npm
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Custom functions
import { getElement, getElementList } from './utils';

// Skybox
import './dataInsert.css';


/**
 * This component renders the form to insert into the database a new translation
 * @param {*} props React props, namely:
 * - insertCount: the counter of the new insertions during the current session;
 * - setInsertCount: the function to update insertCount.
 * @returns The React component
 */
const TranslationInsert = (props) => {
  
  // Redux
  const store = useSelector(state => state);
  const lang = store.lang;
  // React state
  const [typeState, setTypeState] = useState("sito");
  const [elementID, setElementID] = useState(null);
  const [translationLang, setTranslationLang] = useState("ITA");
  const [elementCurrentState, setElementCurrentState] = useState(null);
  // Selector state
  const [elementsSelectorList, setElementsSelectorList] = useState(null);

  /**
   * This effect is called when a new insert has been submitted, to update the displayed information.
   */
  useEffect(() => {
    async function fetchData() {
      setElementsSelectorList(await getElementList(typeState, lang));
    }
    fetchData();
  }, [typeState, lang, props.insertCount])
 
  return (
    <div className="pb-20">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mx-8 my-4">
        {/* Type */}
        <div className="flex justify-end items-center col-span-2">
          <p className="mx-2">Seleziona il tipo di elemento:</p>
          <select name="type" value={typeState} onChange={typeSelectorHandler} onClick={typeSelectorHandler} className="w-36 bg-white text-dark">
            <option value="sito">Sito</option>
            <option value="complesso">Complesso</option>
            <option value="monumento">Monumento</option>
          </select>
        </div>
        {/* Element ID */}
        <div className="flex justify-end items-center">
          <label className="pr-2">Elemento: </label>
          <select className="w-60 bg-white text-dark" value={(elementID ? String(elementID) : "-1") || "-1"} id="elementid" name="elementid" onChange={handlerElementIDInput} onClick={handlerElementIDInput}>
            <option value="-1">Selezionare un elemento</option>
            {elementsSelectorList ?
              elementsSelectorList.List.map((element, index) => {
                return (
                  <option key={index} value={element[Object.keys(element)[0]].ID}>
                    {element[Object.keys(element)[0]].OGTN ? element[Object.keys(element)[0]].OGTN : element[Object.keys(element)[0]].ID}
                  </option>
                )
              })
            : null}
          </select>
        </div>
        {/* Language */}
        <div className="flex justify-end items-center">
          <p className="mx-2">Seleziona la lingua:</p>
          <select id="translationlang" name="translationlang" value={translationLang} onChange={translationLangSelectorHandler} onClick={translationLangSelectorHandler} className="w-36 bg-white text-dark">
            <option value="ITA">Italiano</option>
            <option value="ENG">Inglese</option>
            <option value="DEU">Tedesco</option>
          </select>
        </div>
      </div>
      {/* Form */}
      <form className="flex flex-col mx-4" id="NewTranslationForm">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mx-8 my-4">
          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              OGTN<span className="text-orange font-bold font-cinzel">*</span>: <span className="tooltiptext">Denominazione.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="ogtn" name="ogtn" defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].OGTN : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              OGTD: <span className="tooltiptext">Definizione.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="ogtd" name="ogtd" defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].OGTD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              OGTT: <span className="tooltiptext">Precisazione tipologica.</span>
            </label>
            <input className="text-dark px- w-full" type="text" id="ogtt" name="ogtt" disabled={(typeState !== "sito") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].OGTT : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              OGTY: <span className="tooltiptext">Denominazione tradizionale e/o storica.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="ogty" name="ogty" disabled={(typeState !== "sito") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].OGTY : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              OGTC: <span className="tooltiptext">Categoria di appartenza.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="ogtc" name="ogtc" disabled={(typeState === "sito") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].OGTC : ""} />
          </div>

          <div className="flex justify-end items-center col-span-2">
            <label className="pr-2 tooltip">
              CASD: <span className="tooltiptext">Descrizione caratteri ambientali storici.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="casd" name="casd" disabled={(typeState !== "sito") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].CASD: ""} />
          </div>

          <div className="flex justify-end items-center col-span-2">
            <label className="pr-2 tooltip">
              OSS: <span className="tooltiptext">Osservazioni.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="oss" name="oss" defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].OSS : ""} />
          </div>

          <div className="flex justify-end items-center col-span-2">
            <label className="pr-2 tooltip">
              DESO: <span className="tooltiptext">Descrizione dei dati analitici.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="deso" name="deso" defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].DESO : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              DTSI: <span className="tooltiptext">Periodo di inizio vita.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="dtsi" name="dtsi" disabled={(typeState === "sito") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].DTSI : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              DTSF: <span className="tooltiptext">Periodo di fine vita.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="dtsf" name="dtsf" disabled={(typeState === "sito") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].DTSF : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SCAD: <span className="tooltiptext">Descrizione delle scale.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="scad" name="scad" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SCAD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              RES: <span className="tooltiptext">Specifiche di reperimento.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="res" name="res" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].RES : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              FNST: <span className="tooltiptext">Tipologia delle fondazioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="fnst" name="fnst" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].FNST : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              FNSM: <span className="tooltiptext">Materiale impiegato nelle fondazioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="fnsm" name="fnsm" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].FNSM : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              FNSC: <span className="tooltiptext">Tecnica costruttiva.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="fnsc" name="fnsc" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].FNSC : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              FNSD: <span className="tooltiptext">Descrizione delle fondazioni.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="fnsd" name="fnsd" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].FNSD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ELEN: <span className="tooltiptext">Definizione degli elevati.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="elen" name="elen" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ELEN : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ELET: <span className="tooltiptext">Tipo di tecnica usata negli elevati.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="elet" name="elet" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ELET : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ELED: <span className="tooltiptext">Descrizione degli elevati.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="eled" name="eled" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ELED : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ELEX: <span className="tooltiptext">Componenti del materiale edilizio costituente.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="elex" name="elex" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ELEX : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ELEL: <span className="tooltiptext">Componenti del materiale edilizio legante.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="elel" name="elel" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ELEL : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ELEM: <span className="tooltiptext">Descrizione del paramento.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="elem" name="elem" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ELEM : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SOLD: <span className="tooltiptext">Descizione dei solai e delle strutture orizzontali.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="sold" name="sold" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SOLD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SOLO: <span className="tooltiptext">Tipologia dei solai.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="solo" name="solo" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SOLO : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SOLE: <span className="tooltiptext">Configurazione esterna dei solai.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="sole" name="sole" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SOLE : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SOLI: <span className="tooltiptext">Configurazione interna dei solai.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="soli" name="soli" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SOLI : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              CPUT: <span className="tooltiptext">Tipologia delle coperture.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="cput" name="cput" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].CPUT : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              CPUE: <span className="tooltiptext">Configurazione esterna delle coperture.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="cpue" name="cpue" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].CPUE : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              CPUI: <span className="tooltiptext">Configurazione interna delle coperture.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="cpui" name="cpui" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].CPUI : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              CPUD: <span className="tooltiptext">Descrizione delle coperture.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="cpud" name="cpud" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].CPUD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SPAT: <span className="tooltiptext">Tipologia degli spazi aperti.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="spat" name="spat" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SPAT : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SPAC: <span className="tooltiptext">Tecnica degli spazi aperti.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="spac" name="spac" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SPAC : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              SPAD: <span className="tooltiptext">Descrizione degli spazi aperti.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="spad" name="spad" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].SPAD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ISRC: <span className="tooltiptext">Classe di appartenenza delle iscrizioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="isrc" name="isrc" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ISRC : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ISRL: <span className="tooltiptext">Lingua delle iscrizioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="isrl" name="isrl" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ISRL : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ISRP: <span className="tooltiptext">Posizione delle iscrizioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="isrp" name="isrp" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ISRP : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ISRI: <span className="tooltiptext">Trascrizione delle iscrizioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="isri" name="isri" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ISRI : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ISRM: <span className="tooltiptext">Misure delle iscrizioni.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="isrm" name="isrm" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ISRM : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              ISRD: <span className="tooltiptext">Descrizione delle iscrizioni.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="isrd" name="isrd" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].ISRD : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              RIVM: <span className="tooltiptext">Materiale dei rivestimenti / elementi di pregio.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="rivm" name="rivm" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].RIVM : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              RIVT: <span className="tooltiptext">Tipologia dei rivestimenti / elementi di pregio.</span>
            </label>
            <input className="text-dark px-1 w-full" type="text" id="rivt" name="rivt" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].RIVT : ""} />
          </div>

          <div className="flex justify-end items-center">
            <label className="pr-2 tooltip">
              RIVD: <span className="tooltiptext">Descrizione dei rivestimenti / elementi di pregio.</span>
            </label>
            <textarea className="text-dark px-1 w-full" type="text" id="rivd" name="rivd" disabled={(typeState !== "monumento") ? "disabled" : null} defaultValue={elementCurrentState ? elementCurrentState[Object.keys(elementCurrentState)[0]].RIVD : ""} />
          </div>
        </div>

        {/* Submit */}
        <div className="flex justify-end mx-8">
          <button className="w-60 text-dark rounded-full mt-10 bg-white" onClick={handlerSubmitForm}>{"Crea nuovo " + typeState}</button>
        </div>
      </form>
    </div>
  )


  // ****************************************** HANDLERS ******************************************

  /**
   * This function handles the change in the typology
   * @param {*} event The onChange in the type selector
   */
  async function typeSelectorHandler(event) {
    setTypeState(event.target.value);
    setElementID(null);
    setElementCurrentState(null);
    setTranslationLang("ITA");
    document.forms.NewTranslationForm.reset();
  }

  /**
   * This function handles the change in the ID input field.
   * @param {*} event The onClick or onChange event in the input field.
   */
  async function handlerElementIDInput(event) {
    const currentID = parseInt(event.target.value);
    document.forms.NewTranslationForm.reset();
    if (currentID !== -1 && currentID !== null) {
      setElementID(currentID);
      setElementCurrentState(await getElement(currentID, typeState, translationLang));
    } else {
      setElementID(null);
      setTranslationLang("ITA");
      setElementCurrentState(null);
    }
  }

  /**
   * This function handles the change in the language of the new epoch.
   * @param {*} event The onChange in the type selector
   */
  async function translationLangSelectorHandler(event) {
    let language = event.target.value;
    setTranslationLang(language);
    if (elementID !== null) {
      await document.forms.NewTranslationForm.reset();
      let element = await getElement(elementID, typeState, language);
      setElementCurrentState(element);
    }
  }

  /**
   * This handler submits the inserted data to the database server.
   * @param {*} event The onClick event that confirms the submission
   */
  function handlerSubmitForm(event) {
    event.preventDefault();

    // Get form
    var formEl = document.forms.NewTranslationForm;
    var formData = new FormData(formEl);

    // Get type
    // let type = formData.get('type');
    let type = typeState;
    let firstKey = type.charAt(0).toUpperCase() + type.slice(1) + "Traduzione";
    let currentElementKey = type.charAt(0).toUpperCase() + type.slice(1);

    // Final element
    let finalJSON = {};
    finalJSON[firstKey] = {};
    
    // ID 
    let idkey = "ID" + type.charAt(0).toUpperCase() + type.slice(1);
    var id = elementID;
    if (id === null || isNaN(id)) {
      alert("Inserire un ID valido");
      return;
    }
    finalJSON[firstKey][idkey] = parseInt(id);

    // var lang = formData.get('translationlang');
    var lang = translationLang;
    finalJSON[firstKey].Lingua = lang;

    var ogtn = formData.get('ogtn');
    if (ogtn !== "")
      finalJSON[firstKey].OGTN = ogtn;
    else if (!elementCurrentState || !elementCurrentState[currentElementKey].OGTN)
      finalJSON[firstKey].OGTN = null;
    else
      finalJSON[firstKey].OGTN = elementCurrentState[currentElementKey].OGTN;
    if (finalJSON[firstKey].OGTN === null) { // It's mandatory
      alert("OGTN è un valore obbligatorio");
      return;
    } 
    
    var ogtd = formData.get('ogtd');
    if (ogtd !== "")
      finalJSON[firstKey].OGTD = ogtd;
    else if (!elementCurrentState || !elementCurrentState[currentElementKey].OGTD)
      finalJSON[firstKey].OGTD = null;
    else
      finalJSON[firstKey].OGTD = elementCurrentState[currentElementKey].OGTD;

    var ogtt = formData.get('ogtt');
    if (ogtt !== null)
      if (ogtt !== "")
        finalJSON[firstKey].OGTT = ogtt;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].OGTT)
        finalJSON[firstKey].OGTT = null;
      else
        finalJSON[firstKey].OGTT = elementCurrentState[currentElementKey].OGTT;

    var ogty = formData.get('ogty');
    if (ogty !== null)
      if (ogty !== "")
        finalJSON[firstKey].OGTY = ogty;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].OGTY)
        finalJSON[firstKey].OGTY = null;
      else
        finalJSON[firstKey].OGTY = elementCurrentState[currentElementKey].OGTY;

    var ogtc = formData.get('ogtc');
    if (ogtc !== null)
      if (ogtc !== "")
        finalJSON[firstKey].OGTC = ogtc;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].OGTC)
        finalJSON[firstKey].OGTC = null;
      else
        finalJSON[firstKey].OGTC = elementCurrentState[currentElementKey].OGTC;

    var casd = formData.get('casd');
    if (casd !== null)
      if (casd !== "")
        finalJSON[firstKey].CASD = casd;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].CASD)
        finalJSON[firstKey].CASD = null;
      else
        finalJSON[firstKey].CASD = elementCurrentState[currentElementKey].CASD;

    var oss = formData.get('oss');
    if (oss !== null)
      if (oss.length > 1000) {
        alert('OSS deve avere meno di 1000 caratteri.');
        return;
      } else if (oss !== "")
          finalJSON[firstKey].OSS = oss;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].OSS)
          finalJSON[firstKey].OSS = null;
        else
          finalJSON[firstKey].OSS = elementCurrentState[currentElementKey].OSS;

    var deso = formData.get('deso');
    if (deso !== null)
      if (deso.length > 1000 && !type === 'sito') {
        alert('DESO deve avere meno di 1000 caratteri.');
        return;
      } else if (deso !== "")
          finalJSON[firstKey].DESO = deso;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].DESO)
          finalJSON[firstKey].DESO = null;
        else
          finalJSON[firstKey].DESO = elementCurrentState[currentElementKey].DESO;

    var dtsi = formData.get('dtsi');
    if (dtsi !== null)
      if (dtsi !== "")
        finalJSON[firstKey].DTSI = dtsi;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].DTSI)
        finalJSON[firstKey].DTSI = null;
      else
        finalJSON[firstKey].DTSI = elementCurrentState[currentElementKey].DTSI;

    var dtsf = formData.get('dtsf');
    if (dtsf !== null)
      if (dtsf !== "")
        finalJSON[firstKey].DTSF = dtsf;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].DTSF)
        finalJSON[firstKey].DTSF = null;
      else
        finalJSON[firstKey].DTSF = elementCurrentState[currentElementKey].DTSF;

    var scad = formData.get('scad');
    if (scad !== null)
      if (scad.length > 1000) {
        alert('SCAD deve avere meno di 1000 caratteri.');
        return;
      } else if (scad !== "")
          finalJSON[firstKey].SCAD = scad;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].SCAD)
          finalJSON[firstKey].SCAD = null;
        else
          finalJSON[firstKey].SCAD = elementCurrentState[currentElementKey].SCAD;

    var res = formData.get('res');
    if (res !== null)
      if (res.length > 1000) {
        alert('RES deve avere meno di 1000 caratteri.');
        return;
      } else if (res !== "")
          finalJSON[firstKey].RES = res;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].RES)
          finalJSON[firstKey].RES = null;
        else
          finalJSON[firstKey].RES = elementCurrentState[currentElementKey].RES;

    var fnst = formData.get('fnst');
    if (fnst !== null)
      if (fnst !== "")
        finalJSON[firstKey].FNST = fnst;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].FNST)
        finalJSON[firstKey].FNST = null;
      else
        finalJSON[firstKey].FNST = elementCurrentState[currentElementKey].FNST;

    var fnsm = formData.get('fnsm');
    if (fnsm !== null)
      if (fnsm !== "")
        finalJSON[firstKey].FNSM = fnsm;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].FNSM)
        finalJSON[firstKey].FNSM = null;
      else
        finalJSON[firstKey].FNSM = elementCurrentState[currentElementKey].FNSM;

    var fnsc = formData.get('fnsc');
    if (fnsc !== null)
      if (fnsc !== "")
        finalJSON[firstKey].FNSC = fnsc;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].FNSC)
        finalJSON[firstKey].FNSC = null;
      else
        finalJSON[firstKey].FNSC = elementCurrentState[currentElementKey].FNSC;

    var fnsd = formData.get('fnsd');
    if (fnsd !== null)
      if (fnsd.length > 1000) {
        alert('FNSD deve avere meno di 1000 caratteri.');
        return;
      } else if (fnsd !== "")
          finalJSON[firstKey].FNSD = fnsd;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].FNSD)
          finalJSON[firstKey].FNSD = null;
        else
          finalJSON[firstKey].FNSD = elementCurrentState[currentElementKey].FNSD;

    var elen = formData.get('elen');
    if (elen !== null)
      if (elen !== "")
        finalJSON[firstKey].ELEN = elen;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ELEN)
        finalJSON[firstKey].ELEN = null;
      else
        finalJSON[firstKey].ELEN = elementCurrentState[currentElementKey].ELEN;

    var elet = formData.get('elet');
    if (elet !== null)
      if (elet !== "")
        finalJSON[firstKey].ELET = elet;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ELET)
        finalJSON[firstKey].ELET = null;
      else
        finalJSON[firstKey].ELET = elementCurrentState[currentElementKey].ELET;

    var eled = formData.get('eled');
    if (eled !== null)
      if (eled.length > 1000) {
        alert('ELED deve avere meno di 1000 caratteri.');
        return;
      } else if (eled !== "")
          finalJSON[firstKey].ELED = eled;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].ELED)
          finalJSON[firstKey].ELED = null;
        else
          finalJSON[firstKey].ELED = elementCurrentState[currentElementKey].ELED;

    var elex = formData.get('elex');
    if (elex !== null)
      if (elex !== "")
        finalJSON[firstKey].ELEX = elex;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ELEX)
        finalJSON[firstKey].ELEX = null;
      else
        finalJSON[firstKey].ELEX = elementCurrentState[currentElementKey].ELEX;

    var elel = formData.get('elel');
    if (elel !== null)
      if (elel !== "")
        finalJSON[firstKey].ELEL = elel;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ELEL)
        finalJSON[firstKey].ELEL = null;
      else
        finalJSON[firstKey].ELEL = elementCurrentState[currentElementKey].ELEL;

    var elem = formData.get('elem');
    if (elem !== null)
      if (elem !== "")
        finalJSON[firstKey].ELEM = elem;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ELEM)
        finalJSON[firstKey].ELEM = null;
      else
        finalJSON[firstKey].ELEM = elementCurrentState[currentElementKey].ELEM;

    var sold = formData.get('sold');
    if (sold !== null)
      if (sold.length > 1000) {
        alert('SOLD deve avere meno di 1000 caratteri.');
        return;
      } else if (sold !== "")
          finalJSON[firstKey].SOLD = sold;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].SOLD)
          finalJSON[firstKey].SOLD = null;
        else
          finalJSON[firstKey].SOLD = elementCurrentState[currentElementKey].SOLD;

    var solo = formData.get('solo');
    if (solo !== null)
      if (solo !== "")
        finalJSON[firstKey].SOLO = solo;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].SOLO)
        finalJSON[firstKey].SOLO = null;
      else
        finalJSON[firstKey].SOLO = elementCurrentState[currentElementKey].SOLO;

    var sole = formData.get('sole');
    if (sole !== null)
      if (sole !== "")
        finalJSON[firstKey].SOLE = sole;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].SOLE)
        finalJSON[firstKey].SOLE = null;
      else
        finalJSON[firstKey].SOLE = elementCurrentState[currentElementKey].SOLE;

    var soli = formData.get('soli');
    if (soli !== null)
      if (soli !== "")
        finalJSON[firstKey].SOLI = soli;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].SOLI)
        finalJSON[firstKey].SOLI = null;
      else
        finalJSON[firstKey].SOLI = elementCurrentState[currentElementKey].SOLI;

    var cput = formData.get('cput');
    if (cput !== null)
      if (cput !== "")
        finalJSON[firstKey].CPUT = cput;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].CPUT)
        finalJSON[firstKey].CPUT = null;
      else
        finalJSON[firstKey].CPUT = elementCurrentState[currentElementKey].CPUT;

    var cpue = formData.get('cpue');
    if (cpue !== null)
      if (cpue !== "")
        finalJSON[firstKey].CPUE = cpue;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].CPUE)
        finalJSON[firstKey].CPUE = null;
      else
        finalJSON[firstKey].CPUE = elementCurrentState[currentElementKey].CPUE;

    var cpui = formData.get('cpui');
    if (cpui !== null)
      if (cpui !== "")
        finalJSON[firstKey].CPUI = cpui;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].CPUI)
        finalJSON[firstKey].CPUI = null;
      else
        finalJSON[firstKey].CPUI = elementCurrentState[currentElementKey].CPUI;

    var cpud = formData.get('cpud');
    if (cpud !== null)
      if (cpud.length > 1000) {
        alert('CPUD deve avere meno di 1000 caratteri.');
        return;
      } else if (cpud !== "")
          finalJSON[firstKey].CPUD = cpud;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].CPUD)
            finalJSON[firstKey].CPUD = null;
          else
            finalJSON[firstKey].CPUD = elementCurrentState[currentElementKey].CPUD;

    var spat = formData.get('spat');
    if (spat !== null)
      if (spat !== "")
        finalJSON[firstKey].SPAT = spat;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].SPAT)
        finalJSON[firstKey].SPAT = null;
      else
        finalJSON[firstKey].SPAT = elementCurrentState[currentElementKey].SPAT;

    var spac = formData.get('spac');
    if (spac !== null)
      if (spac !== "")
        finalJSON[firstKey].SPAC = spac;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].SPAC)
        finalJSON[firstKey].SPAC = null;
      else
        finalJSON[firstKey].SPAC = elementCurrentState[currentElementKey].SPAC;

    var spad = formData.get('spad');
    if (spad !== null)
      if (spad.length > 1000) {
        alert('SPAD deve avere meno di 1000 caratteri.');
        return;
      } else if (spad !== "")
          finalJSON[firstKey].SPAD = spad;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].SPAD)
          finalJSON[firstKey].SPAD = null;
        else
          finalJSON[firstKey].SPAD = elementCurrentState[currentElementKey].SPAD;

    var isrc = formData.get('isrc');
    if (isrc !== null)
      if (isrc !== "")
        finalJSON[firstKey].ISRC = isrc;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ISRC)
        finalJSON[firstKey].ISRC = null;
      else
        finalJSON[firstKey].ISRC = elementCurrentState[currentElementKey].ISRC;

    var isrl = formData.get('isrl');
    if (isrl !== null)
      if (isrl !== "")
        finalJSON[firstKey].ISRL = isrl;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ISRL)
        finalJSON[firstKey].ISRL = null;
      else
        finalJSON[firstKey].ISRL = elementCurrentState[currentElementKey].ISRL;

    var isrp = formData.get('isrp');
    if (isrp !== null)
      if (isrp !== "")
        finalJSON[firstKey].ISRP = isrp;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ISRP)
        finalJSON[firstKey].ISRP = null;
      else
        finalJSON[firstKey].ISRP = elementCurrentState[currentElementKey].ISRP;

    var isri = formData.get('isri');
    if (isri !== null)
      if (isri !== "")
        finalJSON[firstKey].ISRI = isri;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ISRI)
        finalJSON[firstKey].ISRI = null;
      else
        finalJSON[firstKey].ISRI = elementCurrentState[currentElementKey].ISRI;

    var isrm = formData.get('isrm');
    if (isrm !== null)
      if (isrm !== "")
        finalJSON[firstKey].ISRM = isrm;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].ISRM)
        finalJSON[firstKey].ISRM = null;
      else
        finalJSON[firstKey].ISRM = elementCurrentState[currentElementKey].ISRM;

    var isrd = formData.get('isrd');
    if (isrd !== null)
      if(isrd.length > 1000) {
        alert('ISRD deve avere meno di 1000 caratteri.');
        return;
      } else if (isrd !== "")
          finalJSON[firstKey].ISRD = isrd;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].ISRD)
          finalJSON[firstKey].ISRD = null;
        else
          finalJSON[firstKey].ISRD = elementCurrentState[currentElementKey].ISRD;

    var rivm = formData.get('rivm');
    if (rivm !== null)
      if (rivm !== "")
        finalJSON[firstKey].RIVM = rivm;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].RIVM)
        finalJSON[firstKey].RIVM = null;
      else
        finalJSON[firstKey].RIVM = elementCurrentState[currentElementKey].RIVM;

    var rivt = formData.get('rivt');
    if (rivt !== null)
      if (rivt !== "")
        finalJSON[firstKey].RIVT = rivt;
      else if (!elementCurrentState || !elementCurrentState[currentElementKey].RIVT)
        finalJSON[firstKey].RIVT = null;
      else
        finalJSON[firstKey].RIVT = elementCurrentState[currentElementKey].RIVT;

    var rivd = formData.get('rivd');
    if (rivd !== null)
      if(rivd.length > 1000) {
        alert('RIVD deve avere meno di 1000 caratteri.');
        return;
      } else if (rivd !== "")
          finalJSON[firstKey].RIVD = rivd;
        else if (!elementCurrentState || !elementCurrentState[currentElementKey].RIVD)
          finalJSON[firstKey].RIVD = null;
        else
          finalJSON[firstKey].RIVD = elementCurrentState[currentElementKey].RIVD;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',  
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, UPDATE',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(finalJSON)
    };

    fetch(window.SERVER_MAIN_PATH + "/insert/" + type + "Traduzione", requestOptions)
      .then(res => {
        const statusCode = res.status;
        const data = res.json();
        return Promise.all([statusCode, data]);
      })
      .then(([statusCode, result]) => {
        if (statusCode === 201) {
          let count = props.insertCount + 1;
          props.setInsertCount(count); // New Render
          alert("Traduzione inserita.");
        } else {
          console.log("ERRORE: " + JSON.stringify(result));
          alert("Inserimento non eseguito. Contatta TuSaiChi.");
        }   
      })
      .catch(function(error) {
        alert(error);
      });

    console.log(finalJSON);
  }
}

export default TranslationInsert;