// npm
import React from 'react';
import { Link } from 'react-router-dom';

// Custom components
import Toggle from '../components/toggle.js';
import LanguageSelector from '../components/languageSelector.js';
import SettingsBar from '../components/settingsBar.js';

// Assets
import darkLogo from '../assets/imgs/darkLogo.png';
import lightLogo from '../assets/imgs/lightLogo.png';
import por from '../assets/imgs/por.webp';
import dei from '../assets/imgs/unipd_dei.png';
import dbc from '../assets/imgs/unipd_dbc.png';
import dsea from '../assets/imgs/unipd_dsea.png';
import archetipo from '../assets/imgs/archetipo.png';
import boccaccio432 from '../assets/imgs/boccaccio432.jpeg';
import suerte from '../assets/imgs/suerte.png';
import venetianCluster from '../assets/imgs/venetianCluster.jpg';
import gis from '../assets/imgs/3Dgis.jpg';


/**
 * This page displays information about CArD 3D project collaborations.
 */
class About extends React.Component {

  /**
   * Class constructor.
   * @param {*} props React properties:
   * - darkOnChange: function called when the dark mode is changed by the user;
   * - isDark: boolean variable that states if the dark mode is active.
   */
  constructor(props) {

    super(props);

    this.state = {
      settingsbarIsOpen: false
    };

    this.handleSettingsbar = this.handleSettingsbar.bind(this);

  }

  componentDidMount(){
    document.title = "CArD 3D - Collaborazioni";
  }

  /**
   * This function opens and close the Sidebar.
   * @param {*} event The event that called the handler.
   */
  handleSettingsbar(event) {
    event.preventDefault();
    this.setState({
      settingsbarIsOpen: !this.state.settingsbarIsOpen
    });
    console.log(this.state);
  }

  render() {
    return (
      <div className="flex flex-col items-center">

        <SettingsBar settingsbarIsOpen={this.state.settingsbarIsOpen} toggleEnabled={this.props.isDark} toggleOnChange={this.props.darkOnChange} />

        <div className="hidden w-4/5 h-20vh lg:flex justify-between">
          <div className="z-50 flex w-1/4 justify-center align-center">
            <Toggle enabled={this.props.isDark} onChange={this.props.darkOnChange} />
          </div>
          <div className="w-1/4 flex items-center z-50">
            <Link to="/">
              <img className="transform hover:scale-110 duration-300" src={this.props.isDark ? darkLogo : lightLogo} alt="CArD 3D light logo"/>
            </Link>
          </div>
          <div className="z-50 flex w-1/4 justify-center align-center">
            <LanguageSelector arrowColor={this.props.isDark ? "white" : "dark"} />
          </div>
        </div>

        <h1 className="font-cinzel text-2xl lg:text-5xl mb-6 mt-24 lg:mt-8">
          Collaborazioni
        </h1>
        <div className="w-full flex justify-center my-4">
          <div className="rounded-3xl bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5 my-4">
            <img src={por} className="h-40 my-4 mx-4" alt="POR" />
          </div>
        </div>
        <p className="w-4/5 lg:w-3/5 text-justify lg:text-lg">
          Il Progetto C.Ar.D. 3D è stato finanziato dal <span className="font-black">Fondo Sociale Europeo (FSE) - Regione Veneto</span> e coinvolge tre dipartimenti dell'Università degli Studi di Padova, aziende e Enti territoriali e centri di Ricerca Internazionali.
        </p>

        <h1 className="font-cinzel text-3xl mb-6 mt-16 mx-2">
          Università degli Studi di Padova
        </h1>
        <div className="w-4/5 lg:w-full flex justify-center my-4">
          <div className="grid grid-col grid-cols-2 lg:grid-cols-3 grid-rows-1 flex justify-center items-center px-4 py-8 rounded-3xl bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5">
            <a href="https://www.dei.unipd.it/" target="_blank" rel="noreferrer"><img src={dei} className="mx-4 h-20 my-4 lg:my-0" alt="DEI" /></a>
            <a href="https://www.beniculturali.unipd.it/www/homepage/" target="_blank" rel="noreferrer"><img src={dbc} className="mx-4 h-20 my-4 lg:my-0" alt="dBC" /></a>
            <a href="https://www.economia.unipd.it/" target="_blank" rel="noreferrer"><img src={dsea} className="mx-4 h-12 my-4 lg:my-0" alt="dSEA" /></a>
          </div>
        </div>

        <h1 className="font-cinzel text-3xl mb-6 mt-16">
          Aziende Partner
        </h1>
        <div className="w-4/5 lg:w-full flex justify-center my-4">
          <div className="grid grid-flow-col auto-cols-max grid-rows-3 lg:grid-rows-1 gap-2 flex justify-center items-center px-4 py-8 rounded-3xl bg-dark bg-opacity-5 dark:bg-white dark:bg-opacity-5 mb-4">
            <a href="https://archetipo-srl.com/" target="_blank" rel="noreferrer"><img src={archetipo} className="ml-4 h-20 my-4 lg:my-0" alt="Archetipo" /></a>
            <div><img src={boccaccio432} className="mx-4 h-20 my-4 lg:my-0" alt="Boccaccio432" /></div>
            <a href="https://www.suertestudio.com/" target="_blank" rel="noreferrer"><img src={suerte} className="mx-4 h-20 my-4 lg:my-0" alt="SuerteStudio" /></a>
            <a href="https://www.venetiancluster.eu/it/" target="_blank" rel="noreferrer"><img src={venetianCluster} className="mx-4 h-20 my-4 lg:my-0" alt="Venetian Cluster" /></a>
            <a href="https://www.3dgis.it/it/" target="_blank" rel="noreferrer"><img src={gis} className="mx-4 h-20 my-4 lg:my-0" alt="3Dgis" /></a>
          </div>
        </div>
        <p className="w-4/5 lg:w-3/5 text-justify lg:text-lg mb-12">
          Le Aziende coinvolte sono realtà ben consolidate nel territorio veneto regionale che sono state selezionate in base al campo di interesse degli assegnisti, i quali saranno supportati e coinvolti durante il lavoro da professionisti del settore.
        </p>

      </div>
    )
  }
}

export default About;
