/**
 * This function processes a polygon to draw it with Leaflet
 * @param {String} poly The POLYGON PostGIS string returned from the server.
 * @returns An array of coordinates compliant to Leadflet requirements.
 */
function processPOLY(poly) {
  let area = [[]];
  let coordinates = poly.substring(9, poly.length-2).split(",");

  let currentPoly = 0; // To manage multipolys
  for (let i = 0; i < coordinates.length; i++) {
    let element = coordinates[i].split(" ");
    if (element.length === 3 && element[0] === "")
      element.splice(0, 1);

    // Process multipoly
    if (element[1].slice(-1) === ")") {
      area[currentPoly].push(JSON.parse("[" + element[1].substring(0, element[1].length - 1) + ", " + element[0] + "]"));
      currentPoly++;
      area.push([]);
    } else if (element[0][0] === "(")
      area[currentPoly].push(JSON.parse("[" + element[1] + ", " + element[0].substring(1, element[1].length) + "]"));
    else
      area[currentPoly].push(JSON.parse("[" + element[1] + ", " + element[0] + "]"));
  }

  return area;
}

/**
 * This function returns the element center.
 * If it is a Monumento, it returns the point saved in the database,
 * otherwise it computes the center of the area by calling getPOLYCenter().
 * @param {*} element The element returned from the server.
 * @returns Two float representing the X and Y coordinates of the center.
 */
function getElementCenter(element) {
  let type = Object.keys(element);
  if (type[0].toLowerCase() === "monumento")
    return [element[type].GPDPY, element[type].GPDPX];
  else
    return getPOLYCenter(element[type].GAP);
}

/**
 * This function computes the center of a Polygon by computing the mean among all vertices of the polygon.
 * @param {String} poly The POLYGON PostGIS string returned from the server. An array of Strings for multipolygons.
 * @returns Two float representing the X and Y coordinates of the center.
 */
function getPOLYCenter(poly) {
  let gap = processPOLY(poly)[0]; // Not very good for multipoly. Must be refined.
  let x = 0;
  let y = 0;
  for (let i = 0; i < gap.length; i++) {
    x += gap[i][1] / gap.length;
    y += gap[i][0] / gap.length;
  }
  return [y, x];
}

/**
 * This function computes the coordinates of an element as String.
 * @param {*} element The element returned from the server.
 * @param {*} type The type of the element.
 * @returns A String containing the coordinates to be drawn on the map.
 */
function getElementCoord(element, type) {
  if (type.toLowerCase() === 'monumento')
    return ("[" + element[type].GPDPY + ", " + element[type].GPDPX + "]");
  else if (type.toLowerCase() === 'complesso' || type.toLowerCase() === 'sito')
    return element[type].GAP;
  else
    return null;
}

export { processPOLY, getElementCenter, getPOLYCenter, getElementCoord }