// npm
import React from 'react'
import { Link } from 'react-router-dom';

// Assets
import Logo3D from '../../assets/imgs/3DLogo.png'

/**
 * This component renders the InfoBar header, which holds the button to go to the homepage
 * and the button to open the Settings Bar.
 * It is rendered in the desktop version on top of the InfoBar while in 'explore' pages.
 */
const InfoBarHeader = (props) => {
	return (
		<div id="infoBar-header-div" className="hidden fixed inset-x-0 top-0 h-15v sm:h-20v lg:h-10v w-4/12 pr-2 z-30 lg:flex justify-between items-center">
			<div id="darkbg-div" className={"z-10 absolute opacity-0 dark:opacity-100 h-full w-full bg-gradient-to-b from-dark"}></div>
			<div id="lightbg-div" className={"z-10 absolute opacity-100 dark:opacity-0 h-full w-full bg-gradient-to-b from-white"}></div>
			{/*Logo home*/}
			<Link to="/" className="z-10 ml-4">
				<img src={Logo3D} alt="Logo" className="w-12" />
			</Link>
			{/*Open Home Sidebar*/}
			<div className="z-10" onClick={props.handleMobileHomeSidebar}>
				<svg className="w-12 h-12 fill-dark dark:fill-white hover:fill-orange mr-2 px-2 py-2 cursor-pointer transition-all duration-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
					{!props.mobileSidebarIsOpen &&
						<path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
					}
					{props.mobileSidebarIsOpen &&
						<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
					}
				</svg>
			</div>
		</div>
	)
}

export default InfoBarHeader;
