// npm
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';


/**
 * This component renders the language selector, where the user can change the language of the application
 * @param {*} props React props, namely:
 * - languageEnabled: a boolean value indicating whether the user can change the language or not;
 * - arrowColor: the color of the arrow;
 * @returns The React component
 */
const LanguageSelector = (props) => {

  // React state
  const [isOpen, setIsOpen] = useState(false);

  /**
   * This function opens and close the language selector
   */
  function handlerOpen() {
    setIsOpen(!isOpen)
  }

  // Redux dispatch for changing lang
  const dispatch = useDispatch()
  // Redux for selecting current lang
  const store = useSelector(state => state)
  const lang = store.lang

  return (
    <div className="inline-block flex items-center justify-center text-left text-dark w-28 focus:outline-none">
      <button type="button" className={"relative flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none " + (props.languageEnabled ? "" : "cursor-default")} id="options-menu" onClick={props.languageEnabled ? handlerOpen : null} aria-haspopup="true" aria-expanded={isOpen}>
        <div className="flex justify-between align-center h-6">{
          lang === 'ITA' ?
            <img className="h-6 rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/64px-Flag_of_Italy.svg.png" alt="IT flag." />
          : lang === 'ENG' ?
            <img className="h-6 rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_the_United_Kingdom_%282-3%29.svg/64px-Flag_of_the_United_Kingdom_%282-3%29.svg.png" alt="UK flag." />
          : null
        }</div>
        {/* Heroicon name: chevron-down */
        props.languageEnabled ?
          <svg className="flex -mr-1 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={props.arrowColor} aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        : null}
        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-150"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {(ref) => (
            <div ref={ref} className="absolute top-12 w-32 md:w-40 left-0 rounded-md shadow-lg bg-white ring-1 ring-dark ring-opacity-5">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div className="flex justify-between align-center items-center my-2 mx-4 cursor-pointer" title="ITA" href="#top" onClick={()=> { dispatch({type: "CHANGE_LANG", payload: "ITA"}); handlerOpen(); }}>
                  <img className="h-6 rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/64px-Flag_of_Italy.svg.png" alt="Italy flag." />
                  <p className="ml-2 w-full">
                    ITA
                  </p>
                </div>
                {/*<div className="flex justify-between align-center items-center my-2 mx-4 cursor-pointer" title="ENG" onClick={()=> { dispatch({type: "CHANGE_LANG", payload: "ENG"}); handlerOpen(); }}>
                  <img className="h-6 rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_the_United_Kingdom_%282-3%29.svg/64px-Flag_of_the_United_Kingdom_%282-3%29.svg.png" alt="UK flag." />
                  <p className="ml-2">
                    ENG
                  </p>
                </div>*/}
                <div className="flex justify-between align-center items-center my-2 mx-4 cursor-pointer" title="WORLD" onClick={()=> { handlerOpen(); }}>
                  <img className="h-6 rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Flag_of_the_United_Nations.svg/64px-Flag_of_the_United_Nations.svg.png" alt="United Nations flag." />
                  <p className="ml-2 w-full">
                    Presto altre lingue!
                  </p>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </button>
    </div>
  )
}

export default LanguageSelector;
